import { useContext, useEffect, useState } from "react";
import { DefaultHeader, ModalSuccess } from "../../../Component";
import { GlobalState } from "../../../Data/Context";
import { Buttons } from "../../../Utils";
import { toast } from "react-toastify";

import "./create-payment-class.styles.css";

const CreateGroup = () => {
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const {
    getPaymentGroup,
    getPaymentLevel,
    getPaymentRole,
    payroll,
    createPaymentClass,
  } = useContext(GlobalState);

  const [inputValues, setInputValues] = useState({
    group_id: "",
    level_id: "",
    role_id: "",
    amount: "",
  });

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setInputValues({ ...inputValues, [name]: value });
    };

  useEffect(() => {
    const paymentGroup = async () => {
      await getPaymentGroup();
      await getPaymentLevel();
      await getPaymentRole();
    };
    paymentGroup();
    console.log(payroll);
  }, []);
  const createGroup = async (e) => {
    let { group_id, level_id, role_id, amount } = inputValues;
    e.preventDefault();
    setLoading(true);

    if (!group_id || !level_id || !role_id || !amount) {
      setLoading(false);
      toast.error("Field cannot be empty");
      return;
    }
    const res = await createPaymentClass(inputValues);
    if (res) {
      setSuccessModal(!successModal);
      setLoading(false);
      setInputValues({
        group_id: "",
        level_id: "",
        role_id: "",
        amount: "",
      });
      return;
    }
  };
  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="container">
        <DefaultHeader text={"Create Payment Class"}></DefaultHeader>
      </div>
      <div className="container">
        <form onSubmit={createGroup}>
          <table>
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Group</th>
                <th>Level</th>
                <th>Title</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="tw-bg-white tw-shadow-md">
              <tr>
                <td>CEPHAS ICT HUB</td>
                <td>
                  <select name="group_id" onChange={handleChange("group_id")}>
                    <option value="">--select--</option>
                    {payroll?.paymentGroup.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select name="level_id" onChange={handleChange("level_id")}>
                    <option value="">--select--</option>
                    {payroll?.paymentLevel.map((level) => (
                      <option key={level.id} value={level.id}>
                        {level.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select name="role_id" onChange={handleChange("role_id")}>
                    <option value="">--select--</option>
                    {payroll?.paymentRole.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    name="amount"
                    onChange={handleChange("amount")}
                    value={inputValues.amount}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Buttons
            title="Create Class"
            css={
              "btn btn-primary1 d-block ms-auto mt-4 px-5 py-2 text-capitalize"
            }
            width
            loading={loading}
            type="submit"
          />
        </form>
        <ModalSuccess
          isOpen={successModal}
          toggle={() => setSuccessModal(!successModal)}
          msg="Continue"
        />
      </div>
    </main>
  );
};

export default CreateGroup;
