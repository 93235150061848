import React from "react";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalState } from "../../Data/Context";
import Empty from "../Empty";
import "../../Styles/OrgAuth.css";

let userList = [
	{
		name: "Motion Designers",
		level: "Inter",
		amount: 400000,
		benefit: 50000,
		status: "pending",
		type: "full-time",
		staff: "Adaralegbe Ifeoluwa",
	},
	{
		name: "Mobile Developers",
		level: "Mid-level",
		amount: 400000,
		benefit: 50000,
		status: "active",
		type: "full-time",
		staff: "Adaralegbe Ifeoluwa",
	},
	{
		name: "UI/UX Designers",
		level: "Mid-level",
		amount: 400000,
		benefit: 50000,
		status: "pending",
		type: "full-time",
		staff: "Adaralegbe Ifeoluwa",
	},
	{
		name: "Motion Designers",
		level: "Inter",
		amount: 400000,
		benefit: 50000,
		status: "retired",
		type: "full-time",
		staff: "Adaralegbe Ifeoluwa",
	},
	{
		name: "Mobile Developers",
		level: "Mid-level",
		amount: 400000,
		benefit: 50000,
		status: "retired",
		type: "full-time",
		staff: "Adaralegbe Ifeoluwa",
	},
	{
		name: "UI/UX Designers",
		level: "Mid-level",
		amount: 400000,
		benefit: 50000,
		status: "resign",
		type: "full-time",
		staff: "Adaralegbe Ifeoluwa",
	},
];

const ListUser = () => {
	let param = useParams();
	const { nairaSign, numberWithCommas } = useContext(GlobalState);
	return (
		<section className="container">
			<table className="">
				<thead className="text-uppercase fw-100 rounded">
					<tr className="td bg-blue rounded">
						<th className="barFont fw-bold fontReduce text-white">job title</th>
						<th className="barFont fw-bold fontReduce  d-none d-lg-block text-white">
							level
						</th>
						<th className="barFont fw-bold fontReduce text-white">amount</th>
						<th className="barFont fw-bold fontReduce text-white">
							benefits {"&"} extra
						</th>
					</tr>
				</thead>
				<tbody>
					{userList.length === 0 ? (
						<Empty subtitle={`No user available`} />
					) : (
						userList.map((item, index) => (
							<tr key={index} className="barFont fw-bold">
								<td className="barFont fw-bold fontReduce">
									<Link
										to={`/${param.page}/${param.id}/${index}`}
										className="text-dark text-decoration-none barFont text-capitalize">
										{item.name}
									</Link>
								</td>
								<td className="barFont fw-bold fontReduce d-none d-lg-block text-capitalize">
									{item.level}
								</td>
								<td className="barFont fw-bold fontReduce text-capitalize">
									{nairaSign}
									{numberWithCommas(Number(item.amount).toFixed(2))}/ month
								</td>
								<td className="barFont fw-bold fontReduce text-capitalize">
									{nairaSign}
									{numberWithCommas(Number(item.benefit).toFixed(2))}/ year
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
		</section>
	);
};

export default ListUser;

export const VoucherList = () => {
	let param = useParams();
	const { nairaSign, numberWithCommas } = useContext(GlobalState);
	return (
		<section className="container">
			<table className="">
				<thead className="text-uppercase fw-100 rounded">
					<tr className="td bg-blue rounded">
						<th className="barFont fw-bold fontReduce text-white">
							staff name
						</th>
						<th className="barFont fw-bold fontReduce  d-none d-lg-block text-white">
							employment type
						</th>
						<th className="barFont fw-bold fontReduce text-white">amount</th>
						<th className="barFont fw-bold fontReduce text-white">status</th>
					</tr>
				</thead>
				<tbody>
					{userList.length === 0 ? (
						<Empty subtitle={`No user available`} />
					) : (
						userList.map((item, index) => (
							<tr key={index} className="barFont fw-bold">
								<td className="barFont fw-bold fontReduce">
									<Link
										to={`/${param.page}/${param.id}/${index}`}
										className="text-dark text-decoration-none barFont text-capitalize">
										{item.staff}
									</Link>
								</td>
								<td className="barFont fw-bold fontReduce d-none d-lg-block text-capitalize">
									{item.type}
								</td>
								<td className="barFont fw-bold fontReduce text-capitalize">
									{nairaSign}
									{numberWithCommas(Number(item.amount).toFixed(2))}/ month
								</td>
								<td className="barFont fw-bold fontReduce text-capitalize">
									{item.status}
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
		</section>
	);
};
