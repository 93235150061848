import { useContext, useEffect, useState } from "react";
import {
  DefaultHeader,
  ModalAddWorker,
  ModalSuccess,
} from "../../../Component";
import { GlobalState } from "../../../Data/Context";

const AddWorker = () => {
  const {
    getAllWorkers,
    addWorker,
    payroll,
    getOrganisationUser,
    organisation,
  } = useContext(GlobalState);
  const [usersModal, setUsersModal] = useState(false);
  const [dropDownOPen, setDropDownOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [value, setValue] = useState(null);
  const options = [];
  organisation?.allUsers.forEach((element) => {
    options.push({
      label: element.email,
      value: element.id,
    });
  });
  const toggleOpen = () => {
    setDropDownOpen(!dropDownOPen);
  };

  const onSelectChange = (value) => {
    toggleOpen();
    setValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await addWorker({ user_id: value.value });
    console.log(res);
    if (res) {
      showUsersModal();
      setSuccessModal(!successModal);
    }
  };

  const showUsersModal = () => {
    setUsersModal(!usersModal);
  };

  useEffect(() => {
    const paymentClass = async () => {
      await getAllWorkers();
      await getOrganisationUser();
    };
    paymentClass();
    console.log(payroll);
    console.log(organisation);
  }, [successModal]);
  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="container">
        <DefaultHeader text={"Create Payment Group"}>
          <button
            className="btn btn-primary1 text-capitalize ms-auto px-5 py-2"
            onClick={showUsersModal}
          >
            add new
          </button>
        </DefaultHeader>

        <div>
          <form>
            <table>
              <thead>
                <tr className="tw-capitalize">
                  <th>workers</th>
                  <th>payment class</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody className="tw-bg-white tw-shadow-md">
                {payroll?.allWorkers.map((worker) => (
                  <tr>
                    <td className="tw-capitalize">
                      {worker.user.full_name + " " + worker.user.last_name}
                    </td>
                    <td>Full-Time|Frontend|Frontend developer</td>
                    <td>
                      <button type="button" class="btn btn-primary">
                        Edit worker
                      </button>
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </form>
        </div>
      </div>
      {/* modal */}
      <ModalAddWorker
        isOpen={usersModal}
        users={options}
        onSelectChange={onSelectChange}
        dropDownOpen={dropDownOPen}
        toggleOpen={toggleOpen}
        value={value}
        handleSubmit={handleSubmit}
        back={showUsersModal}
      />
      <ModalSuccess
        isOpen={successModal}
        toggle={() => setSuccessModal(!successModal)}
        msg="Continue"
      />
    </main>
  );
};

export default AddWorker;
