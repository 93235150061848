import React from "react";
import { CustomSearchBar, DefaultHeader } from "../../../Component";
import { ListUser } from "../../../Component/Payroll";
import { useNavigate, useParams } from "react-router-dom";

const Voucher = () => {
	let navigate = useNavigate(),
		param = useParams();
	return (
		<main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader text={"Payment Voucher"} />
				<CustomSearchBar
					addNew
					addFunc={() => navigate(`/${param.page}/add-new`)}
				/>
				<ListUser />
			</div>
		</main>
	);
};

export default Voucher;
