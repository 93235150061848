import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useParams } from "react-router-dom";

const Wrapper = ({ children }) => {
	let escape = [
		"otp",
		"login",
		"createaccount",
		"forgetpassword",
		"resetpassword",
	];
	let param = useParams();
	return (
		<>
			<Navbar />
			{children}
			{escape.includes(param.page) ? <></> : <Footer />}
		</>
	);
};

export default Wrapper;
