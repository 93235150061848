import {
	GET_USER,
	GET_USER_FAIL,
	GET_USER_LOADING,
	LOGIN_USER,
	LOGIN_USER_FAIL,
	LOGIN_USER_LOADING,
	LOGOUT,
	TOKEN,
	UPDATE_USER,
	UPDATE_USER_FAIL,
	SECOND_VERIFY,
	GET_DASHBOARD,
	GET_DASHBOARD_FAIL,
	GET_DASHBOARD_LOADING,
	VERIFY_MAIL,
} from "../Actions/ActionTypes";

const initialState = {
	user: null,
	token: localStorage.getItem(TOKEN),
	isAuth: false,
	isLoggedIn: false,
	loading: false,
	isLoading: false,
	isUpdated: false,
	userType: "",
	twoStep: false,
	firstStep: false,
	dashboard: null,
	dashboardLoading: false,
};

const AuthReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_USER:
			return {
				...state,
				user: payload.data,
				isAuth: true,
				loading: false,
				userType: payload.type,
				twoStep: false,
				firstStep: false,
			};
		case GET_USER_FAIL:
			return {
				...state,
				loading: false,
				isAuth: false,
				twoStep: false,
				firstStep: false,
			};
		case GET_DASHBOARD:
			return {
				...state,
				dashboard: payload.data,
				dashboardLoading: false,
			};
		case GET_DASHBOARD_LOADING:
			return {
				...state,
				dashboardLoading: true,
			};
		case GET_DASHBOARD_FAIL:
			return {
				...state,
				dashboard: null,
				dashboardLoading: false,
			};
		case GET_USER_LOADING:
			return {
				...state,
				loading: true,
			};
		case LOGIN_USER:
			localStorage.setItem(TOKEN, payload.token);
			return {
				...state,
				isLoggedIn: true,
				user: payload.data,
				isLoading: false,
			};
		case LOGIN_USER_FAIL:
			return {
				...state,
				isLoggedIn: false,
				isLoading: false,
			};
		case LOGIN_USER_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_USER:
			return {
				...state,
				isUpdated: true,
				user: payload.data,
				isLoading: false,
			};
		case UPDATE_USER_FAIL:
			return {
				...state,
				isUpdated: false,
				isLoading: false,
			};
		case VERIFY_MAIL:
			return { ...state, firstStep: true, loading: false };
		case SECOND_VERIFY:
			return { ...state, twoStep: true, loading: false };
		case LOGOUT:
			localStorage.removeItem(TOKEN);
			return {
				token: null,
				user: null,
				isAuth: false,
				isLoggedIn: false,
				isLoading: false,
				isUpdated: false,
				twoStep: false,
				firstStep: false,
			};
		default:
			return state;
	}
};

export default AuthReducer;
