import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Slide } from "react-reveal";
import SquareIcon from "../square-icon/square-icon.component";

const TeamCard = ({
  facebookLink,
  twitterLink,
  instagramLink,
  linkedinLink,
}) => {
  return (
    <Slide bottom>
      <div className="tw-w-80 tw-bg-[#EFF0F6] tw-rounded-lg tw-mx-auto">
        <img
          src={require("../../assets/team-1.png")}
          alt=""
          className="tw-rounded-t-lg"
        />
        <div className="tw-pl-4 tw-pr-8 tw-pb-6">
          <div className="tw-rounded-lg tw-p-10 tw-bg-white">
            <p className="tw-text-2xl tw-capitalize tw-font-medium">sandra cope</p>
            <p className="tw-text-lg tw-font-medium tw-uppercase">founder, ceo</p>
            <div className="tw-mt-4 tw-flex tw-gap-x-4">
              <SquareIcon
                icon={
                  <FontAwesomeIcon icon="fa-brands fa-facebook-f" size="lg" />
                }
                link={facebookLink}
              />
              <SquareIcon
                icon={<FontAwesomeIcon icon="fa-brands fa-twitter" size="lg" />}
                link={facebookLink}
              />
              <SquareIcon
                icon={
                  <FontAwesomeIcon
                    icon="fa-brands fa-square-instagram"
                    size="lg"
                  />
                }
                link={facebookLink}
              />
              <SquareIcon
                icon={
                  <FontAwesomeIcon icon="fa-brands fa-linkedin-in" size="lg" />
                }
                link={facebookLink}
              />
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default TeamCard;
