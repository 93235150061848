import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_PAYMENT_CLASS_LOADING,
  CREATE_PAYMENT_CLASS_FAIL,
  CREATE_PAYMENT_CLASS,
  GET_PAYMENT_CLASS_LOADING,
  GET_PAYMENT_CLASS_FAIL,
  GET_PAYMENT_CLASS,
  CREATE_GROUP_LOADING,
  CREATE_GROUP_FAILED,
  CREATE_GROUP,
  CREATE_LEVEL_LOADING,
  CREATE_LEVEL_FAILED,
  CREATE_LEVEL,
  CREATE_ROLE_LOADING,
  CREATE_ROLE_FAILED,
  CREATE_ROLE,
  GET_GROUP_LOADING,
  GET_GROUP_FAILED,
  GET_GROUP,
  GET_LEVEL,
  GET_LEVEL_LOADING,
  GET_LEVEL_FAILED,
  GET_ROLE,
  GET_ROLE_LOADING,
  GET_ROLE_FAILED,
  GET_WORKER,
  GET_WORKER_LOADING,
  GET_WORKER_FAILED,
  UPDATE_WORKER_LOADING,
  UPDATE_WORKER,
  UPDATE_WORKER_FAILED,
  ADD_WORKER_LOADING,
  ADD_WORKER,
  ADD_WORKER_FAILED,
  GET_CLASS_WORKER,
  GET_CLASS_WORKER_FAILED,
  GET_CLASS_WORKER_LOADING,
  PAY_WORKERS,
  PAY_WORKERS_LOADING,
  PAY_WORKERS_FAILED,
  VALIDATE_WORKERS,
  VALIDATE_WORKERS_LOADING,
  VALIDATE_WORKERS_FAILED,
  DELETE_GROUP,
  DELETE_GROUP_FAIL,
  DELETE_LEVEL,
  DELETE_LEVEL_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_FAIL,
  UPDATE_LEVEL,
  UPDATE_LEVEL_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
} from "./ActionTypes";

export const getPaymentClass = () => async (dispatch) => {
  dispatch({ type: GET_PAYMENT_CLASS_LOADING });

  try {
    let res = await axios.get(`/api/organisations/get-class`);
    dispatch({
      type: GET_PAYMENT_CLASS,
      payload: res.data.data,
    });
  } catch (err) {
    if (err) console.log({ err });
    dispatch({ type: GET_PAYMENT_CLASS_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const createPaymentClass = (details) => async (dispatch) => {
  dispatch({ type: CREATE_PAYMENT_CLASS_LOADING });
  try {
    let res = await axios.post(`/api/organisations/create-class`, details);
    dispatch({ type: CREATE_PAYMENT_CLASS });
    return res;
  } catch (err) {
    console.log({ err });
    dispatch({ type: CREATE_PAYMENT_CLASS_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const createPaymentGroup = (groupName) => async (dispatch) => {
  dispatch({ type: CREATE_GROUP_LOADING });
  try {
    let res = await axios.post(`/api/organisations/create-group`, groupName);
    dispatch({ type: CREATE_GROUP });
    return res;
  } catch (err) {
    console.log({ err });
    dispatch({ type: CREATE_GROUP_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const deleteGroup = (thisData) => async (dispatch) => {
  try {
    let res = await axios.post(
      `/api/organisations/delete-group/${thisData}`
    );
    dispatch({
      type: DELETE_GROUP,
      payload: thisData,
    });
    toast.success(res.message, {
      autoClose: 5000,
    });
  } catch (err) {
    console.log({ err });
    dispatch({ type: DELETE_GROUP_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const updateGroup = (id, name) => async (dispatch) => {
  try {
    let res = await axios.post(`/api/organisations/update-group/${id}`, name);

    dispatch({
      type: UPDATE_GROUP,
    });

    toast.success(res.data.message, {
      autoClose: 5000,
    });
    return res
    // dispatch(getPaymentGroup());
  } catch (err) {
    console.log({ err });
    dispatch({ type: UPDATE_GROUP_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const createPaymentLevel = (groupName) => async (dispatch) => {
  dispatch({ type: CREATE_LEVEL_LOADING });
  try {
    let res = await axios.post(`/api/organisations/create-level`, groupName);
    dispatch({ type: CREATE_LEVEL });
    return res;
  } catch (err) {
    console.log({ err });
    dispatch({ type: CREATE_LEVEL_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const deleteLevel = (thisData) => async (dispatch) => {
  try {
    let res = await axios.post(
      `/api/organisations/delete-level/${thisData}`
    );
    dispatch({
      type: DELETE_LEVEL,
      payload: thisData,
    });
    toast.success(res.message, {
      autoClose: 5000,
    });
  } catch (err) {
    console.log({ err });
    dispatch({ type: DELETE_LEVEL_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const updateLevel = (id, name) => async (dispatch) => {
  try {
    let res = await axios.post(`/api/organisations/update-level/${id}`, name);

    dispatch({
      type: UPDATE_LEVEL,
    });

    toast.success(res.message, {
      autoClose: 5000,
    });
    return res
    // dispatch(getPaymentGroup());
  } catch (err) {
    console.log({ err });
    dispatch({ type: UPDATE_LEVEL_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const createPaymentRole = (groupName) => async (dispatch) => {
  dispatch({ type: CREATE_ROLE_LOADING });
  try {
    let res = await axios.post(`/api/organisations/create-role`, groupName);
    dispatch({ type: CREATE_ROLE });
    return res;
  } catch (err) {
    console.log({ err });
    dispatch({ type: CREATE_ROLE_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const deleteRole = (thisData) => async (dispatch) => {
  try {
    let res = await axios.post(
      `/api/organisations/delete-role/${thisData}`
    );
    dispatch({
      type: DELETE_ROLE,
      payload: thisData,
    });
    toast.success(res.message, {
      autoClose: 5000,
    });
  } catch (err) {
    console.log({ err });
    dispatch({ type: DELETE_ROLE_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const updateRole = (id, name) => async (dispatch) => {
  try {
    let res = await axios.post(`/api/organisations/update-role/${id}`, name);

    dispatch({
      type: UPDATE_ROLE,
    });

    toast.success(res.message, {
      autoClose: 5000,
    });
    return res
    // dispatch(getPaymentGroup());
  } catch (err) {
    console.log({ err });
    dispatch({ type: UPDATE_ROLE_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const getPaymentGroup = () => async (dispatch) => {
  dispatch({ type: GET_GROUP_LOADING });
  try {
    let res = await axios.get(`/api/organisations/get-group`);
    dispatch({ type: GET_GROUP, payload: res.data.data });
  } catch (err) {
    console.log({ err });
    dispatch({ type: GET_GROUP_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};
export const getPaymentLevel = () => async (dispatch) => {
  dispatch({ type: GET_LEVEL_LOADING });
  try {
    let res = await axios.get(`/api/organisations/get-level`);
    dispatch({ type: GET_LEVEL, payload: res.data.data });
  } catch (err) {
    console.log({ err });
    dispatch({ type: GET_LEVEL_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const getPaymentRole = () => async (dispatch) => {
  dispatch({ type: GET_ROLE_LOADING });
  try {
    let res = await axios.get(`/api/organisations/get-role`);
    dispatch({ type: GET_ROLE, payload: res.data.data });
  } catch (err) {
    console.log({ err });
    dispatch({ type: GET_ROLE_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const getAllWorkers = () => async (dispatch) => {
  dispatch({ type: GET_WORKER_LOADING });
  try {
    let res = await axios.get(`/api/organisations/get-workers`);
    dispatch({ type: GET_WORKER, payload: res.data.data });
  } catch (err) {
    console.log({ err });
    dispatch({ type: GET_WORKER_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const getPaymentClassWorkers = (paymentClassId) => async (dispatch) => {
  dispatch({ type: GET_CLASS_WORKER_LOADING });
  try {
    let res = await axios.get(
      `/api/organisations/get-all-paymentclass-workers?payment_class_id=${paymentClassId}`
    );
    dispatch({ type: GET_CLASS_WORKER, payload: res.data.data });
  } catch (err) {
    console.log({ err });
    dispatch({ type: GET_CLASS_WORKER_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const updateWorker = (details) => async (dispatch) => {
  dispatch({ type: UPDATE_WORKER_LOADING });
  try {
    let res = await axios.post(
      `/api/organisations/add-worker-payment-class`,
      details
    );
    dispatch({ type: UPDATE_WORKER });
    return res;
  } catch (err) {
    console.log({ err });
    dispatch({ type: UPDATE_WORKER_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const addWorker = (details) => async (dispatch) => {
  dispatch({ type: ADD_WORKER_LOADING });
  try {
    let res = await axios.post(`/api/organisations/add-worker`, details);
    dispatch({ type: ADD_WORKER });
    return res;
  } catch (err) {
    console.log({ err });
    dispatch({ type: ADD_WORKER_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const payWorkers = (details) => async (dispatch) => {
  dispatch({ type: PAY_WORKERS_LOADING });
  try {
    let res = await axios.post(`/api/organisations/pay-workers`, details);
    dispatch({ type: PAY_WORKERS });
    console.log(res);
    return res;
  } catch (err) {
    console.log({ err });
    dispatch({ type: PAY_WORKERS_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const validateWorkers = (details) => async (dispatch) => {
  dispatch({ type: VALIDATE_WORKERS_LOADING });
  try {
    let res = await axios.post(
      `/api/organisations/pay-workers-validate`,
      details
    );
    dispatch({ type: VALIDATE_WORKERS, payload: res.data.data });
  } catch (err) {
    console.log({ err });
    dispatch({ type: VALIDATE_WORKERS_FAILED });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};
