const RoundedIcon = ({ icon, link }) => {
  return (
    <a href={link}>
      <span className="tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-white tw-border-2">
        {icon}
      </span>
    </a>
  );
};

export default RoundedIcon;
