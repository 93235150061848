import React, { useState } from "react";
import { DefaultHeader, Transactions } from "../../../Component";
import { UserProfile } from "../../../Component/Payroll";

const SingleWorker = () => {
	let [stateActive, setStateActive] = useState(0),
		buttonName = ["profile", "profile history"];
	return (
		<main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader />
				<div className="d-flex justify-content-center mb-3">
					<div className="btn-group">
						{buttonName.map((item, index) => (
							<button
								key={index}
								onClick={() => setStateActive(index)}
								className={`btn text-uppercase px-5 py-3 ${
									stateActive === index
										? "btn-primary1"
										: "btn-outline-primary1"
								}`}>
								{item}
							</button>
						))}
					</div>
				</div>
				<section className="container">
					{stateActive === 0 ? <UserProfile /> : <Transactions />}
				</section>
			</div>
		</main>
	);
};

export default SingleWorker;
