import { useState } from "react";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const AppInput = ({
  select,
  label,
  password,
  type,
  pattern,
  inputType,
  ...restProps
}) => {
  const [show, setShow] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    setFocused(true);
  };

  const toggleShow = () => {
    setShow(!show);
    if (!show) {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  // console.log({ select, restProps });
  return (
    <div>
      <label htmlFor="" className="tw-text-[#000] tw-fomt-semibold">
        {label}
      </label>
      <div className="tw-relative tw-max-w-md  md:tw-mt-2">
        {select ? (
          <select
            {...restProps}
            className="tw-block tw-border-[0.05px] tw-border-gray tw-rounded-md tw-w-full tw-h-12 tw-px-8"
            onBlur={handleFocus}
            focused={focused.toString()}
            pattern={pattern}
          >
            {select?.map((item, index) => (
              <option value={item?.name} key={index}>
                {item?.name}
              </option>
            ))}
          </select>
        ) : inputType === "textArea" ? (
          <div className="tw-mb-4">
            <textarea
              rows="4"
              className="tw-block tw-border-[0.05px] tw-border-gray tw-rounded-md tw-w-full tw-bg-white tw-px-8 tw-py-4"
              {...restProps}
            />
          </div>
        ) : (
          <input
            {...restProps}
            className="w-block tw-border-[0.05px] tw-border-gray tw-rounded-md tw-w-full tw-mt-1 tw-h-12 tw-px-8"
            type={password ? passwordType : type}
            onBlur={handleFocus}
            focused={focused.toString()}
            pattern={pattern}
          />
        )}
        <span
          className="tw-absolute tw-right-10 tw-top-2.5 tw-cursor-pointer tw-text-mainShade"
          onClick={toggleShow}
        >
          {password &&
            (!show ? (
              <VisibilityOffIcon fontSize="small" />
            ) : (
              <RemoveRedEyeIcon fontSize="small" />
            ))}
        </span>
      </div>
    </div>
  );
};

export default AppInput;
