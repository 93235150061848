import React, { useState, useContext, useEffect } from "react";
import { ModalComponents, Wrapper } from "../../Component";
import { GlobalState } from "../../Data/Context";
import moment from "moment";
import { Buttons, ImageView, OtpComponent } from "../../Utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import app from "../../Data/Counties";

import AppButton from "../../components/button/button.component";
import Footer from "../../components/footer/footer.component";
import AppInput from "../../components/input/input.component";

const CreateAccount = () => {
  const { registerUser, auth } = useContext(GlobalState);
  let [stateData, setStateData] = useState({
    email: "",
    name: "",
    company_logo: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    country: "",
    incorporation_year: "",
    no_of_users: "",
    password: "",
    address: "",
    confirm_password: "",
  });
  let [loading, setLoading] = useState(false);
  let [loading2, setLoading2] = useState(false),
    [isOpen, setIsOpen] = useState(false),
    [typePass, setTypePass] = useState(false),
    [typePass2, setTypePass2] = useState(false),
    toggle = () => {
      setIsOpen(!isOpen);
    },
    [isOpen2, setIsOpen2] = useState(false),
    toggle2 = () => {
      setIsOpen2(!isOpen2);
    },
    [isOpen3, setIsOpen3] = useState(false),
    toggle3 = () => {
      setIsOpen3(!isOpen3);
    };

  let {
    address,
    email,
    name,
    company_logo,
    phone_number,
    country,
    incorporation_year,
    no_of_users,
    password,
    confirm_password,
    first_name,
    last_name,
  } = stateData;

  let textChange =
    (name) =>
    ({ target: { value } }) => {
      setStateData({ ...stateData, [name]: value });
    };

  let setImages = (file) => {
    setStateData({ ...stateData, company_logo: file });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading2(true);
    await registerUser(stateData);
    setLoading2(false);
  };

  useEffect(() => {
    if (auth.firstStep) {
      toggle();
    } else if (auth.twoStep) {
      toggle2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.twoStep, auth.firstStep]);
  return (
    <div>
      <section
        style={{ background: "#FCFEFF" }}
        className="container tw-mx-auto tw-px-8 md:tw-px-0 tw-pt-32 tw-pb-20"
      >
        <p className="tw-font-bold tw-capitalize tw-text-3xl">welcome</p>
        <p className="tw-text-sm tw-text-[#000] tw-font-semibold tw-mt-4 tw-mb-8">
          Welcome! Please enter your details.
        </p>

        <div className="tw-grid tw-gap-12 lg:tw-grid-cols-3 md:tw-grid-cols-2">
          <div className="lg:tw-col-span-2 tw-mt-8 tw-order-2 md:tw-order-1">
            <div className="mb-2 createaccountImg">
              <label className="tw-text-xl tw-font-semibold tw-text-[#000] py-3">Company logo</label>
              <ImageView
                file={"company_logo"}
                images={company_logo}
                setImages={setImages}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
            <form>
              <div className="tw-grid tw-gap-x-12 tw-gap-4 lg:tw-grid-cols-2">
                <AppInput
                  label="Company Name"
                  placeholder="Full Name"
                  type="text"
                  value={name}
                  onChange={textChange("name")}
                />
                <AppInput
                  label="First Name"
                  placeholder="Full Name"
                  type="text"
                  value={first_name}
                  onChange={textChange("first_name")}
                />
                <AppInput
                  label="Last Name"
                  placeholder="Full Name"
                  type="text"
                  value={last_name}
                  onChange={textChange("last_name")}
                />
                <AppInput
                  label="Email"
                  placeholder="Email Address"
                  type="email"
                  value={email}
                  onChange={textChange("email")}
                />
                <AppInput
                  label="Phone"
                  placeholder="+234 81000000000"
                  type="number"
                  value={phone_number}
                  onChange={textChange("phone_number")}
                />
                <AppInput
                  label="Country"
                  placeholder="Nigeria"
                  select={app}
                  type="select"
                  value={country}
                  onChange={textChange("country")}
                />
                <AppInput
                  label="Incoporation Year"
                  placeholder="20-12-2022"
                  type="date"
                  max={moment().format("YYYY-MM-DD")}
                  value={incorporation_year}
                  onChange={textChange("incorporation_year")}
                />
                <AppInput
                  label="Number fo Users"
                  placeholder="2"
                  type="number"
                  min={2}
                  value={no_of_users}
                  onChange={textChange("no_of_users")}
                />
                <AppInput
                  label="Create Password"
                  placeholder="0000"
                  type="text"
                  value={password}
                  onChange={textChange("password")}
                  password
                />
                <AppInput
                  label="Confirm Password"
                  placeholder="0000"
                  type="text"
                  value={confirm_password}
                  onChange={textChange("confirm_password")}
                  password
                />
              </div>
              <div className="tw-mt-8">
                {/* <AppButton buttonType="secondary" children="create account" /> */}
                <Buttons
                  title={"Create Account"}
                  css={"text-white my-3"}
                  style={{
                    backgroundColor: "#031A6E",
                    padding: "10px 18px",
                  }}
                  loading={loading2}
                  width
                  type="submit"
                  onClick={handleSubmit}
                />
              </div>
            </form>
          </div>
          <div className="tw-order-1 md:tw-order-2 tw-self-center">
            <img
              src={require("../../assets/create-account.png")}
              alt=""
              className="tw-mx-auto"
            />
          </div>
        </div>
      </section>
      <section>
        <VerificationCode isOpen={isOpen} toggle={toggle} />
        <SecretPinAuthenticate
          isOpen={isOpen2}
          toggle={toggle2}
          toggle3={toggle3}
        />
        <SetSecretPinAuthenticate
          isOpen={isOpen3}
          toggle={toggle3}
          toggle2={toggle2}
        />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default CreateAccount;

export let VerificationCode = ({ isOpen, toggle }) => {
  const { loadUser } = useContext(GlobalState);
  let [code, setCode] = useState("");
  let [loading2, setLoading2] = useState(false),
    [submit, setSubmit] = useState(false),
    navigate = useNavigate(),
    [verified, setVerify] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading2(true);
    try {
      let res = await axios.post("/api/verify", { code });
      toast.success(res.data.message);
      setVerify(true);
    } catch (err) {
      console.log({ err });
      let error = err.response?.data;
      if (error) {
        if (error.messgage === "User already Verified") {
          setVerify(true);
        }
        console.log({ error });
        toast.error(
          error.error && typeof error.error === "string"
            ? error.error
            : error.message,
          { autoClose: false }
        );
      }
    }

    setLoading2(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (submit && verified) {
      if (isOpen) {
        toggle();
        let getUser = async () => {
          await loadUser();
        };
        getUser();
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, isOpen, verified]);

  return (
    <ModalComponents isOpen={isOpen} back={toggle} title="verification code">
      <form>
        <p className="text-center">
          Please input the verification code sent to your mail
        </p>
        <div className="d-flex justify-content-center my-5 mx-auto">
          <OtpComponent
            stateData={code}
            textChange={(data) => {
              setCode(data);
            }}
            numInputs={4}
            css="borderColor"
            loading={loading2}
          />
        </div>
        <Buttons
          title={"verify"}
          css={"text-white my-3 mx-auto"}
          style={{
            backgroundColor: "#031A6E",
            padding: "10px 18px",
          }}
          loading={loading2}
          type="submit"
          onClick={handleSubmit}
        />
      </form>
    </ModalComponents>
  );
};

export let SecretPinAuthenticate = ({ isOpen, toggle, toggle3 }) => {
  const { loadUser } = useContext(GlobalState);
  let [code, setCode] = useState("");
  let [loading2, setLoading2] = useState(false),
    [submit, setSubmit] = useState(false),
    navigate = useNavigate(),
    [verified, setVerify] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading2(true);
    try {
      let res = await axios.post("/api/auth2", {
        secret_pin: code,
      });
      toast.success(res.data.message);
      setVerify(true);
    } catch (err) {
      console.log({ err });
      let error = err.response?.data;
      if (error) {
        console.log({ error });
        toast.error(
          error.error && typeof error.error === "string"
            ? error.error
            : error.message,
          { autoClose: false }
        );
      }
    }

    setLoading2(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (submit && verified) {
      if (isOpen) {
        toggle();
        let getUser = async () => {
          await loadUser();
        };
        getUser();
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, isOpen, verified]);

  return (
    <ModalComponents isOpen={isOpen} back={toggle} title="input Secret pin">
      <form>
        <p className="text-center">
          Please input your secret pin to verify that it is you
        </p>
        {/* <div className="form-group mb-1">
					<label className="label text-capitalize">Secret pin</label>
					<input
						type="number"
						className="form-control formWidth"
						placeholder="Code"
						onChange={e => setCode(e.target.value)}
						value={code}
					/>
				</div> */}
        <div className="d-flex justify-content-center my-5 mx-auto">
          <OtpComponent
            stateData={code}
            textChange={(data) => {
              setCode(data);
            }}
            css="borderColor"
            loading={loading2}
          />
        </div>
        <Buttons
          title={"send"}
          css={"text-white my-3 mx-auto"}
          style={{
            backgroundColor: "#031A6E",
            padding: "10px 18px",
          }}
          loading={loading2}
          type="submit"
          onClick={handleSubmit}
        />
      </form>
      <button
        className="btn btn-light text-capitalize d-block ms-auto mt-3"
        onClick={(e) => {
          e.preventDefault();
          toggle();
          toggle3();
        }}
      >
        no secret pin yet?
      </button>
    </ModalComponents>
  );
};

export let SetSecretPinAuthenticate = ({ isOpen, toggle, toggle2 }) => {
  const { loadUser } = useContext(GlobalState);
  let [code, setCode] = useState("");
  let [loading2, setLoading2] = useState(false),
    [submit, setSubmit] = useState(false),
    navigate = useNavigate(),
    [verified, setVerify] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading2(true);
    try {
      let res = await axios.post("/api/organisation-update", {
        organisation_secret_pin: code,
      });
      toast.success(res.data.message);
      setVerify(true);
    } catch (err) {
      console.log({ err });
      let error = err.response?.data;
      if (error) {
        console.log({ error });
        toast.error(
          error.error && typeof error.error === "string"
            ? error.error
            : error.message,
          { autoClose: false }
        );
      }
    }

    setLoading2(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (submit && verified) {
      if (isOpen) {
        toggle();
        let getUser = async () => {
          await loadUser();
        };
        getUser();
        navigate("/login");
        toggle2();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, isOpen, verified]);

  return (
    <ModalComponents isOpen={isOpen} back={toggle} title="set Secret pin">
      <form>
        <p className="text-center">
          Please set a secret pin to verify that it is you
        </p>
        <div className="d-flex justify-content-center my-5 mx-auto">
          <OtpComponent
            stateData={code}
            textChange={(data) => {
              setCode(data);
            }}
            css="borderColor"
            loading={loading2}
          />
        </div>
        <Buttons
          title={"send"}
          css={"text-white my-3 mx-auto"}
          style={{
            backgroundColor: "#031A6E",
            padding: "10px 18px",
          }}
          loading={loading2}
          type="submit"
          onClick={handleSubmit}
        />
      </form>
      <button
        className="btn btn-light text-capitalize d-block ms-auto mt-3"
        onClick={(e) => {
          e.preventDefault();
          toggle();
          toggle2();
        }}
      >
        have a secret pin?
      </button>
    </ModalComponents>
  );
};
