const colorArray = [
  { one: "#A40606", two: "#D98324" },
  { one: "#380036", two: "#0CBABA" },
  { one: "#0D324D", two: "#7F5A83" },
  { one: "#7C98B3", two: "#637081" },
  { one: "#B02E0C", two: "#EB4511" },
  { one: "#0C0C0C", two: "#4834D4" },
  { one: "#5F0F40", two: "#310E68" },
  { one: "#313131", two: "#6DADDB" },
  { one: "#170E13", two: "#7A7ADB" },
  { one: "#009FC2", two: "#0D0A0B" },
  { one: "#20A4F3", two: "#182B3A" },
  { one: "#009FC2", two: "#0D0A0B" },
  { one: "#5F0A87", two: "#A4508B" },
  { one: "#3E0000", two: "#7C0000" },
  { one: "#647DEE", two: "#7F53AC" },
  { one: "#247BA0", two: "#FFFFB5" },
  { one: "#009FC2", two: "#0D0A0B" },
  { one: "#5F0F40", two: "#310E68" },
  { one: "#A40606", two: "#D98324" },
  { one: "#380036", two: "#0CBABA" },
  { one: "#0D324D", two: "#7F5A83" },
  { one: "#7C98B3", two: "#637081" },
  { one: "#B02E0C", two: "#EB4511" },
  { one: "#0C0C0C", two: "#4834D4" },
  { one: "#5F0F40", two: "#310E68" },
  { one: "#313131", two: "#6DADDB" },
  { one: "#170E13", two: "#7A7ADB" },
  { one: "#009FC2", two: "#0D0A0B" },
  { one: "#20A4F3", two: "#182B3A" },
  { one: "#009FC2", two: "#0D0A0B" },
  { one: "#5F0A87", two: "#A4508B" },
  { one: "#3E0000", two: "#7C0000" },
  { one: "#647DEE", two: "#7F53AC" },
  { one: "#247BA0", two: "#FFFFB5" },
  { one: "#009FC2", two: "#0D0A0B" },
  { one: "#5F0F40", two: "#310E68" },
];

export default colorArray
