// import HeroBg from "../../assets/bg-1.png";
import AppButton from "../../components/button/button.component";
import Feature from "../../components/feature/feature.component";
import Footer from "../../components/footer/footer.component";

import vector from "../../assets/vector.png";
import { Bounce, Fade, Slide, Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const tools = [
  {
    title: "Payroll management",
    text: "CEBIZ mobile app help business organisations and cooperative society in payroll management",
    type: "green",
  },
  {
    title: "Staff management",
    text: "CEBIZ mobile app helps business managers with the process of overseeing and coordinating the activities of employees within the organisation to ensure that they are working effectively and efficiently towards the goals of the business.",
  },
  {
    title: "Saving management",
    text: "CEBIZ 	Mobile apps make it easy for business organisations to manage the savings of their employees and also to keep track of all the members of the organisation.",
  },
  {
    title: "Inventory management",
    text: "CEBIZ mobile app helps business organisations and cooperative societies to manage the flow of goods and materials into and out of the organisation, managing the stock levels of products or raw materials to ensure that there is always enough on hand to meet customer demand, while minimising the costs of carrying excess inventory.",
  },
  {
    title: "Chargeless Transactions",
    text: "We provide chargeless transactions, which are all of our transactions without any fees or charges for savings, wallet-to-wallet transfers, bill payment, etc.",
  },
  {
    title: "Report generation",
    text: "CEBIZ mobile app makes it easy for business organisations and cooperative societies to generate reports of their activities, either daily, monthly, biannual, annual, etc.",
    type: "yellow",
  },
  {
    title: "Dashboard",
    text: "Direct access to important interfaces for your business management.",
    type: "green",
  },
  {
    title: "Project Management",
    text: "Display metrics, stats and insights into your project performance.",
  },
  {
    title: "File Management",
    text: "An organized structure in which you store information for easy retrieval.",
  },
  {
    title: "Financial Management",
    text: "Helps plan, organize, and control all transactions in your business.",
  },
  {
    title: "Payroll Management",
    text: "Provides financial records of employees and employer's payroll tax liability.",
    icon: "fa-dollar-sign",
  },
  {
    title: "KPI Management",
    text: "Helps you monitor and evaluate your employees' work to accomplish the company's goals.",
    type: "blue",
    icon: "fa-star",
  },
  {
    title: "Management Reports",
    text: "Data of different aspects of the business, to help them make better-informed decisions.",
    icon: "fa-file",
  },
  {
    title: "Employee Management",
    text: "Comprehensive feature that encompasses practically all aspects of human resources.",
    type: "yellow",
    icon: "fa-users",
  },
  {
    title: "Asset Management",
    text: "Keeps the record of all assets and equipments that are purchased and rented.",
    icon: "fa-desktop",
  },
  {
    title: "Client Management",
    text: "Coordinates your organization's interactions with its clients and potential clients.",
    type: "blue",
    icon: "fa-user",
  },
  {
    title: "Spreadsheets",
    text: "Helps business for computation, organization, analysis and storage of data.",
    icon: "fa-file",
  },
  {
    title: "Quickbooks API",
    text: "Financial management suite complete with tools for accounting related tasks.",
    icon: "fa-dollar-sign",
  },
  {
    title: "Freshdesk API",
    text: "Customer service software providing helpdesk support with all smart automations.",
    icon: "fa-assistive-listening-systems",
  },
  {
    title: "Departments",
    text: "Define how activities are directed toward the achievement of your organizational aims.",
    icon: "fa-crosshairs",
  },
  {
    title: "Shift Management",
    text: "Assist with arranging and schedule employee shifts with no complexity.",
    type: "yellow",
    icon: "fa-users",
  },
  {
    title: "Recruitment Tool",
    text: "A set of tools designed to manage your company's recruitment processes",
    icon: "fa-users",
  },
  {
    title: "Calender for Planning",
    text: "A scheduling software tool to use for all of your business planning needs.",
    icon: "fa-calendar",
    type: "green",
  },
  {
    title: "Email Management",
    text: "Configure your company's office email and access it with the business management suite.",
    icon: "fa-envelope",
  },
  {
    title: "Warning Management",
    text: "Document every warning isseued to employees notifying them of a breach of company protocol.",
    type: "blue",
    icon: "fa-exclamation-triangle",
  },
  {
    title: "Employee Promotion",
    text: "Document employee promotion to higher positions and other things associated with it.",
    icon: "fa-users",
  },
  {
    title: "Task Management",
    text: "Track every piece of work that needs to be done employees especially in routine tasks.",
    icon: "fa-tasks",
  },
  {
    title: "Staff Termination",
    text: "Document every employee termination details for future reference.",
    icon: "fa-user-alt-slash",
  },
  {
    title: "Attendance Tool",
    text: "A tool that helps your company track employee attendance and working hours.",
    icon: "fa-tasks",
  },
  {
    title: "Leave Management",
    text: "Allow employees to request leave and managers to approve requests made by employees.",
    type: "yellow",
    icon: "fa-user-clock",
  },
  {
    title: "Training Management",
    text: "Document every planned set of trainings for imparting knowledge into employees",
    icon: "fa-chalkboard-teacher",
  },
  {
    title: "Transactions",
    text: "TransactionsDaily activities central to the company's accounting and financial management.",
    type: "green",
    icon: "fa-dollar-sign",
  },
  {
    title: "Announcements",
    text: "Create announcement & circulate across the organization or employee groups.",
    icon: "fa-bullhorn",
  },
  {
    title: "Resignation",
    text: "You can easily create, manage and track employee resignations within the organization.",
    type: "yellow",
    icon: "fa-user-alt-slash",
  },
  {
    title: "Bug Tracking Tool",
    text: "A tool to log and monitor bugs or errors during project management.",
    icon: "fa-bug",
  },
  {
    title: "Opportunity Tracker",
    text: "This tools helps you track opportunities and business deals out there.",
    icon: "fa-gem",
  },
  {
    title: "Lead Management",
    text: "This tool allows sales team to manage and track lead activity along the path to sale.",
    icon: "fa-user-tag",
  },
  {
    title: "Sales Management",
    text: "Provides data-driven customer insights, automated workflow and better forecasting.",
    type: "green",
    icon: "fa-hand-holding-usd",
  },
  {
    title: "Ticket Management",
    text: "Tracks the status of each ticket as support staff members work on solving the issue.",
    icon: "fa-assistive-listening-systems",
  },
  {
    title: "Knowledge Base",
    text: "Manages knowledge to improve understanding, collaboration and process alignment.",
    type: "blue",
    icon: "fa-lightbulb",
  },
  {
    title: "Utility Management",
    text: "Helps record all holidays, set new goals and tracks the goal achievements.",
    icon: "fa-calendar-alt",
  },
  {
    title: "Settings Management",
    text: "Allows to define settings, and maintain them according to the established baselines.",
    type: "yellow",
    icon: "fa-cog",
  },
  {
    title: "Database Backup",
    text: "Database backup and recovery is part of the Business suite, that no data gets lost.",
    icon: "fa-database",
  },
  {
    title: "Zoom Meeting API",
    text: "Set up meetings, store recordings in the cloud accessible by the ERP via API.",
    icon: "fa-video",
  },
  {
    title: "Private and Group Chat",
    text: "Employees of your organization can privately chat and within group.",
    type: "green",
    icon: "fa-comments",
  },
  {
    title: "Data subscription",
    text: "CEBIZ 	Mobile apps make it easy for users to subscribe to mobile data plans of any kind, purchases of airtime of any network,and manage their data usage.",
    icon: "",
  },
  {
    title: "Cables subscription",
    text: "CEBIZ 	Mobile apps make it easy for users to subscribe to cables of any kind, that is TV subscription of any kind.",
    type: "blue",
    icon: "",
  },
  {
    title: "E-pin for Examination",
    text: "CEBIZ mobile app helps users with e-pin for exams such as NECO, WAEC, UTME, etc.",
    icon: "",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(false);

  const goToCreateAccount = () => {
    navigate("/create-account");
  };
  return (
    <div>
      <header
        className="tw-pt-32 md:tw-pt-40 tw-pb-12 tw-bg-dark tw-relative tw-overflow-hidden"
        // style={{ background: `url(${HeroBg})` }}
      >
        <div className="tw-absolute -tw-right-20 -tw-top-20 -tw-bottom-20">
          <img
            src={require("../../assets/hero-eclipse-xl.png")}
            alt=""
            className="tw-object-cover tw-h-"
          />
        </div>

        <div className="tw-absolute -tw-bottom-4 -tw-left-4">
          <img
            src={require("../../assets/hero-eclipse-sm.png")}
            alt=""
            className="tw-w-auto"
          />
        </div>
        <div className="tw-absolute -tw-inset-0">
          <img
            src={require("../../assets/grid-lines.png")}
            alt=""
            className="tw-object-cover tw-h-full"
          />
        </div>
        <div className="container tw-px-8 md:tw-px-0 tw-mx-auto">
          <div className="tw-mt-8 tw-grid md:tw-grid-cols-2 tw-gap-12">
            <div className="">
              <Zoom bottom>
                <h1 className="tw-text-white hero-text tw-text-4xl md:tw-text-6xl tw-leading-tight">
                  There's no wellness without financial{" "}
                  <span>
                    wellness <img src={vector} alt="" className="tw-w-auto" />
                  </span>
                </h1>
              </Zoom>
              <Zoom clear>
                <p className="tw-text-sm tw-text-white tw-w-3/4 tw-mt-8 tw-font-light">
                  You can now conduct transactions with our new platform from
                  anywhere at any time
                </p>
              </Zoom>
              <div className="tw-mt-10">
                <AppButton
                  children="get started"
                  buttonType="white"
                  onClick={goToCreateAccount}
                />
              </div>
              <div className="tw-flex tw-items-center tw-mt-16">
                <img
                  src={require("../../assets/apple-store.png")}
                  alt=""
                  className="tw-w-auto"
                />
                <img
                  src={require("../../assets/google-store.png")}
                  alt=""
                  className="tw-w-auto"
                />
              </div>
              <div className="tw-mt-12 tw-flex tw-flex-wrap tw-items-bottom tw-gap-x-4">
                <p className="tw-text-md tw-leading-none tw-whitespace-nowrap text-white">
                  Excellent {"  "}
                  <span className="tw-text-[#2D9CDB]"> 4.9 out of 5</span>
                </p>
                <img
                  src={require("../../assets/5-star.png")}
                  alt="five star"
                  className="tw-h-4 tw-w-auto"
                />
              </div>
            </div>
            <div className="tw-relative">
              <Fade right>
                <img
                  src={require("../../assets/new-hero-image.png")}
                  alt="hero"
                  className="tw-w-auto tw-h-[350px] md:tw-h-[500px]"
                />
              </Fade>
            </div>
          </div>
        </div>
      </header>
      <section className="tw-bg-mainShade tw-text-white tw-py-20">
        <div className="container tw-px-8 md:tw-px-0 tw-mx-auto">
          <div className="tw-flex tw-justify-between tw-flex-wrap md:tw-flex-nowrap tw-gap-12">
            <div className="">
              <Slide bottom>
                <p className="tw-text-white tw-text-3xl md:tw-text-5xl tw-capitalize tw-max-w-md tw-font-medium tw-leading-tight">
                  why you have <br />{" "}
                  <span>
                    To choose <img src={vector} alt="" />
                  </span>{" "}
                  us
                </p>
              </Slide>
              <div className="tw-border tw-max-w-[50%] tw-ml-16 tw-border-gray tw-mt-8 tw-mb-2 tw-opacity-40"></div>
              <Slide bottom>
                <p className="tw-text-white tw-text-xs tw-max-w-sm tw-font-normal tw-leading-loose">
                  Proper financial management helps the growth of an
                  organisation. This requires discipline, and financial
                  management experience which many people lack. Cebiz software
                  helps you automate your financial obligations and organisation
                  activities such as payment of staffs, inventory management,
                  Human resource management, savings for members within the
                  organisation Bill payments etc. CEBIZ assists organisation and
                  individuals to reach their goals
                </p>
              </Slide>
            </div>
            <div>
              <Slide bottom>
                <div className="tw-flex tw-gap-8 tw-items-center md:tw-items-end tw-border-b tw-pb-4 tw-mb-4 tw-border-gray">
                  <div>
                    <img src={require("../../assets/impact.png")} alt="" />
                    <p className="tw-text-[10px] tw-text-gray tw-mt-2">
                      Impact
                    </p>
                    <p className="tw-text-white tw-font-medium tw-text-xl md:tw-text-2xl tw-mt-2 tw-leading-none">
                      100+
                    </p>
                  </div>
                  <p className="tw-text-white tw-text-xs tw-max-w-xs tw-font-normal">
                    "Together, we can make a difference. Let's create a positive
                    impact and leave a lasting legacy." <br /> Our three-pronged
                    corporate responsibility and sustainability (CRS)
                    approach—citizenship, stakeholder management, and impact
                    management—is heavily influenced by our commitment to
                    nation-building.
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-flex tw-gap-8 tw-items-center md:tw-items-end tw-border-b tw-pb-4 tw-mb-4 tw-border-gray">
                  <div>
                    <img src={require("../../assets/customers.png")} alt="" />
                    <p className="tw-text-[10px] tw-text-gray tw-mt-2">
                      Customers
                    </p>
                    <p className="tw-text-white tw-font-medium tw-text-xl md:tw-text-2xl tw-mt-2 tw-leading-none">
                      70+
                    </p>
                  </div>
                  <p className="tw-text-white tw-text-xs tw-max-w-xs tw-font-normal">
                    With fractional shares on CEBIZ, we're delivering trusted
                    international savings alternatives to every African,
                    offering any organisation with a CEBIZ account the chance to
                    grow their money anywhere in the globe.
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-flex tw-gap-8  tw-items-center md:tw-items-end tw-font-normal tw-mb-4">
                  <div>
                    <img src={require("../../assets/growth.png")} alt="" />
                    <p className="tw-text-[10px] tw-text-gray tw-mt-2">
                      Growth
                    </p>
                    <p className="tw-text-white tw-font-medium tw-text-xl md:tw-text-2xl tw-mt-2 tw-leading-none">
                      20%/ <br /> month
                    </p>
                  </div>
                  <p className="tw-text-white tw-text-xs tw-max-w-xs tw-leading-tight">
                    Lorem ipsum dolor sit amet consectetur. A tristique tempor
                    ultrices.
                  </p>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container tw-px-8 md:tw-px-0 tw-mx-auto">
          <div className="tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-16 tw-my-20">
            <div>
              <Slide bottom>
                <p className="tw-text-3xl md:tw-text-5xl tw-text-dark tw-leading-tight tw-font-medium">
                  All Features{" "}
                  <span>
                    You Need In One <img src={vector} alt="" />
                  </span>{" "}
                  Place
                </p>
              </Slide>
            </div>
            <div className="md:tw-col-span-2 md:tw-px-8">
              <Slide bottom>
                <p className="tw-text-sm md:tw-font-medium text-black tw-leading-loose">
                  A FinTech app called CEBIZ assists private and public
                  institutions, corporate societies, businesses, and individuals
                  for financial management such as , payroll, inventory ,
                  management of customers/users savings , Human resource
                  management. A Mobile app platform will be configured for your
                  organisation for receiving salary/wages. You can create
                  savings group for the members of the organisation to grow
                  their income. <br /> Bill payments, such as Cable TV,
                  Electricity Subscription, Data and Airtime purchase are also
                  available on the mobile application. <br /> A dashboard will
                  be given to your organisation to manage you members and your
                  finance. Create an Account Now!
                </p>
              </Slide>
            </div>
            {seeMore
              ? tools.map((tool, idx) => (
                  <Feature
                    title={tool.title}
                    text={tool.text}
                    type={tool.type}
                    key={idx}
                  />
                ))
              : tools
                  .filter((_, idx) => idx < 9)
                  .map((tool, idx) => (
                    <Feature
                      title={tool.title}
                      text={tool.text}
                      type={tool.type}
                      key={idx}
                    />
                  ))}
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <p
              className="tw-font-bold text-dark tw-py-4 tw-px-8 tw-inline-block rounded tw-capitalize tw-text-right tw-cursor-pointer tw-bg-[#F2994A]"
              onClick={() => setSeeMore(!seeMore)}
            >
              {seeMore ? "show less" : "see more"}
            </p>
          </div>
        </div>
      </section>
      <section className="tw-bg-mainShade tw-text-white tw-py-20">
        <div className="container tw-px-8 md:tw-px-0 tw-mx-auto">
          <div className="tw-grid md:tw-grid-cols-2">
            <div>
              <Slide bottom>
                <p className="tw-text-white tw-text-5xl tw-capitalize tw-font-medium tw-leading-tight tw-mb-12">
                  Saving today, <br /> for a better <br />{" "}
                  <span>
                    tomorrow. <img src={vector} alt="" />
                  </span>
                </p>
              </Slide>
              <Slide bottom>
                <p className="tw-text-white tw-text-xs tw-max-w-sm tw-text-normal tw-font-normal tw-leading-loose">
                  Start building your financial future with smart savings
                  habits." Saving money is a habit that must be developed over
                  time. Relax while CEBIZ assists you in forming the habit.
                  You'll notice a growth in your savings over time. Start
                  building your financial future with smart savings habits.
                </p>
              </Slide>
            </div>
            <div>
              <div className="tw-flex tw-gap-8 tw-items-end tw-justify-between tw-max-w-xl tw-border-b tw-pb-4 tw-mb-4 tw-border-gray">
                <Slide bottom>
                  <div>
                    <img src={require("../../assets/signup-star.png")} alt="" />
                    <p className="tw-text-white tw-font-medium tw-text-lg tw-mt-4 tw-leading-none tw-capitalize">
                      sign up
                    </p>
                  </div>
                  <p className="tw-text-white tw-text-xs tw-max-w-xs tw-leading-tight">
                    Join the community and unlock exclusive access! Sign up now!
                  </p>
                </Slide>
              </div>
              <div className="tw-flex gap-8 tw-items-end tw-justify-between tw-max-w-xl tw-border-b tw-pb-4 tw-mb-4 tw-border-gray">
                <Slide bottom>
                  <div>
                    <img src={require("../../assets/verify-star.png")} alt="" />
                    <p className="tw-text-white tw-font-medium tw-text-lg tw-mt-4 tw-leading-none tw-capitalize">
                      verify
                    </p>
                  </div>
                  <p className="tw-text-white tw-text-xs tw-max-w-xs tw-leading-tight">
                    Verify your account to unlock exclusive features and enhance
                    your security.
                  </p>
                </Slide>
              </div>
              <div className="tw-text-white tw-flex tw-gap-8 tw-items-end tw-justify-between tw-max-w-xl tw-border-b tw-pb-4 tw-mb-4 tw-border-gray">
                <Slide bottom>
                  <div>
                    <img
                      src={require("../../assets/savings-star.png")}
                      alt=""
                    />
                    <p className="tw-text-white tw-font-medium tw-text-lg tw-mt-4 tw-leading-none tw-capitalize">
                      start saving
                    </p>
                  </div>
                  <p className="tw-text-white tw-text-xs tw-max-w-xs tw-leading-tight">
                    Start Saving Today: Invest in Your Future and Achieve Your
                    Financial Goals.
                  </p>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container tw-px-8 md:tw-px-0 tw-mx-auto tw-my-20">
          <Bounce>
            <p className="tw-text-5xl tw-max-w-lg tw-mx-auto tw-text-center tw-capitalize tw-font-medium tw-leading-tight tw-mb-12">
              Manage Your Money Today Easily With Our{" "}
              <span>
                Services <img src={vector} alt="" className="tw-mx-auto" />
              </span>
            </p>
          </Bounce>
          <Slide bottom>
            <p className="tw-text-sm tw-text-gray tw-text-center tw-mx-auto tw-capitalize tw-max-w-sm tw-font-medium tw-leading-tight tw-mb-12">
              Join now to get started with CEBIZ, which can help you manage your
              money more effectively, stop overspending, and be more
              responsible. Join the community and unlock exclusive access!!
            </p>
          </Slide>
          <div className="tw-relative tw-flex tw-justify-center tw-items-center">
            <p className="tw-text-gray tw-uppercase tw-text-2xl tw-opacity-50 tw-hidden sm:tw-block -tw-z-10 tw-absolute tw-left-4">
              get started
            </p>
            <button
              className="tw-text-white tw-bg-mainShade tw-uppercase tw-text-2xl tw-py-4 tw-px-16 tw-font-medium tw-rounded-full"
              onClick={goToCreateAccount}
            >
              get started
            </button>
            <p className="tw-text-gray tw-uppercase tw-text-2xl tw-opacity-50 tw-hidden sm:tw-block -tw-z-10 tw-absolute tw-right-4">
              get started
            </p>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
