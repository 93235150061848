import AppButton from "../../components/button/button.component";
import Partners from "../../components/partners/partners.component";
import Footer from "../../components/footer/footer.component";
import DoneIconComponent from "../../components/done-icon/done-icon";

import "./savings.style.css";
import { Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";

const Savings = () => {
  const navigate = useNavigate();

  const goTocreateAccount = () => navigate("/create-account");
  return (
    <div>
      <section className="tw-bg-mainShade">
        <div className="tw-container tw-px-8 md:tw-px-0 tw-mx-auto tw-py-10">
          <div className="tw-grid md:tw-grid-cols-2">
            <div className="tw-self-center">
              <Slide bottom>
                <p className="tw-text-white tw-text-2xl md:tw-text-4xl tw-font-medium tw-font-mono">
                  Create Organizational <br />
                  <span className="tw-text-secondary"> Savings</span>
                </p>
              </Slide>
              <Slide bottom>
                <p className="tw-text-sm tw-font-light tw-text-white tw-opacity-60 tw-mt-8">
                  Cebiz pay mobile app helps organisations in Payroll
                  management, inventory management, staff management, saving
                  smarter, growing stronger. And also helps in eliminating
                  financial worries by providing a stress free way to pay your
                  bills and take control of your finances.
                </p>
              </Slide>
              <div className="tw-mt-8">
                <AppButton
                  children="create organization"
                  buttonType="secondary"
                  onClick={goTocreateAccount}
                />
              </div>
            </div>
            <div>
              <img
                src={require("../../assets/savings-hero.png")}
                alt=""
                className="tw-mx-auto"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="tw-relative">
        <div className="tw-absolute tw-inset-0 tw-bg-mainLight tw-opacity-10 -tw-z-10"></div>
        <div className="tw-container tw-px-8 md:tw-px-0 tw-mx-auto tw-grid md:tw-grid-cols-3 tw-gap-12 tw-py-20">
          <div className="md:tw-col-span-2 tw-order-2 md:tw-order-1">
            <Slide bottom>
              <p className="tw-text-3xl md:tw-text-4xl tw-font-normal">
                Lorem ipsum <span className="tw-text-secondary">dolor</span>
              </p>
            </Slide>
            <Slide bottom>
              <p className="tw-mt-8 md:tw-w-4/5 tw-text-xs">
                Lorem ipsum dolor sit amet consectetur. Nec adipiscing arcu in
                dui dui pharetra dignissim tincidunt. A amet lacus orci viverra
                mi tempor. Tempor enim sit odio sit eget nulla sed. Diam eget
                consectetur tortor sit leo condimentum cras lectus bibendum. At
                bibendum purus imperdiet lobortis aliquam feugiat. Magna diam
                malesuada egestas tellus. Elementum tortor facilisis ullamcorper
                phasellus ornare lacus tristique. Sit in placerat malesuada
                tristique porta aenean. Elit in faucibus sed.
              </p>
            </Slide>
          </div>
          <div className=" tw-order-1 md:tw-order-2">
            <Slide bottom>
              <img
                src={require("../../assets/savings-pana.png")}
                alt=""
                className="tw-h-40 tw-mx-auto"
              />
            </Slide>
          </div>
        </div>
      </section>
      <section className="tw-bg-mainShade">
        <div className="tw-container tw-px-8 md:tw-px-0 tw-mx-auto tw-py-20">
          <Slide bottom>
            <p className="tw-text-4xl tw-text-white tw-text-center tw-capitalize tw-mb-8">
              our <span className="tw-text-secondary">partners</span>
            </p>
          </Slide>
          <div className="tw-flex tw-justify-center tw-gap-y-12 tw-flex-wrap tw-text-white tw-px-8">
            <Partners
              text="reno is here to simplify the process."
              image="houz.png"
            />
            <Partners
              text="In addition to getting a contractor, you're also getting the eye of a design professional."
              image="houz.png"
            />
            <Partners
              text="Streamlining timely aspects like layout, materials selection, and building approvals"
              image="houz.png"
            />
            <Partners
              text="This startup is on a mission to make home renovations easier"
              image="houz.png"
            />
          </div>
        </div>
      </section>
      <section className="tw-relative">
        <div className="tw-absolute tw-inset-0 tw-bg-mainLight tw-opacity-10 -tw-z-10"></div>
        <div className="tw-container tw-px-8 tw-mx-auto tw-bg-[#F2F1FF] tw-py-20">
          <Slide bottom>
            <p className="tw-text-5xl tw-text-main tw-text-center tw-font-bold tw-capitalize">
              Affordable pricing plan
            </p>
          </Slide>
            <div class="tw-flex tw-justify-center mt-4">
              <div className="tw-p-2 bg-white tw-rounded-full tw-gap-4 tw-flex">
                <span className="tw-py-4 tw-px-10 tw-rounded-full tw-text-white tw-bg-gradient-to-r tw-from-[#2272FF] tw-to-[#0D03C3] tw-cursor-pointer">
                  Monthly
                </span>
                <span className="tw-py-4 tw-px-10 tw-rounded-full tw-cursor-pointer hover:tw-bg-gradient-to-r hover:tw-from-[#2272FF] hover:tw-to-[#0D03C3] hover:tw-text-white">
                  Anually
                </span>
              </div>
              <div></div>
            </div>
          <Slide>
            <div className="tw-flex tw-items-center tw-justify-center tw-gap-8 tw-mt-12">
              {/*  */}
              <div
                className="tw-py-4 tw-px-4 tw-w-52 bg-white shadow tw-rounded-xl tw-relative tw-z-20 subscription"
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
              >
                <p className="tw-text-main tw-font-bold tw-text-md  plan">
                  Basic
                </p>
                <p className="tw-text-[#0F0BC7] tw-font-black tw-text-4xl plan">
                  $29{" "}
                  <span className="tw-text-base tw-text-dark tw-font-bold tw-capitalize">
                    /month
                  </span>
                </p>
                <p className="tw-text-gray tw-text-md tw-h-24 tw-mt-4 plan">
                  For small business
                </p>
                <div className="tw-absolute tw-bottom-2 tw-inset-x-0 tw-px-4">
                  <ul>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>1 User</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Cedit card</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Dashboard</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Reports</span>
                    </li>
                  </ul>
                  <div className="tw-flex tw-justify-center tw-my-8">
                    <button className="bttn tw-py-4 tw-px-10 tw-rounded-full tw-text-white tw-bg-gradient-to-r tw-from-[#2272FF] tw-to-[#0D03C3] tw-cursor-pointer">
                      get started
                    </button>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div
                className="tw-py-4 tw-px-4 tw-w-52 bg-white shadow tw-rounded-xl tw-relative tw-z-20 subscription"
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
              >
                <p className="tw-text-main tw-font-bold tw-text-md  plan">
                  Basic
                </p>
                <p className="tw-text-[#0F0BC7] tw-font-black tw-text-4xl plan">
                  $29{" "}
                  <span className="tw-text-base text-dark tw-font-bold tw-capitalize">
                    /month
                  </span>
                </p>
                <p className="tw-text-gray tw-text-md tw-h-24 tw-mt-4 plan">
                  For small business
                </p>
                <div className="tw-absolute tw-bottom-2 tw-inset-x-0 tw-px-4">
                  <ul>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>1 User</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Cedit card</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Dashboard</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Reports</span>
                    </li>
                  </ul>
                  <div className="tw-flex tw-justify-center tw-my-8">
                    <button className="bttn tw-py-4 tw-px-10 tw-rounded-full tw-text-white tw-bg-gradient-to-r tw-from-[#2272FF] tw-to-[#0D03C3] tw-cursor-pointer">
                      get started
                    </button>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <div
                className="tw-py-4 tw-px-4 tw-w-52 bg-white shadow tw-rounded-xl tw-relative tw-z-20 subscription"
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
              >
                <p className="tw-text-main tw-font-bold tw-text-md  plan">
                  Basic
                </p>
                <p className="tw-text-[#0F0BC7] tw-font-black tw-text-4xl plan">
                  $29{" "}
                  <span className="tw-text-base text-dark tw-font-bold tw-capitalize">
                    /month
                  </span>
                </p>
                <p className="tw-text-gray tw-text-md tw-h-24 tw-mt-4 plan">
                  For small business
                </p>
                <div className="tw-absolute tw-bottom-2 tw-inset-x-0 tw-px-4">
                  <ul>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>1 User</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Cedit card</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Dashboard</span>
                    </li>
                    <li className="tw-flex tw-gap-x-4 tw-items-center tw-text-md tw-mb-2 tw-text-gray plan">
                      <DoneIconComponent />
                      <span>Reports</span>
                    </li>
                  </ul>
                  <div className="tw-flex tw-justify-center tw-my-8">
                    <button className="bttn tw-py-4 tw-px-10 tw-rounded-full tw-text-white tw-bg-gradient-to-r tw-from-[#2272FF] tw-to-[#0D03C3] tw-cursor-pointer">
                      get started
                    </button>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </Slide>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Savings;
