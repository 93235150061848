import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

AOS.init();

library.add(
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faSquareInstagram
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
