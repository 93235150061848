import {
  GET_DASHBOARD,
  GET_DASHBOARD_FAIL,
  GET_DASHBOARD_LOADING,
  GET_USER,
  GET_USER_FAIL,
  GET_USER_LOADING,
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGIN_USER_LOADING,
  LOGOUT,
  LOGOUT_LOADING,
  SECOND_VERIFY,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  VERIFY_MAIL,
} from "./ActionTypes";
import { toast } from "react-toastify";
import { SetAuthToken } from "../Config";
import axios from "axios";
import { getOrganisationUser } from "./OrganisationAction";
import { getSavings } from "./SavingsAction";

// LOGOUT
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT_LOADING });
  try {
    await axios.post(`/api/logout`);
    dispatch({ type: LOGOUT });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

// GET USER INFO
export const loadUser = () => async (dispatch) => {
  if (localStorage.CEBIZ_ORG) {
    SetAuthToken(localStorage.CEBIZ_ORG);
  }
  dispatch({ type: GET_USER_LOADING });

  try {
    let res = await axios.get(`/api/organisations/organisation_info`);
    if (res?.data?.type === "ORGANISATION") {
      dispatch(getOrganisationUser());
      dispatch(getSavings());
      dispatch(getDashboardData());
    }
    if (res?.data?.type)
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
  } catch (err) {
    console.log({ err });
    if (err) console.log(err.response?.data);
    let error = err.response?.data;
    var value =
        error.error && typeof error.error === "string"
          ? error.error
          : error.message,
      value2 = "Second Authentication Verification failed";

    if (value === value2) {
      dispatch({ type: SECOND_VERIFY });
      toast.error(value);
    } else if (value === "Your email address is not verified.") {
      dispatch({ type: VERIFY_MAIL });
      toast.info("Proceed to verify your email");
    } else {
      dispatch({ type: GET_USER_FAIL });
    }
  }
};

// LOGIN ACTION
export const loginUser = (userData) => async (dispatch) => {
  dispatch({ type: LOGIN_USER_LOADING });
  try {
    var res;
    if (userData.user === "user")
      res = await axios.post(`/api/user/login`, { ...userData });
    else res = await axios.post(`/api/login`, { ...userData });

    dispatch({
      type: LOGIN_USER,
      payload: res.data,
    });
    dispatch(loadUser());
    toast.success(res.data.message, { autoClose: 5000 });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data;
    dispatch({ type: LOGIN_USER_FAIL });
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

// REGISTER ACTION
export const registerUser = (userData) => async (dispatch) => {
  dispatch({ type: LOGIN_USER_LOADING });

  try {
    let res = await axios.post(
      "/api/organisation-registration",
      {
        ...userData,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch({
      type: LOGIN_USER,
      payload: res.data,
    });
    // console.log({ res: res.data });
    dispatch(loadUser());
    toast.success(res.data.message, { autoClose: false });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data;
    dispatch({ type: LOGIN_USER_FAIL });
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const updateUser = (thisData) => async (dispatch) => {
  try {
    let res = await axios.post(
      `/api/organisations/organisation-update`,
      {
        ...thisData,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });

    toast.success(res.data.message, { autoClose: 5000 });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data;
    dispatch({ type: UPDATE_USER_FAIL });
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const getDashboardData = () => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_LOADING });

  try {
    let res = await axios.post(`/api/organisations/basicOrganisationDetails`);

    dispatch({
      type: GET_DASHBOARD,
      payload: res.data,
    });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data;
    dispatch({ type: GET_DASHBOARD_FAIL });
    if (error) {
      console.log({ error });
      let value =
        error.error && typeof error.error === "string"
          ? error.error
          : error.message;
      if (value === "request not successfull") {
      } else toast.error(value);
    }
  }
};
