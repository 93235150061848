import React, { useState, useEffect } from "react";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { BsFillBagCheckFill, BsWindows } from "react-icons/bs";
import { HiCurrencyDollar } from "react-icons/hi";
import { FaBars } from "react-icons/fa";

export default function Navbar() {
	let [isShadow, setIsShadow] = useState(false);
	let handleScroll = () => {
		window.onscroll = () => {
			if (window.scrollY > 200) setIsShadow(true);
			else setIsShadow(false);
		};
	};

	useEffect(() => {
		handleScroll();
	}, []);
	return (
		<nav
			className={`navbar navbar-expand-lg bg-white sticky-top ${
				isShadow ? "shadow" : ""
			}`}>
			<div className="container-fluid">
				<Link to="/">
					{" "}
					<img src={require("../assets/logo03.png")} alt="" />
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation">
					<span className="navbar-toggler-icon">
						<FaBars />
					</span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mx-auto">
						<li className="nav-item dropdown ">
							<Link
								style={{ color: "black" }}
								className="nav-link"
								to="#"
								id="navbarDropdown"
								//what makes it actually drops down
								data-bs-toggle="dropdown"
								role="button"
								aria-haspopup="true"
								aria-expanded="false">
								Company
							</Link>
							<div
								className="dropdown-menu p-2"
								aria-labelledby="navbarDropdown">
								<Link to="/about" className="dropdown-item">
									<div className="align">
										<FaUsers
											size={40}
											className="mt-1 p-2 rounded"
											style={{ color: "#f87474", backgroundColor: "#fdeff1" }}
										/>
										<div className="ms-3 mt-3">
											<p className="ftp1">About us</p>
											<p className="ftp2">What we offer</p>
										</div>
									</div>
								</Link>
								<Link to="/login" className="dropdown-item">
									<div className="align">
										<BsFillBagCheckFill
											size={40}
											className="mt-1 p-2 rounded"
											style={{ color: "#2eb66e", backgroundColor: "#e8f6f0" }}
										/>
										<div className="ms-3 mt-3">
											<p className="ftp1">Careers</p>
											<p className="ftp2">Join our team</p>
										</div>
									</div>
								</Link>
							</div>
						</li>
						<li className="nav-item dropdown ">
							<Link
								style={{ color: "black" }}
								className="nav-link"
								to="#"
								id="navbarDropdown"
								//what makes it actually drops down
								data-bs-toggle="dropdown"
								role="button"
								aria-haspopup="true"
								aria-expanded="false">
								Features
							</Link>
							<div
								className="dropdown-menu p-2"
								aria-labelledby="navbarDropdown">
								<Link to="/orgsavings" className="dropdown-item">
									<div className="align">
										<HiCurrencyDollar
											size={40}
											className="mt-1 p-2 rounded"
											style={{ color: "#FAAE3B", backgroundColor: "#faefe0" }}
										/>
										<div className="ms-3 mt-3">
											<p className="ftp1">Organization Savings</p>
											<p className="ftp2">Save with an organization</p>
										</div>
									</div>
								</Link>
								<Link to="/downloadapp" className="dropdown-item">
									<div className="align">
										<BsWindows
											size={40}
											className="mt-1 p-2 rounded"
											style={{ color: "#031A6E", backgroundColor: "#d0d6e9" }}
										/>
										<div className="mt-3 ms-5">
											<p className="ftp1">Mobile App</p>
											<p className="ftp2">Save, loan & Fund wallet</p>
										</div>
									</div>
								</Link>
							</div>
						</li>
						<li className="nav-item dropdown">
							<Link
								className="nav-link"
								style={{ color: "black" }}
								to="/faqs"
								id="navbarDropdown"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								FAQs
							</Link>
						</li>
					</ul>
					<div className="my-2 my-lg-0">
						<Link
							to="/login"
							style={{ textDecoration: "none", padding: "5px 20px" }}
							className="me-4 btn btn-light">
							Sign in
						</Link>
						<Link
							to="/downloadapp"
							className="btn"
							style={{ backgroundColor: "#0C0D5B", color: "white" }}>
							Get mobile app
						</Link>
					</div>
				</div>
			</div>
		</nav>
	);
}
