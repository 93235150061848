import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { FaFilter, FaDownload, FaPlus } from "react-icons/fa";
import { BiCopy, BiDotsVertical } from "react-icons/bi";
import moment from "moment";
import { GlobalState } from "../Data/Context";
import { Link } from "react-router-dom";
import Empty from "./Empty";

const Transactions = ({ data }) => {
	const { transactions, nairaSign, numberWithCommas } = useContext(GlobalState);

	let [stateData, setStateData] = useState(null);

	useEffect(() => {
		if (data) setStateData(data);
		else setStateData(transactions);
	}, [data, transactions]);

	if (!stateData) return <></>;

	return (
		<section className="pt-5">
			<div className="container">
				<div className="d-flex">
					<p className="text-uppercase mb-0 border-bottom border-3 border-dark">wallet history</p>
				</div>
				<hr className="m-0" />
			</div>
			{stateData.length === 0 ? (
				<Empty />
			) : (
				<>
					<CustomSearchBar dot />
					<section className="container">
						<table className="">
							<thead className="text-uppercase text-muted fw-100">
								<tr className="trasactionGrid">
									<th className="barFont text-muted fw-500 fontReduce">
										amount
									</th>
									<th className="barFont text-muted fw-500 fontReduce">
										Transaction id
									</th>
									<th className="barFont text-muted fw-500 fontReduce d-none d-lg-block">
										payment method
									</th>
									<th className="barFont text-muted fw-500 fontReduce d-none d-lg-block">
										Date/time
									</th>
									<th className="barFont text-muted fw-500 fontReduce">
										STATUS
									</th>
								</tr>
							</thead>
							<tbody>
								{stateData.map((item, index) => (
									<tr key={index} className="barFont trasactionGrid">
										<td className="barFont fontReduce">
											<span>
												{nairaSign}
												{numberWithCommas(Number(item?.amount).toFixed(2))}
											</span>
										</td>
										<td className="barFont fontReduce">
											<Link
												to={`/receipts/${item?.id}`}
												className="text-dark text-decoration-none">
												{item?.transaction_reference?.substring(0, 25)}
											</Link>
											{item?.transaction_reference && (
												<span className="d-none d-md-inline">
													<BiCopy
														className="myCursor textColor"
														size={20}
														onClick={() => {
															navigator.clipboard
																.writeText(item?.transaction_reference)
																.then(
																	() => {
																		toast.success("Copied");
																	},
																	err => {
																		toast.warm(`Could not copy:4 ${err}`);
																	}
																);
														}}
													/>{" "}
												</span>
											)}
										</td>
										<td className="barFont fontReduce d-none d-lg-block text-center">
											{item?.description}
										</td>
										<td className="barFont fontReduce d-none d-lg-block">
											{moment(item?.created_at).fromNow()}
										</td>
										<td className="barFont fontReduce">
											<button
												type="button"
												className={`btn barFont text-uppercase fontReduce ${
													item?.status === "recieved"
														? "btn-main"
														: item?.status === "send"
														? "btn-light-red"
														: "btn-deep-red"
												}`}>
												{item?.status}
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</section>
				</>
			)}
		</section>
	);
};

export default Transactions;

export const CustomSearchBar = ({ dot = false, addNew = false, addFunc }) => {
	return (
		<div className="tw-bg-[#D8E0FD] px-3 py-2 my-3 rounded d-flex justify-content-between align-items-center">
			<div className="px-lg-5 searchDiv">
				<input
					type="search"
					name="search"
					id=""
					placeholder="Search"
					className="form-control"
				/>
			</div>
			<div className="d-none d-lg-flex justify-content-center align-items-center">
				{addNew ? (
					<>
						<button className="btn text-capitalize mx-3 fontReduce px-md-4 m-0 border py-2 barFont text-muted">
							<FaDownload className="dashboardP" /> export
						</button>
						<button
							onClick={addFunc}
							className="btn btn-primary1 text-capitalize mx-3 fontReduce px-md-4 m-0 border py-2 barFont text-muted">
							<FaPlus className="dashboardP text-white" /> add new
						</button>
					</>
				) : (
					<>
						<button className="btn text-capitalize px-md-4 m-0 border py-2 barFont text-muted">
							<FaFilter className="dashboardP fontReduce" /> filter
						</button>
						<button className="btn text-capitalize mx-3 fontReduce px-md-4 m-0 border py-2 barFont text-muted">
							<FaDownload className="dashboardP" /> export
						</button>
						{dot && (
							<button className="btn text-capitalize mx-3 fontReduce m-0 border py-2">
								<BiDotsVertical />
							</button>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export const SavingsTransaction = ({ data }) => {
	const { nairaSign, numberWithCommas } = useContext(GlobalState);
	let [stateData, setStateData] = useState(null);

	useEffect(() => {
		if (data) setStateData(data);
	}, [data]);

	if (!stateData) return <></>;

	return (
		<section className="pt-5">
			<div className="container">
				<div className="d-flex">
					<p className="text-uppercase mb-0 border-bottom border-3">all</p>
				</div>
				<hr className="m-0" />
			</div>
			{stateData.length === 0 ? (
				<Empty />
			) : (
				<>
					<CustomSearchBar />
					<section className="container">
						<table className="">
							<thead className="text-uppercase">
								<tr className="">
									<th className="barFont fw-500 fontReduce">savings name</th>
									<th className="barFont  fw-500 fontReduce text-center">
										amount
									</th>
									<th className="barFont fw-500 fontReduce text-center">
										Date/time
									</th>
									<th className="barFont fw-500 fontReduce text-right">
										total
									</th>
								</tr>
							</thead>
							<tbody>
								{stateData.map((item, index) => (
									<tr key={index} className="barFont">
										<td className="barFont fontReduce text-capitalize text-muted">
											{item.savings_name}
										</td>
										<td className="barFont fontReduce text-center text-muted">
											<span>
												{nairaSign}
												{numberWithCommas(Number(item.amount).toFixed(2))}
											</span>
										</td>

										<td className="barFont fontReduce text-center text-muted">
											{moment(item.created_at).format("L - hh:mma")}
										</td>
										<td className="barFont fontReduce text-right text-muted">
											<span>
												{nairaSign}
												{numberWithCommas(
													Number(item.accumulated_amount).toFixed(2)
												)}
											</span>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</section>
				</>
			)}
		</section>
	);
};
