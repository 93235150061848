import { Slide } from "react-reveal";

const Partners = ({ image, text }) => {
  return (
    <Slide bottom>
      <div className="tw-min-w-fit">
        <img
          src={require(`../../assets/${image}`)}
          alt=""
          className="tw-mb-4 tw-mx-auto"
        />
        <p className="tw-w-72 tw-text-xs tw-text-center tw-mx-auto">"{text}"</p>
      </div>
    </Slide>
  );
};

export default Partners;
