import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { BiArrowBack } from "react-icons/bi";
import success from "../assets/success.png";
import { Buttons } from "../Utils";
import NumberFormat from "react-number-format";
import moment from "moment";
import Button from "@atlaskit/button";
import Select from "react-select";
import { defaultTheme } from "react-select";
const { colors } = defaultTheme;

const selectStyles = {
  control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

const ModalComponents = ({ isOpen, toggle, title, children, back }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} centered scrollable>
      <ModalHeader
        toggle={toggle}
        className="borderNone text-capitalize genSansFont"
      >
        {back && <BiArrowBack className="me-3 myCursor" onClick={back} />}
        {title}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default ModalComponents;

export const ModalSuccess = ({ isOpen, toggle, toggle2, children, msg }) => {
  return (
    <ModalComponents title={""} isOpen={isOpen}>
      <div className="d-flex justify-content-center align-items-center flex-column py-4">
        <div
          className="rv-bubbles rounded-circle d-flex justify-content-center align-items-center"
          style={{ height: "10rem", width: "10rem" }}
        >
          <div
            className="rv-bubbles rounded-circle d-flex justify-content-center align-items-center"
            style={{ height: "8rem", width: "8rem" }}
          >
            <div
              className="rv-bubbles border-none btn-primary1 rounded-circle"
              style={{ height: "6rem", width: "6rem" }}
            >
              <img src={success} alt="Success" />
            </div>
          </div>
        </div>
        {children ? (
          children
        ) : (
          <>
            <p className="mt-3">Congratulations</p>
            <button
              className="btn btn-primary1"
              onClick={() => {
                toggle();
                if (toggle2) {
                  toggle2();
                }
              }}
            >
              {msg ? msg : "Go back to dashboard"}
            </button>
          </>
        )}
      </div>
    </ModalComponents>
  );
};

export const ConfirmDeleteModal = ({ isOpen, toggle, loading, deleteItem, editItem }) => {
  return (
    <ModalComponents isOpen={isOpen} back={toggle} title="Action">
      <div className="d-flex flex-column text-center">
        <p className="text-dark">
          Do you want to edit?
          {/* {stateEdit.value && stateEdit.data.savings_name}? */}
        </p>
        <div className="btn-group mx-auto">
          <button
            onClick={editItem}
            className="btn btn-success text-uppercase"
          >
            yes
          </button>
          <button
            onClick={toggle}
            className="btn btn-success btn-success2 text-uppercase"
          >
            no
          </button>
        </div>
        <hr />
        <p className="text-dark text-uppercase">OR</p>
        <hr />
        <p className="text-dark">
          Do you want to delete
          {/* {stateEdit.value && stateEdit.data.savings_name}? */}
        </p>
        <div className="btn-group mx-auto">
          <Buttons
            title={"yes"}
            onClick={deleteItem}
            loading={loading}
            css="btn-danger text-uppercase"
          />
          <button onClick={toggle} className="btn btn-primary text-uppercase">
            no
          </button>
        </div>
      </div>
    </ModalComponents>
  );
};

export const ModalInviteUser = ({ isOpen, handleSubmit, back }) => {
  return (
    <ModalComponents back={back} title={"invite user"} isOpen={isOpen}>
      <form className="p-3" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="Email" className="textColor">
            Email
          </label>
          <input
            type="email"
            name="Email"
            id="Email"
            className="form-control borderColor passwordInput"
            placeholder="ifeoluwabk4ever@gmail.com"
          />
        </div>
        <div className="mb-5">
          <label htmlFor="Choose role" className="textColor">
            Choose role
          </label>
          <select
            name="Choose role"
            id="Choose role"
            className="form-control form-select borderColor"
            placeholder="Choose role"
          >
            <option value="">Choose role...</option>
          </select>
        </div>

        <button
          className="btn btn-primary1 d-block mx-auto px-5 text-capitalize w-75"
          onClick={handleSubmit}
        >
          confirm
        </button>
      </form>
    </ModalComponents>
  );
};

export let ModalSendMoney = ({ beneficiary, toggle, isOpen, handleSubmit }) => {
  return (
    <ModalComponents title={"send money"} toggle={toggle} isOpen={isOpen}>
      <form className="p-3" onSubmit={handleSubmit}>
        <div>
          <p className="genSansFont">Beneficiaries</p>
          <div className="tw-flex tw-items-top tw-gap-8 px-4 py-2 text-center tw-mb-8 tw-overflow-x-scroll">
            {beneficiary.slice(0, 4).map((item, index) => (
              <div key={index} className="">
                <img src={item.picture} alt={item.name} className="tw-w-20 tw-h-20 tw-mb-4" />
                <small className="genSansFont textColor">{item.name}</small>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="Name" className="textColor">
            Name
          </label>
          <select
            name="Name"
            id="Name"
            className="form-control form-select formFullWidth"
            placeholder="Select Bank"
          >
            <option value="">Select Bank...</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="Account Number" className="textColor">
            Account Number
          </label>
          <input
            type="number"
            name="Account Number"
            id="Account Number"
            className="form-control formFullWidth"
            placeholder="e.g. 1234567890"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Amount" className="textColor">
            Amount
          </label>
          <input
            type="number"
            name="Amount"
            id="Amount"
            className="form-control formFullWidth"
            placeholder="minimum of 50"
            min={50}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Naration" className="textColor">
            Naration
          </label>
          <textarea
            name="Naration"
            id="Naration"
            className="form-control formFullWidth"
            placeholder="Naration"
            style={{
              height: "5rem",
              overflowY: "auto",
              resize: "none",
            }}
          />
        </div>
        <button
          className="btn btn-primary1 d-block mx-auto px-5 text-capitalize w-75"
          onClick={handleSubmit}
        >
          Continue
        </button>
      </form>
    </ModalComponents>
  );
};

export let ModalCreateSavings = ({
  isOpen,
  handleSubmit,
  back,
  stateEdit,
  stateData,
  textChange,
  loading,
}) => {
  return (
    <ModalComponents
      title={`${
        stateEdit.value ? `update ${stateEdit?.data?.savings_name}` : "create"
      } savings`}
      back={back}
      isOpen={isOpen}
    >
      <form className="p-3" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="Savings Narration" className="textColor">
            Savings Narration
          </label>
          <textarea
            name="Savings Narration"
            id="Savings Narration"
            className="form-control w-100 formFullWidth"
            placeholder="Spend the money well"
            style={{
              height: "5rem",
              overflowY: "auto",
              resize: "none",
            }}
            value={stateData.savings_description}
            onChange={textChange("savings_description")}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Savings Name" className="textColor">
            Savings Name
          </label>
          <input
            type="text"
            name="Savings Name"
            id="Savings Name"
            className="form-control w-100 formFullWidth"
            placeholder="e.g. Car thrift"
            value={stateData.savings_name}
            onChange={textChange("savings_name")}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Amount" className="textColor">
            Amount
          </label>
          <NumberFormat
            className="form-control text-5 w-100 formFullWidth"
            value={stateData.amount}
            displayType="input"
            prefix="₦ "
            placeholder="500,000,000"
            onValueChange={textChange("amount")}
            thousandSeparator={true}
            inputMode="numeric"
            renderText={(value, props) => <span {...props}>{value}</span>}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Saving Method" className="textColor">
            Saving Method
          </label>
          <select
            name="Saving Method"
            id="Saving Method"
            className="form-control form-select text-capitalize w-100 formFullWidth"
            value={stateData.savings_method}
            onChange={textChange("savings_method")}
            placeholder="Saving Method"
          >
            <option value="">Saving Method...</option>
            <option value="daily">Daily</option>
            <option value="weekly">weekly</option>
            <option value="monthly">montly</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="Start Date" className="textColor">
            Start Date
          </label>
          <input
            type="date"
            name="Start Date"
            id="Start Date"
            className="form-control w-100 formFullWidth"
            value={stateData.start_date}
            onChange={textChange("start_date")}
            min={moment().format("YYYY-MM-DD")}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Duration" className="textColor">
            Duration (in Month)
          </label>
          <input
            type="number"
            name="Duration"
            id="Duration"
            className="form-control w-100 formFullWidth"
            placeholder="Duration"
            value={stateData.duration}
            onChange={textChange("duration")}
            min={1}
          />
        </div>
        <Buttons
          title={stateEdit.value ? "update" : "confirm"}
          css={"btn btn-primary1 d-block mx-auto px-5 text-capitalize w-75"}
          width="w-75"
          onClick={handleSubmit}
          loading={loading}
        />
      </form>
    </ModalComponents>
  );
};

export let ModalConfirm = ({ toggle, isOpen, handleSubmit, back }) => {
  return (
    <ModalComponents
      title={"confirm"}
      toggle={toggle}
      isOpen={isOpen}
      back={back}
    >
      <form onSubmit={handleSubmit} className="p-3">
        <p className="text-center text-dark barFont">To</p>
        <p className="text-center barFont">Adaralegbe Ifeoluwa</p>
        <div className="bg-light rounded p-2 mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-dark barFont">From</p>
            <p className="text-dark barFont">1234567890</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-dark barFont mb-0">Transaction fee:</p>
            <p className="barFont mb-0">+5.00</p>
          </div>
        </div>
        <p className="text-dark barFont">Amount</p>
        <p className="text-dark barFont">Description</p>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="text-dark barFont">Beneficiary Bank</p>
        </div>

        <button
          className="btn btn-primary1 d-block mx-auto px-5 text-capitalize w-75"
          onClick={handleSubmit}
        >
          Confirm
        </button>
      </form>
    </ModalComponents>
  );
};

export let ModalOtpPin = ({ toggle, isOpen, handleSubmit, back }) => {
  return (
    <ModalComponents
      title={"otp pin"}
      toggle={toggle}
      isOpen={isOpen}
      back={back}
    >
      <form onSubmit={handleSubmit} className="p-3">
        <p className="text-center text-dark barFont">
          Enter your Transfer Pin to complete your action
        </p>
        <section className="otpPin">
          <div className="border rounded p-4"></div>
          <div className="border rounded p-4"></div>
          <div className="border rounded p-4"></div>
          <div className="border rounded p-4"></div>
          <div className="border rounded p-4"></div>
          <div className="border rounded p-4"></div>
        </section>

        <button
          className="btn btn-primary1 d-block mx-auto px-5 text-capitalize w-75"
          onClick={handleSubmit}
        >
          Done
        </button>
      </form>
    </ModalComponents>
  );
};

export let ModalNotification = ({
  isOpen,
  type,
  handleSubmit,
  back,
  loading,
  stateData,
  textChange,
}) => {
  return (
    <ModalComponents title={`${type} notification`} back={back} isOpen={isOpen}>
      <form className="p-3" onSubmit={handleSubmit}>
        {!type && (
          <div className="mb-3">
            <label htmlFor="Type" className="textColor">
              Type
            </label>
            <select
              name="Type"
              id="Type"
              value={stateData?.notification_type}
              onChange={textChange("notification_type")}
              className="form-control borderColor form-select"
              placeholder="Type"
            >
              <option value="">Select notification type</option>
              <option value="EMAIL">Email</option>
              <option value="SMS">SMS</option>
              <option value="PUSH">Notification</option>
            </select>
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="Title" className="textColor">
            Title
          </label>
          <input
            type="text"
            name="Title"
            id="Title"
            value={stateData?.title}
            onChange={textChange("title")}
            className="form-control borderColor"
            placeholder="Title"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Message" className="textColor">
            Message
          </label>
          <textarea
            name="Savings Narration"
            id="Message"
            className="form-control borderColor"
            placeholder="Message"
            value={stateData?.message}
            onChange={textChange("message")}
            style={{
              height: "15rem",
              overflowY: "auto",
              resize: "none",
            }}
          />
        </div>
        <Buttons
          loading={loading}
          onClick={handleSubmit}
          css="btn-primary1 d-block mx-auto px-5 text-capitalize"
          width={"w-75"}
          title="send"
        />
      </form>
    </ModalComponents>
  );
};

export let ModalPasswordRest = ({ isOpen, handleSubmit, back }) => {
  return (
    <ModalComponents title={"password reset"} back={back} isOpen={isOpen}>
      <form className="p-3" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="Old Password" className="textColor">
            Old Password
          </label>
          <input
            type="password"
            name="Old Password"
            id="Old Password"
            className="form-control borderColor passwordInput"
            placeholder="Old Password"
          />
        </div>
        <div className="mb-5">
          <label htmlFor="New Password" className="textColor">
            New Password
          </label>
          <input
            type="password"
            name="New Password"
            id="New Password"
            className="form-control borderColor passwordInput"
            placeholder="New Password"
          />
        </div>

        <button
          className="btn btn-primary1 d-block mx-auto px-5 text-capitalize w-75"
          onClick={handleSubmit}
        >
          confirm
        </button>
      </form>
    </ModalComponents>
  );
};

export let ModalNewPaygroup = ({
  isOpen,
  handleSubmit,
  handleInput,
  inputName,
  back,
  title,
  modeType,
  value,
  loading
}) => {
  return (
    <ModalComponents title={`${modeType} Payment ${title}`} back={back} isOpen={isOpen}>
      <form className="p-3" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="Name" className="textColor">
            Name
          </label>
          <input
            type="text"
            name={inputName}
            id="Name"
            className="form-control borderColor"
            placeholder="Name"
            value={value}
            onChange={handleInput}
          />
        </div>
        <Buttons
          title={modeType === "Add New" ?"create" : "Edit"}
          css={"btn btn-primary1 d-block mx-auto mt-4 px-5 text-capitalize w-75"}
          width="w-75"
          onClick={handleSubmit}
          loading={loading}
        />
      </form>
    </ModalComponents>
  );
};

export const ModalAddWorker = ({
  handleSubmit,
  isOpen,
  back,
  users,
  value,
  onSelectChange,
  dropDownOpen,
  toggleOpen,
  loading
}) => {
  return (
    <ModalComponents title={"Add Worker"} back={back} isOpen={isOpen}>
      <form className="p-3" onSubmit={handleSubmit}>
        <div>
          {/* <label>Users</label>
          <select
            className="form-select mb-4"
            aria-label="Default select example"
          >
            <option value="">Select User</option>
            <option>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                />
                <label for="floatingInput">Email address</label>
              </div>
            </option>
            {users?.map((user) => (
              <option key={user.id} value={user.id}>
                {type == "worker" ? user.user.full_name : user.email}
              </option>
            ))}
          </select> */}
          <Dropdown
            dropDownOpen={dropDownOpen}
            onClose={toggleOpen}
            target={
              <Button
                iconAfter={<ChevronDown />}
                onClick={toggleOpen}
                isSelected={dropDownOpen}
                className="w-100"
              >
                {value ? `${value.label}` : "Select Worker"}
              </Button>
            }
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={onSelectChange}
              options={users}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={users}
            />
          </Dropdown>
        </div>
        <Buttons
          title="Add worker"
          css={"btn btn-primary1 d-block mx-auto mt-4 px-5 text-capitalize w-75"}
          width="w-75"
          onClick={handleSubmit}
          loading={loading}
        />
      </form>
    </ModalComponents>
  );
};

// styled components

const Menu = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      css={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, dropDownOpen, target, onClose }) => (
  <div css={{ position: "relative" }}>
    {target}
    {dropDownOpen ? <Menu>{children}</Menu> : null}
    {dropDownOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);
