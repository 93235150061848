import React, { useEffect, useState } from "react";
import { initialState, month } from "../../Pages/dashboard";
import { getUniqueColor } from "../../Pages/savings";
import NewChartFormat from "../Charts";
import { CapitalizeFirst } from "../Sidebar";

const Reports = () => {
	let [options, setOptions] = useState(initialState),
		[label, setLabel] = useState([]),
		[color, setColor] = useState([]),
		data = [10, 20, 30, 20, 10];

	useEffect(() => {
		let data = [],
			data2 = [];
		for (let i = 0; i < month.length; i++) {
			data.push(CapitalizeFirst(month[i]));
			data2.push(`#${getUniqueColor()}`);
		}
		setOptions({ ...options, xaxis: { categories: data } });
		setLabel(data);
		setColor(data2);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="row g-4">
			<NewChartFormat
				options={options}
				series={[{ name: "Total Transactions", data }]}
				type="line"
				info={{
					name: "Number Of worker",
					amount: 29,
					money: false,
					user: "work",
				}}
			/>
			<NewChartFormat
				options={{
					...options,
					labels: label.slice(0, data.length),
					colors: color,
					dataLabels: {
						enabled: false,
					},
					legend: {
						position: "bottom",
					},
					stroke: {
						show: false,
					},
				}}
				series={data}
				type="pie"
				info={{
					name: "Number Of worker",
					amount: 29,
					money: false,
					user: "work",
				}}
			/>
		</div>
	);
};

export default Reports;
