import Footer from "../../components/footer/footer.component";

import boxBg from "../../assets/box-bg.png";
import TeamCard from "../../components/team-card/team-card.component";
import vector from "../../assets/vector.png";
import Feature from "../../components/feature/feature.component";
import { Fade, Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate()

  const goToCreateAccount = () => navigate('/create-account')
  return (
    <div>
      <header className="tw-relative tw-bg-mainShade tw-pt-40 tw-pb-20">
        <div className="tw-absolute tw-inset-y-0 tw-w-1/4 tw-bg-dark tw-right-0 z-10 tw-hidden md:tw-block"></div>
        <div className="tw-container tw-mx-auto tw-px-8 md:tw-px-0">
          <div className="tw-grid md:tw-grid-cols-2 tw-gap-8">
            <div className="">
              <Slide bottom>
                <p className="tw-uppercase tw-text-2xl tw-font-bold tw-text-white">
                  about us
                </p>
              </Slide>
              <Slide bottom>
                <p className="tw-text-4xl md:tw-text-5xl tw-font-medium tw-mt-8 tw-text-white">
                  Funds management, <br /> made easy <br />
                  <span>cebiz</span>
                </p>
              </Slide>
              <Slide bottom>
                <p className="tw-mt-4 tw-text-md tw-max-w-lg tw-text-white">
                  Expertly navigating the world of finance to help you achieve
                  your Savings goals.
                </p>
              </Slide>
              <div className="tw-mt-8">
                <button className="tw-bg-white tw-text-black tw-h-12 tw-px-12 tw-uppercase tw-rounded-xl" onClick={goToCreateAccount}>
                  get started
                </button>
              </div>
            </div>
            <Fade right>
              <div className="tw-relative">
                <img
                  src={require("../../assets/about-hero.png")}
                  alt=""
                  className="md:tw-absolute w-auto"
                />
              </div>
            </Fade>
          </div>
        </div>
      </header>
      <div style={{ backgroundImage: `url(${boxBg})` }}>
        <section className="tw-grid md:tw-grid-cols-2">
          <div className="tw-p-8 tw-self-center">
            <Slide bottom>
              <p className="tw-text-4xl lg:tw-text-5xl tw-font-medium tw-leading-tight">
                The perfect <span className="tw-text-secondary">solution</span>
                <br /> didn't exist so we <br /> created{" "}
                <span className="tw-text-secondary">one</span>.
              </p>
            </Slide>
            <Slide bottom>
              <p className="tw-mt-4 tw-max-w-md">
                We came from the frustration of the stress of managing the
                finance of the organisation and saving some percentage of our
                income, now we’re here to provide solutions.
              </p>
            </Slide>
          </div>
          <div>
            <img
              src={require("../../assets/round-table.png")}
              className="tw-ml-auto tw-h-96 tw-object-cover"
              alt=""
            />
          </div>
        </section>
        <section
          className="tw-bg-mainShade tw-py-20"
          style={{ backgroundColor: `url(${boxBg})` }}
        >
          <div className="tw-container tw-mx-auto tw-px-8 tw-md:px-0">
            <div className="tw-grid md:tw-grid-cols-2 tw-gap-8">
              <div className="tw-self-center">
                <Slide bottom>
                  <img
                    src={require("../../assets/analytics.png")}
                    alt=""
                    className="md:tw-h-96 tw-w-auto"
                  />
                </Slide>
              </div>
              <div>
                <Slide bottom>
                  <p className="tw-text-white tw-font-bold tw-text-4xl tw-mb-4">
                    <span className="tw-text-secondary">Benefits</span> of
                    working with <span className="tw-text-secondary">us</span>
                  </p>
                </Slide>
                <Slide bottom>
                  <p className="tw-text-white tw-mb-8 tw-max-w-sm">
                    Experience the Difference: Discover the Benefits of Working
                    with Us and Achieve Your financial Goals with Confidence!
                  </p>
                </Slide>
                {/* <div>
                  <Slide bottom>
                    <img
                      src={require("../../assets/analytics-option.png")}
                      alt=""
                    />
                  </Slide>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* <section className="tw-py-20">
        <Slide bottom>
          <p className="tw-text-center tw-text-4xl tw-font-medium tw-capitalize tw-mb-8">
            meet out <span className="tw-text-secondary">team</span>
          </p>
        </Slide>
        <Slide bottom>
          <p className="tw-max-w-md tw-text-sm tw-text-center tw-mx-auto">
            Lorem ipsum dolor sit amet consectetur. Sit dui vulputate vivamus
            in. Tellus elementum lectus facilisi ultricies nunc.
          </p>
        </Slide>
          <div className="tw-container tw-mx-auto tw-px-8 tw-md:px-0 tw-mt-8">
            <div className="tw-flex tw-justify-between tw-flex-wrap tw-gap-8">
              <TeamCard />
              <TeamCard />
              <TeamCard />
            </div>
          </div>
        </section> */}
        <section>
        <div className="tw-container tw-px-8 md:tw-px-0 tw-mx-auto">
          <div className="tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-16 tw-my-20">
            <div>
              <Slide bottom>
                <p className="tw-text-3xl md:tw-text-5xl tw-text-dark tw-leading-tight tw-font-medium">
                  All Features{" "}
                  <span>
                    You Need In One <img src={vector} alt="" />
                  </span>{" "}
                  Place
                </p>
              </Slide>
            </div>
            <div className="md:tw-col-span-2 md:tw-px-8">
              <Slide bottom>
                <p className="tw-text-sm md:tw-font-medium text-black tw-leading-loose">
                  A finTech app called CEBIZ assists private and public
                  institutions, corporate societies, businesses, and individuals
                  for financial management such as , payroll, inventory ,
                  management of customers/users savings , Human resource
                  management. A Mobile app platform will be configured to your
                  organisation for receiving salary/wages and savings
                </p>
              </Slide>
            </div>
            <Feature
              type="blue"
              title="Chargeless Transactions"
              text="We provide chargeless transactions, which are all of our transactions without any fees or charges for savings, wallet-to-wallet transfers, bill payment, etc."
            />
            <Feature
              title="Data subscription"
              text="CEBIZ 	Mobile apps make it easy for users to subscribe to mobile data plans of any kind, purchases of airtime of any network,and manage their data usage."
            />
            <Feature
              title="Cables subscription"
              text="CEBIZ 	Mobile apps make it easy for users to subscribe to cables of any kind, that is TV subscription of any kind."
            />
            <Feature
              title="Saving management"
              text="CEBIZ 	Mobile apps make it easy for business organisations to manage the savings of their employees and also to keep track of all the members of the organisation."
            />
            <Feature
              title="Payroll management"
              text="CEBIZ mobile app help business organisations and cooperative society in payroll management"
            />
            <Feature
              title="Staff management"
              text="CEBIZ mobile app helps business managers with the process of overseeing and coordinating the activities of employees within the organisation to ensure that they are working effectively and efficiently towards the goals of the business."
              type="green"
            />
            <Feature
              title="E-pin for Examination"
              text="CEBIZ mobile app helps users with e-pin for exams such as NECO, WAEC, UTME, etc."
            />
            <Feature
              title="Report generation"
              text="CEBIZ mobile app makes it easy for business organisations and cooperative societies to generate reports of their activities, either daily, monthly, biannual, annual, etc."
              type="yellow"
            />
            <Feature
              title="Inventory management"
              text="CEBIZ mobile app helps business organisations and cooperative societies to manage the flow of goods and materials into and out of the organisation, managing the stock levels of products or raw materials to ensure that there is always enough on hand to meet customer demand, while minimising the costs of carrying excess inventory."
            />
          </div>
        </div>
      </section>
        <section>
          <Footer />
        </section>
      </div>
    </div>
  );
};

export default About;
