import DoneIcon from "@mui/icons-material/Done";


const DoneIconComponent = () => {
  return (
    <span className="tw-relative tw-text-white tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center tw-bg-[#0F0BC7] tw-rounded-full plan">
      <div className="tw-bg-main tw-rounded-full tw-absolute tw-inset-0 -tw-z-1 tw-opacity-10 planT"></div>
      <DoneIcon />
    </span>
  );
};

export default DoneIconComponent;