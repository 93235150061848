import AppButton from "../button/button.component";
import { Slide } from "react-reveal";
import { useState } from "react";

const CARD_TYPE = {
  blue: "tw-bg-[#082267]",
  green: "tw-bg-[#2B9485]",
  yellow: "tw-bg-[#F2994A]",
};

const Feature = ({ type, text, title }) => {
  const [showLess, setShowless] = useState(true)
  return (
    <Slide bottom>
      <div className="tw-relative">
        <div
          className={`tw-absolute tw-inset-0 ${CARD_TYPE[type]} tw-rounded-xl`}
        ></div>
        <div
          className={`${
            type && "-tw-rotate-[5deg]"
          } tw-p-8 tw-bg-[#F8F8F8] tw-rounded-xl tw-relative tw-overflow-hidden max-w-md tw-mx-auto`}
        >
          <p className="tw-text-2xl tw-text-main tw-mb-8">
            {title}
          </p>
          <p className="tw-text-xs tw-font-normal tw-text-dark tw-mb-8 sm:tw-w-4/5">
            {showLess ? text.slice(0,100) : text} <br /> {text.length >= 112 && <span className="tw-text-main tw-underline tw-font-bold tw-cursor-pointer" onClick={() => setShowless(!showLess)}>{showLess ? "See More" : "show Less"}</span>}
          </p>
          {/* <AppButton children="open account" buttonType="primary" /> */}
          <img
            src={require(`../../assets/${
              type ? "feature-type-1.png" : "feature-type-2.png"
            }`)}
            alt=""
            className="tw-absolute tw-right-0 -tw-bottom-7 tw-h-24 tw-w-32"
          />
        </div>
      </div>
    </Slide>
  );
};

export default Feature;
