import {
  ADD_ORGANISATION,
  ADD_ORGANISATION_FAIL,
  DELETE_ORGANISATION,
  DELETE_ORGANISATION_FAIL,
  GET_ORGANISATION,
  GET_ORGANISATION_FAIL,
  GET_ORGANISATION_LOADING,
  GET_USER_LOADING,
  GET_USER_ORG,
  GET_USER_ORG_FAIL,
  LOGOUT,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_FAIL,
  GET_ORGANISATION_INFO,
  GET_ORGANISATION_INFO_LOADING,
  GET_ORGANISATION_INFO_FAIL,
} from "../Actions/ActionTypes";
import { DeleteData } from "./SavingReducer";

const initialState = {
  allUsers: [],
  isAdded: false,
  isDeleted: false,
  isUpdated: false,
  singleUser: null,
  organisationInfo: null,
  loading: false,
  isLoading: false,
};
const SavingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORGANISATION:
      return {
        ...state,
        allUsers: payload,
        loading: false,
      };
    case GET_ORGANISATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ORGANISATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_ORGANISATION_INFO:
      return {
        ...state,
        organisationInfo: payload,
        isLoading: false,
      };
    case GET_ORGANISATION_INFO_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORGANISATION_INFO_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_USER_ORG:
      return {
        ...state,
        singleUser: payload,
        isLoading: false,
      };
    case GET_USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_ORG_FAIL:
      return {
        ...state,
        loading: false,
        singleUser: null,
      };
    case ADD_ORGANISATION:
      return {
        ...state,
        isAdded: true,
      };
    case ADD_ORGANISATION_FAIL:
      return {
        ...state,
        isAdded: false,
        allUsers: [{ ...payload }, ...state.allUsers],
      };
    case UPDATE_ORGANISATION:
      return {
        ...state,
        isUpdated: true,
      };
    case UPDATE_ORGANISATION_FAIL:
      return {
        ...state,
        isUpdated: false,
      };
    case DELETE_ORGANISATION:
      return {
        ...state,
        isDeleted: true,
        allUsers: DeleteData(state.allUsers, payload),
      };
    case DELETE_ORGANISATION_FAIL:
      return {
        ...state,
        isDeleted: false,
      };
    case LOGOUT:
      return {
        ...state,
        allUsers: [],
        isAdded: false,
        isDeleted: false,
        isUpdated: false,
        singleUser: null,
        loading: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default SavingsReducer;
