import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
	DefaultHeader,
	ModalComponents,
	ModalNotification,
	SavingsTransaction,
} from "../../Component";
import { GlobalState } from "../../Data/Context";
import image from "../../assets/user.png";
import moment from "moment";
import { Buttons } from "../../Utils";
import axios from "axios";
import { toast } from "react-toastify";

const SingleCustomer = () => {
	const { organisation, getSingleOrganisationUser } = useContext(GlobalState);

	let [stateUsers, setStateUsers] = useState(null),
		initialState = { message: "", title: "", notification_type: "" },
		[stateData, setStateData] = useState(initialState),
		param = useParams(),
		[isOpen, setIsOpen] = useState(false),
		[loading, setLoading] = useState(false),
		[isOpen2, setIsOpen2] = useState(false),
		toggle = () => {
			setIsOpen(!isOpen);
		},
		toggle2 = () => {
			setIsOpen2(!isOpen2);
		},
		handleSendNotification = async e => {
			e.preventDefault();
			if (
				!stateData.notification_type ||
				!stateData.title ||
				!stateData.notification_type
			)
				return toast.info("Please input all fields");
			setLoading(true);
			try {
				let res = await axios.post(`/api/organisations/userNotification`, {
					title: stateData.title,
					message: stateData.message,
					user_id: stateUsers?.id,
					notification_type: stateData.notification_type,
				});
				toast.success(res.data.message);
				setStateData(initialState);
				toggle2();
			} catch (err) {
				console.log({ err });
				if (err) console.log(err.response?.data);
				let error = err.response?.data;
				if (error)
					toast.error(
						error.error && typeof error.error === "string"
							? error.error
							: error.message,
						{ autoClose: false }
					);
			}
			setLoading(false);
		},
		handleToggleFreeze = async e => {
			e.preventDefault();
			if (!stateData.title || !stateData.message)
				return toast.info("Please input all fields");
			setLoading(true);
			try {
				let res = await axios.post(`/api/organisations/freezeAccount`, {
					user_id: stateUsers?.id,
					title: stateData.title,
					message: stateData.message,
				});
				toast.success(res.data.message);
				setStateData(initialState);
				toggle();
			} catch (err) {
				console.log({ err });
				if (err) console.log(err.response?.data);
				let error = err.response?.data;
				if (error)
					toast.error(
						error.error && typeof error.error === "string"
							? error.error
							: error.message,
						{ autoClose: false }
					);
			}
			setLoading(false);
		},
		textChange =
			name =>
			({ target: { value } }) => {
				setStateData({ ...stateData, [name]: value });
			};

	useEffect(() => {
		getSingleOrganisationUser(param);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [param.id]);

	useEffect(() => {
		if (organisation.singleUser) {
			setStateUsers(organisation.singleUser.user_data);
		} else if (organisation.allUsers)
			organisation.allUsers.map(
				user => user.id === Number(param.id) && setStateUsers(user)
			);
	}, [organisation.allUsers, param.id, organisation.singleUser]);

	if (!stateUsers) return <></>;

	return (
		<section className="bg-light pb-4 main-height">
			<div className="container">
				<DefaultHeader text={"Here is your Customers"} />
				<section className="d-lg-flex align-items-center container">
					<div className="my-auto settingImg me-2 userImg">
						<img
							src={stateUsers?.image ? stateUsers?.image : image}
							alt="User"
							className="rounded-circle img-fluid"
						/>
					</div>
					<div className="w-100">
						<div className="d-lg-flex justify-content-between align-items-center mb-3">
							<h4 className="barFont">{stateUsers?.full_name}</h4>
							<div className="d-flex justify-content-between align-items-center">
								<button
									className="btn btn-outline-success btn-outline-success2 text-capitalize barFont fontReduce mx-1"
									onClick={
										stateUsers?.account_status.toLowerCase() === "active"
											? toggle
											: () => {}
									}>
									{stateUsers?.account_status.toLowerCase() !== "active"
										? "activate account"
										: "freeze acount"}
								</button>
								<button
									className="btn btn-success2 text-capitalize barFont fontReduce mx-1"
									onClick={toggle2}>
									send notification
								</button>
							</div>
						</div>
						<hr />
						<div className="row g-3">
							<div className="col-lg-3 mb-3 mb-lg-0 col-6">
								<small className="text-muted text-uppercase d-block barFont">
									email
								</small>
								<p className="text-dark fw-500 barFont fontReduce">
									{stateUsers?.email ? stateUsers?.email : "nil"}
								</p>
							</div>
							<div className="col-lg-3 mb-3 mb-lg-0 col-6">
								<small className="text-muted text-uppercase d-block barFont">
									phone
								</small>
								<p className="text-dark fw-500 barFont fontReduce">
									{stateUsers?.phone_number ? stateUsers?.phone_number : "nil"}
								</p>
							</div>
							<div className="col-lg-3 mb-3 mb-lg-0 col-6">
								<small className="text-muted text-uppercase d-block barFont">
									id type
								</small>
								<p className="text-dark fw-500 barFont fontReduce text-capitalize">
									{stateUsers?.type ? stateUsers?.type : "nil"}
								</p>
							</div>
							<div className="col-lg-3 mb-3 mb-lg-0 col-6">
								<small className="text-muted text-uppercase d-block barFont">
									savings
								</small>
								<p className="fw-500 barFont text-capitalize btn btn-success2 px-4">
									{stateUsers?.get_user_savings_count}
								</p>
							</div>
							<div className="col-lg-3 mb-3 mb-lg-0 col-6">
								<small className="text-muted text-uppercase d-block barFont">
									dob
								</small>
								<p className="fw-500 barFont text-capitalize text-dark">
									{stateUsers?.date_of_birth
										? moment(stateUsers?.date_of_birth).format("L")
										: "nil"}
								</p>
							</div>
							<div className="col-lg-3 mb-3 mb-lg-0 col-6">
								<small className="text-muted text-uppercase d-block barFont">
									bvn
								</small>
								<p className="fw-500 barFont text-capitalize text-dark fontReduce">
									{stateUsers?.get_verification_status?.bvn_no
										? stateUsers?.get_verification_status?.bvn_no
										: "nil"}
								</p>
							</div>
							<div className="col-lg-3 mb-3 mb-lg-0 col-6">
								<small className="text-muted text-uppercase d-block barFont">
									status
								</small>
								<p className="fw-500 barFont text-capitalize text-dark">
									{stateUsers?.account_status}
								</p>
							</div>
							<div className="col-lg-3 mb-3 mb-lg-0 col-6">
								<small className="text-muted text-uppercase d-block barFont">
									joined date
								</small>
								<p className="fw-500 barFont text-capitalize text-dark">
									{moment(stateUsers?.created_at).format("L")}
								</p>
							</div>
						</div>
					</div>
				</section>
				<SavingsTransaction data={stateUsers?.get_user_savings} />
			</div>
			<ModalComponents
				isOpen={isOpen}
				back={toggle}
				title={
					stateUsers?.account_status.toLowerCase() !== "active"
						? "activate account"
						: "freeze acount"
				}>
				<form>
					<p className="text-dark text-center">
						Do you want to{" "}
						{stateUsers?.account_status.toLowerCase() !== "active"
							? "activate account"
							: "freeze acount"}{" "}
						{stateUsers?.full_name}?
					</p>
					<div className="mb-3">
						<label htmlFor="Title" className="textColor">
							Title
						</label>
						<input
							type="text"
							name="Title"
							id="Title"
							value={stateData?.title}
							onChange={textChange("title")}
							className="form-control borderColor"
							placeholder="Title"
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="Message" className="textColor">
							Message
						</label>
						<textarea
							name="Savings Narration"
							id="Message"
							className="form-control borderColor"
							placeholder="Message"
							value={stateData?.message}
							onChange={textChange("message")}
							style={{
								height: "15rem",
								overflowY: "auto",
								resize: "none",
							}}
						/>
					</div>
					<Buttons
						loading={loading}
						onClick={handleToggleFreeze}
						css="btn-primary1 d-block mx-auto px-5 text-capitalize"
						width={"w-75"}
						title="send"
					/>
				</form>
			</ModalComponents>
			<ModalNotification
				type={""}
				isOpen={isOpen2}
				back={toggle2}
				loading={loading}
				handleSubmit={handleSendNotification}
				textChange={textChange}
				stateData={stateData}
			/>
		</section>
	);
};

export default SingleCustomer;
