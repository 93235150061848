import React from "react";
import { DefaultHeader, UserCustomers } from "../../Component";

const Customers = () => {
	return (
		<section className="bg-light pb-4 main-height">
			<div className="container">
				<DefaultHeader text={"Here is your Customers"} />
				<UserCustomers />
			</div>
		</section>
	);
};

export default Customers;
