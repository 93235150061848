import bgImg from "../../assets/faq-frame.png";
import AppButton from "../../components/button/button.component";
import Footer from "../../components/footer/footer.component";
import { Bounce, Slide } from "react-reveal";

const Faq = () => {
  return (
    <div>
      <section
        className="tw-bg-mainShade"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="tw-container tw-px-8 md:tw-px-0 tw-mx-auto tw-py-10">
          <Bounce>
            <div className="tw-max-w-2xl tw-mx-auto tw-text-center text-white tw-my-10">
              <p className="tw-text-4xl tw-font-medium tw-mb-4 text-white">
                You've got <span className="tw-text-secondary">questions</span>?
              </p>
              <p className="tw-text-4xl tw-font-medium tw-mb-4 text-white">
                We've got <span className="tw-text-secondary">answers</span>!
              </p>
              <p className="text-white">
                Everything you need to know about the product and billing. Can’t
                find the answer you’re looking for? Please contact our team
              </p>
            </div>
          </Bounce>
        </div>
      </section>
      <section>
        <div className="tw-container tw-px-8 md:tw-px-0 tw-mx-auto tw-py-10">
          <div className="tw-grid md:tw-grid-cols-2 tw-gap-12">
            <div>
              <Slide bottom>
                <div>
                  <p className="tw-font-medium tw-text-xl">
                    How do I fund my wallet?
                  </p>
                  <p className="tw-text-sm">
                    You can fund your wallet via a virtual account that will be
                    created for you using your details. <br />
                    You can also fund your wallet with your debit card. <br />
                    You can also fund your wallet from another person's wallet
                    ID to your wallet ID.
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    How do I withdraw my money?
                  </p>
                  <p className="tw-text-sm">
                    You can withdraw your money once you link your desired bank
                    account(i.e the bank you want to withdraw)
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    Do I have to be in an organisation to register?
                  </p>
                  <p className="tw-text-sm">
                    Yes, you have to be in an organisation in order to register.
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    Can I withdraw my savings at any time?
                  </p>
                  <p className="tw-text-sm">
                    No, you can only withdraw your savings at the end of the
                    savings duration.
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    What do I need to open an account with cebiz pay app?
                  </p>
                  <p className="tw-text-sm">
                    You must belong to any organisation or cooperative that is
                    registered with Cebiz pay in order to open an account with
                    Cebiz pay.
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    Why do you need my BVN?
                  </p>
                  <p className="tw-text-sm">
                    We ask you to confirm your BVN so we can be sure no one is
                    pretending to be you. Once you confirm your BVN, we match
                    your details with the information on the national BVN
                    database owned by the Central Bank. <br />
                    Note : we won't use your BVN to access your bank account(s).
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    Is there a minimum balance?
                  </p>
                  <p className="tw-text-sm">
                    No, we don’t have a minimum account balance.
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    Is my money safe with Cebiz pay?
                  </p>
                  <p className="tw-text-sm">
                    Yes, your money is safe with us. We secure all accounts with
                    the same high-security encryption used by regular banks.
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    Will I get account statements?
                  </p>
                  <p className="tw-text-sm">
                    Yes, you can request an account statement for a specific
                    time period. <br />
                    To request an account statement, tap Dashboard on the app
                    then tap Statements & Reports
                  </p>
                </div>
              </Slide>
              <Slide bottom>
                <div className="tw-mt-8">
                  <p className="tw-font-medium tw-text-xl">
                    What should I do if I need help?
                  </p>
                  <p className="tw-text-sm">
                    The easiest way to reach us is to check for a message icon
                    by your right on the Dashboard on the Cebiz pay app then tap
                    to Chat With Us. You can also send an email to help at
                    cebizpay@gmail.com between 8:00 am and 5:00 pm on weekdays.
                  </p>
                </div>
              </Slide>
            </div>
            <div>
              <Slide bottom>
                <img src={require("../../assets/info-img.png")} alt="" />
              </Slide>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-bg-mainShade">
        <div className="tw-container tw-px-8 md:tw-px-0 tw-mx-auto tw-mt-20 tw-py-10">
          <div className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-12">
            <div className="tw-self-center lg:tw-col-span-2">
              <Slide bottom>
                <p className="tw-text-white tw-text-2xl tw-font-medium">
                  Still have questions?
                </p>
                <p className="tw-text-sm tw-text-white tw-mt-4">
                  Can’t find the answer you’re looking for? Please{" "}
                  <span className="tw-text-secondary">contact</span> our team
                </p>
              </Slide>
              <div className="tw-mt-8">
                <AppButton children="get in touch" buttonType="secondary" />
              </div>
            </div>
            <div>
              <img
                src={require("../../assets/amico.png")}
                alt=""
                className="tw-mx-auto tw-h-40"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="tw-relative">
        <div className="tw-absolute tw-inset-0 tw-bg-mainLight tw-opacity-10 -tw-z-10"></div>
        <Bounce>
          <div className="tw-px-8 tw-text-center tw-py-20">
            <p className="tw-text-4xl tw-text-mainLight tw-capitalize tw-mb-4">
              download cebiz app
            </p>
            <p className="tw-text-mainLight tw-text-sm tw-max-w-lg tw-mx-auto">
              Do more with the app Save, Send & Receive money with no hassle
              from anywhere.
            </p>
            <div className="tw-flex tw-items-center tw-justify-center tw-mt-4">
              <img
                src={require("../../assets/apple-store.png")}
                alt="apple store"
              />
              <img src={require("../../assets/google-store.png")} alt="" />
            </div>
          </div>
        </Bounce>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Faq;
