import React from "react";
import { useContext } from "react";
import { GlobalState } from "../Data/Context";
import Chart from "react-apexcharts";

const NewChartFormat = ({ options, series, type, info, labels }) => {
	const { numberWithCommas, nairaSign } = useContext(GlobalState);

	return (
		<>
			<div className="p-2 col-md-6 rounded mx-auto">
				<div>
					<h5 className="interFont text-capitalize textColor">{info.name}</h5>
					{info.money ? (
						<p className="interFont fw-500">
							{nairaSign} {numberWithCommas(Number(info.amount).toFixed(2))}
						</p>
					) : (
						<p className="interFont fw-500">
							{numberWithCommas(info.amount)}{" "}
							<span className="text-capitalize">{info.user}</span>
						</p>
					)}
				</div>
				<Chart
					options={options}
					series={series}
					labels={labels}
					type={type ? type : "bar"}
					// width="500"
				/>
			</div>
		</>
	);
};

export default NewChartFormat;
