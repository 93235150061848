import React from "react";
import { DefaultHeader, Transactions } from "../../../Component";
import { Reports } from "../../../Component/Payroll";

const MainReports = () => {
	return (
		<main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader text={"Payment Report"} />
				<Reports />
				<Transactions />
			</div>
		</main>
	);
};

export default MainReports;
