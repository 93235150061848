import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Brand from "../../components/brand/brand.component";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import RoundedIcon from "../rounded-icon/rounded-icon.component";

const Footer = () => {
  return (
    <footer className="tw-bg-mainShade tw-py-12 tw-text-white">
      <div className=" tw-flex tw-justify-between tw-flex-wrap lg:tw-flex-nowrap">
        <div className="tw-w-full lg:tw-w-auto">
          <div className="tw-flex tw-justify-between tw-flex-wrap tw-mb-8 tw-gap-12 tw-mx-12">
            <div>
              <Brand height="tw-h-20" />
              <p className="tw-text-[10px] text-white tw-w-60 tw-leading-tight tw-font-normal">
                Taking control of your finances with this, and saving for your
                future will be made easier with CEBIZ mobile app
              </p>
              <ul className="tw-mb-8 tw-text-[10px]">
                <li>(07032490411)</li>
                <li><address>info@cebiz.co</address></li>
              </ul>
            </div>
            <div className="tw-capitalize">
              <p className="tw-text-md tw-font-bold tw-mb-4 text-white">quick menu</p>
              <div className="tw-grid tw-grid-cols-2 tw-gap-8 ttw-ext-[10px]">
                <ul>
                  <li className="tw-mb-4">
                    <Link to="/mobile-app">Mobile App</Link>
                  </li>
                  <li className="tw-mb-4">
                    <Link to="/savings">Organizational savings</Link>
                  </li>
                </ul>
                <ul>
                  <li className="tw-mb-4">
                    <Link to="/">company</Link>
                  </li>
                  <li className="tw-mb-4">
                    <Link to="/about">About us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tw-border tw-border-gray tw-opacity-50 tw-hidden lg:tw-block"></div>
          <div className="tw-mx-12 tw-mt-4 tw-text-[10px] tw-hidden lg:tw-block text-white">
            <p className="text-white">© Copyright 2021. All rights reserved.</p>
          </div>
        </div>
        <div className="tw-mx-12">
          <div className="tw-capitalize">
            <p className="tw-text-md tw-font-bold tw-mb-4 text-white">subscribe</p>
            <div className="tw-border-2 tw-p-1 tw-rounded-xl tw-flex tw-items-center tw-mb-4 tw-justify-between tw-max-w-[60%]">
              <input
                type="text"
                placeholder="Email for Newsletter"
                className="tw-bg-transparent tw-outline-none tw-w-full tw-py-4 tw-h-8"
              />
              <span className="tw-h-8 tw-w-10 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-mainLight">
                <ArrowForwardRoundedIcon />
              </span>
            </div>
            <div className="tw-flex tw-gap-x-4">
              <RoundedIcon
                link="/"
                icon={<FontAwesomeIcon icon="fa-brands fa-instagram" />}
              />
              <RoundedIcon
                link="/"
                icon={<FontAwesomeIcon icon="fa-brands fa-facebook-f" />}
              />
              <RoundedIcon
                link="/"
                icon={<FontAwesomeIcon icon="fa-brands fa-twitter" />}
              />
            </div>
            <div className="mt-4">
              <Link
                to="/"
                className="tw-text-md tw-font-medium tw-opacity-50 tw-mr-8"
              >
                Terms of service
              </Link>
              <Link
                to="/"
                className="tw-text-md tw-font-medium tw-opacity-50 tw-mr-8"
              >
                Licence
              </Link>
              <Link
                to="/"
                className="tw-text-md tw-font-medium tw-opacity-50 tw-mr-8"
              >
                Privacy policy
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="tw-border tw-border-gray tw-opacity-50 tw-mt-4 lg:tw-hidden"></div>
        <div className="tw-mx-12 tw-mt-4 tw-text-[10px] lg:tw-hidden text-white">
          <p className="text-white text-center">© Copyright 2021. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
