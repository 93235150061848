import Footer from "../../components/footer/footer.component";

const MobileApp = () => {
  return (
    <div>
      <section className="tw-bg-mainShade">
        <div className="tw-container tw-px-8 md:tw-px-0 tw-mx-auto tw-py-10">
          <div className="tw-grid md:tw-grid-cols-2 tw-gap-12">
            <div className="tw-self-center">
              <p className="tw-text-white tw-text-4xl tw-font-medium tw-font-mono">
                To pay bills without Stress, save with{" "}
                <span className="tw-text-secondary">CEBIZ</span>
              </p>
              <p className="tw-text-sm tw-font-light tw-text-white tw-opacity-60 tw-mt-8">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Adipisci, explicabo! Qui nesciunt saepe itaque. Neque ipsa at
                praesentium laudantium laboriosam aspernatur repellendus, iusto
                quisquam! Provident voluptatibus ipsa possimus sequi iusto,
                doloremque modi illum esse optio sit delectus voluptates maxime
                eius.
              </p>
            </div>
            <div>
              <img
                src={require("../../assets/pana.png")}
                alt=""
                className="tw-mx-auto"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="tw-relative">
        <div className="tw-absolute tw-inset-0 tw-bg-mainLight tw-opacity-10 -tw-z-10"></div>
        <div className="tw-px-8 tw-text-center tw-py-20">
          <p className="tw-text-4xl tw-text-mainLight tw-capitalize tw-mb-4">download cebiz app</p>
          <p className="tw-text-mainLight tw-text-sm tw-max-w-lg tw-mx-auto">
            Do more with the app Save, Send & Receive money with no hassle from
            anywhere.
          </p>
          <div className="tw-flex tw-items-center tw-justify-center tw-mt-4">
            <img src={require("../../assets/apple-store.png")} alt="apple store" />
            <img src={require("../../assets/google-store.png")} alt="" />
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default MobileApp;
