// TOKEN
export const TOKEN = "CEBIZ_ORG";
export const LOGOUT = "LOGOUT";
export const LOGOUT_LOADING = "LOGOUT_LOADING";

// USER
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGIN_USER_LOADING = "LOGIN_USER_LOADING";
export const GET_USER = "GET_USER";
export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const SECOND_VERIFY = "SECOND_VERIFY";
export const VERIFY_MAIL = "VERIFY_MAIL";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_LOADING = "GET_DASHBOARD_LOADING";
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL";

// SAVINGS
export const UPDATE_SAVING = "UPDATE_SAVING";
export const UPDATE_SAVING_FAIL = "UPDATE_SAVING_FAIL";
export const DELETE_SAVING = "DELETE_SAVING";
export const DELETE_SAVING_FAIL = "DELETE_SAVING_FAIL";
export const ADD_SAVING = "LOGIN_SAVING";
export const ADD_SAVING_FAIL = "LOGIN_SAVING_FAIL";
export const GET_SAVING = "GET_SAVING";
export const GET_SAVING_LOADING = "GET_SAVING_LOADING";
export const GET_SAVING_FAIL = "GET_SAVING_FAIL";
export const RELOAD_SAVINGS = "RELOAD_SAVINGS";

// ORGANISATION
export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";
export const UPDATE_ORGANISATION_FAIL = "UPDATE_ORGANISATION_FAIL";
export const DELETE_ORGANISATION = "DELETE_ORGANISATION";
export const DELETE_ORGANISATION_FAIL = "DELETE_ORGANISATION_FAIL";
export const ADD_ORGANISATION = "LOGIN_ORGANISATION";
export const ADD_ORGANISATION_FAIL = "LOGIN_ORGANISATION_FAIL";
export const GET_ORGANISATION = "GET_ORGANISATION";
export const GET_ORGANISATION_LOADING = "GET_ORGANISATION_LOADING";
export const GET_ORGANISATION_FAIL = "GET_ORGANISATION_FAIL";
export const GET_USER_ORG = "GET_USER_ORG";
export const GET_USER_ORG_LOADING = "GET_USER_ORG_LOADING";
export const GET_USER_ORG_FAIL = "GET_USER_ORG_FAIL";
export const GET_ORGANISATION_INFO = "  GET_ORGANISATION_INFO";
export const GET_ORGANISATION_INFO_LOADING = "  GET_ORGANISATION_INFO_LOADING";
export const GET_ORGANISATION_INFO_FAIL = "  GET_ORGANISATION_INFO_FAIL";

// Payroll
export const CREATE_PAYMENT_CLASS_LOADING = "CREATE_PAYMENT_CLASS_LOADING";
export const CREATE_PAYMENT_CLASS = "CREATE_PAYMENT_CLASS";
export const CREATE_PAYMENT_CLASS_FAIL = "CREATE_PAYMENT_CLASS_FAIL";
export const GET_PAYMENT_CLASS_LOADING = "GET_PAYMENT_CLASS_LOADING";
export const GET_PAYMENT_CLASS = "GET_PAYMENT_CLASS";
export const GET_PAYMENT_CLASS_FAIL = "GET_PAYMENT_CLASS_FAIL";
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_LOADING = "CREATE_GROUP_LOADING";
export const CREATE_GROUP_FAILED = "CREATE_GROUP_FAILED";
export const CREATE_LEVEL = "CREATE_LEVEL";
export const CREATE_LEVEL_LOADING = "CREATE_LEVEL_LOADING";
export const CREATE_LEVEL_FAILED = "CREATE_LEVEL_FAILED";
export const CREATE_ROLE = "CREATE_TITLE";
export const CREATE_ROLE_LOADING = "CREATE_TITLE_LOADING";
export const CREATE_ROLE_FAILED = "CREATE_TITLE_FAILED";
export const GET_GROUP_LOADING = "GET_GROUP_LOADING";
export const GET_GROUP_FAILED = "GET_GROUP_FAILED";
export const GET_GROUP = "GET_GROUP";
export const GET_LEVEL_LOADING = "GET_LEVEL_LOADING";
export const GET_LEVEL_FAILED = "GET_LEVEL_FAILED";
export const GET_LEVEL = "GET_LEVEL";
export const GET_ROLE_LOADING = "GET_ROLE_LOADING";
export const GET_ROLE_FAILED = "GET_ROLE_FAILED";
export const GET_ROLE = "GET_ROLE";
export const GET_WORKER = "GET_WORKER";
export const GET_WORKER_LOADING = "  GET_WORKER_LOADING";
export const GET_WORKER_FAILED = "  GET_WORKER_FAILED";
export const UPDATE_WORKER = "UPDATE_WORKER";
export const UPDATE_WORKER_LOADING = "UPDATE_WORKER_LOADING";
export const UPDATE_WORKER_FAILED = "UPDATE_WORKER_FAILED";
export const ADD_WORKER = "ADD_WORKER";
export const ADD_WORKER_LOADING = "ADD_WORKER_LOADING";
export const ADD_WORKER_FAILED = "ADD_WORKER_FAILED";
export const GET_CLASS_WORKER = "GET_CLASS_WORKER";
export const GET_CLASS_WORKER_FAILED = "GET_CLASS_WORKER_FAILED";
export const GET_CLASS_WORKER_LOADING = "GET_CLASS_WORKER_LOADING";
export const PAY_WORKERS = "PAY_WORKERS";
export const PAY_WORKERS_LOADING = "PAY_WORKERS_LOADING";
export const PAY_WORKERS_FAILED = "PAY_WORKERS_FAILED";
export const VALIDATE_WORKERS = "VALIDATE_WORKERS";
export const VALIDATE_WORKERS_LOADING = "VALIDATE_WORKERS_LOADING";
export const VALIDATE_WORKERS_FAILED = "VALIDATE_WORKERS_FAILED";
export const DELETE_GROUP = "  DELETE_GROUP";
export const DELETE_GROUP_FAIL = "  DELETE_GROUP_FAIL";
export const DELETE_LEVEL = "  DELETE_LEVEL";
export const DELETE_LEVEL_FAIL = "  DELETE_LEVEL_FAIL";
export const DELETE_ROLE = "  DELETE_ROLE";
export const DELETE_ROLE_FAIL = "  DELETE_ROLE_FAIL";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";
export const UPDATE_LEVEL = "UPDATE_LEVEL";
export const UPDATE_LEVEL_FAIL = "UPDATE_LEVEL_FAIL";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";
