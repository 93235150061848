import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../Data/Context";

const Home = () => {
	const { headerFiles } = useContext(GlobalState);
	let navigate = useNavigate();
	useEffect(() => {
		navigate(headerFiles[0].url);
	}, [navigate, headerFiles]);
	return <></>;
};

export default Home;
