import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { GlobalState } from "./Data/Context";
import PageRender from "./PageRender";
import Home from "./routes/home";
import { Sidebar } from "./Component";
import Home2 from "./Pages/home";
import Home3 from "./Views/home";
import AppNav from "./components/nav/nav.component";

const Routers = () => {
	const { auth } = useContext(GlobalState);

	return (
		<div
			className={auth?.userType === "ORGANISATION" ? "side-header" : ""}
			data-bs-spy="scroll"
			data-bs-target="#header-nav"
			data-bs-offset="1">
			<div id={auth?.userType === "ORGANISATION" ? "main-wrapper" : ""}>
				<ToastContainer />
				{auth?.userType === "ORGANISATION" ? <Sidebar /> : <AppNav />}
				<Routes>
					<Route
						path="/"
						element={
							auth?.userType === "ORGANISATION" ? (
								<Home2 />
							) : auth?.userType === "USER" ? (
								<Home3 />
							) : (
								<Home />
							)
						}
					/>
					<Route path="/:page" element={<PageRender />} />
					<Route path="/:page/:id" element={<PageRender />} />
					<Route path="/:page/:id/:step" element={<PageRender />} />
				</Routes>
			</div>
		</div>
	);
};

export default Routers;
