import React, { useState, useContext, useEffect } from "react";
import { BiToggleLeft, BiToggleRight, BiCheck } from "react-icons/bi";
import image from "../../assets/user.png";
import { GlobalState } from "../../Data/Context";
import {
  DefaultHeader,
  ModalInviteUser,
  ModalPasswordRest,
  UserCustomers,
} from "../../Component";
import "../../Styles/OrgAuth.css";
import { BiEditAlt } from "react-icons/bi";
import { IconContext } from "react-icons/lib";

const Settings = () => {
  const { nairaSign, numberWithCommas, auth } = useContext(GlobalState);
  let [saving, SetSavings] = useState(true),
    [isOpen, setIsOpen] = useState(false),
    [isOpen2, setIsOpen2] = useState(false),
    [orgDetails, setOrgDetails] = useState(null);

  useEffect(() => {
    if (auth?.user?.organisation_details)
      setOrgDetails(auth?.user?.organisation_details);
  }, [auth?.user]);

  if (!orgDetails) return <></>;

  let toggle = () => {
    setIsOpen(!isOpen);
  };
  let toggle2 = () => {
    setIsOpen2(!isOpen2);
  };

  let handleSubmit = (e) => {
    if (e) e.preventDefault();
    toggle();
  };
  let handleSubmitPass = (e) => {
    if (e) e.preventDefault();
    toggle2();
  };

  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="container">
        <div className="tw-bg-[#fff] tw-p-8 tw-my-8">
          <DefaultHeader />
          <section className="tw-mt-7 tw-w-1/2 tw-mx-auto">
            <div>
              <div className="tw-flex tw-w-1/2 tw-mx-auto  tw-mt-6 tw-justify-center tw-items-center tw-gap-3">
                <div>
                  <h6 className="tw-text-3xl tw-text-center tw-font-bold tw-text-[#000]">
                    Account Details
                  </h6>
                  <div className="tw-flex tw-items-center tw-justify-center tw-mt-5 tw-gap-2">
                    <img src={image} alt="User" className="tw-h-14" />
                    <div className="tw-cursor-pointer">
                      <IconContext.Provider value={{ color: "black" }}>
                        <BiEditAlt size={20} />
                      </IconContext.Provider>
                    </div>
                  </div>
                  <h6 className="tw-text-lg tw-pt-3 tw-text-center tw-text-[#1b1b1b] tw-font-semibold">
                    {orgDetails.organisation_name}
                  </h6>
                  <p className="tw-text-base tw-text-[#000] tw-py-1 tw-text-center tw-font-medium">
                    <span className="tw-font-bold">Role: </span> Super Admin
                  </p>
                  <p className="tw-text-base tw-text-[#1b1b1b] tw-text-center tw-font-medium tw-whitespace-nowrap">
                    <span className="tw-font-bold">Email Address: </span>
                    {orgDetails.organisation_email}
                  </p>
                  <div className="tw-flex tw-justify-center">
                    <div
                      onClick={() => SetSavings(!saving)}
                      className="myCursor"
                    >
                      {saving ? (
                        <BiToggleLeft size={60} color="#031a6e" />
                      ) : (
                        <BiToggleRight size={60} color="#031a6e" />
                      )}
                      {/* <p className="text-capitalize tw-text-center text-dark m-0 fontReduce">
                        other savings
                      </p> */}
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-center tw-gap-5 tw-mt-5">
                    <button
                      onClick={toggle}
                      className="btn text-capitalize donateHome3 text-white fontReduce"
                    >
                      invite user
                    </button>
                    <button
                      onClick={toggle2}
                      className="btn text-capitalize btn-light border fontReduce"
                    >
                      change password
                    </button>
                  </div>
                </div>
              </div>

              {/* <div className="tw-mt-1 tw-flex tw-justify-center">
                <div onClick={() => SetSavings(!saving)} className="myCursor">
                  {saving ? (
                    <BiToggleLeft size={60} color="#031a6e" />
                  ) : (
                    <BiToggleRight size={60} color="#031a6e" />
                  )}
                  <p className="text-capitalize tw-text-center text-dark m-0 fontReduce">
                    other savings
                  </p>
                </div>
              </div> */}
            </div>
          </section>
          {/* <section className="settingGrid">
            <div>
              <h6 className="tw-text-gray tw-text-base">Account Details</h6>
              <div className="my-2">
                <img
                  src={image}
                  alt="User"
                  className="rounded-circle tw-h-14"
                />
                <div>
                  <button className="rounded tw-h-10 tw-px-4 tw-border tw-text-sm tw-mt-4 tw-border-gray tw-text-gray tw-whitespace-nowrap">
                    change picture
                  </button>
                </div>
              </div>
              <h4 className=" tw-text-lg tw-font-medium">
                {orgDetails.organisation_name}
              </h4>
              <small className="text-capitalize d-block tw-text-xs">
                super admin
              </small>
              <h4 className="fontReduce tw-text-lg tw-font-medium">
                {orgDetails.organisation_email}
              </h4>
              <small className="text-capitalize d-block fontReduce">
                email address
              </small>
              <div className="mt-2">
                <div onClick={() => SetSavings(!saving)} className="myCursor">
                  {saving ? (
                    <BiToggleLeft size={60} color="#031a6e" />
                  ) : (
                    <BiToggleRight size={60} color="#031a6e" />
                  )}
                </div>
                <p className="text-capitalize text-dark m-0 fontReduce">
                  other savings
                </p>
              </div>
            </div>
            <div className="d-flex tw-gap-4 mb-3 mb-lg-0">
              <div className="pt-3">
                <button
                  onClick={toggle}
                  className="btn text-capitalize donateHome3 text-white fontReduce"
                >
                  invite user
                </button>
              </div>
              <div className="pt-3">
                <button
                  onClick={toggle2}
                  className="btn text-capitalize btn-light border fontReduce"
                >
                  change password
                </button>
              </div>
            </div>
          </section> */}
        </div>
        <UserCustomers />
        <section className="d-none d-lg-block">
          <div className="text-center my-3">
            <h6 className="text-uppercase genSansFont textColor">pricing</h6>
            <h3 className="genSansFont fw-bold textColor">
              Affordable pricing plans
            </h3>
          </div>
          <section className="ms-lg-5">
            <div className="pricingGrid pricingGridHeader">
              <div className="d-flex mt-auto mb-3 genSansFont">Features</div>
              <div className="text-center">
                <p className="text-muted mt-3 genSansFont">
                  {nairaSign}
                  {numberWithCommas(Number(9999).toFixed(2))}
                </p>
                <p className="fw-bold text-capitalize text-dark genSansFont">
                  basic
                </p>
                <button className="btn donateHome3 text-white text-capitalize mb-3 genSansFont">
                  get started
                </button>
              </div>
              <div className="text-center midPricing py-3">
                <p className="text-muted mt-3 genSansFont">
                  {nairaSign}
                  {numberWithCommas(Number(99999).toFixed(2))}
                </p>
                <p className="fw-bold text-capitalize text-dark genSansFont">
                  growth
                </p>
                <button className="btn donateHome3 text-white text-capitalize mb-3 genSansFont">
                  get started
                </button>
              </div>
              <div className="text-center">
                <p className="text-muted mt-3 genSansFont">
                  {nairaSign}
                  {numberWithCommas(Number(999999).toFixed(2))}
                </p>
                <p className="fw-bold text-capitalize text-dark genSansFont">
                  enterprice
                </p>
                <button className="btn donateHome3 text-white text-capitalize mb-3 genSansFont">
                  get started
                </button>
              </div>
            </div>
            <div className="pricingGrid border-top border-bottom pricingGrid2">
              <div className="genSansFont">Number of Admin</div>
              <div className="genSansFont">1 user</div>
              <div className="midPricing genSansFont">5 user</div>
              <div className="genSansFont">unlimited</div>
            </div>
            <div className="pricingGrid border-top border-bottom pricingGrid2 genSansFont">
              <div className="genSansFont">Total credit limit</div>
              <div className="genSansFont">
                {nairaSign}
                {numberWithCommas(Number(999999).toFixed(2))}
              </div>
              <div className="midPricing genSansFont">
                {nairaSign}
                {numberWithCommas(Number(99999999).toFixed(2))}
              </div>
              <div className="genSansFont">
                {nairaSign}
                {numberWithCommas(Number(9999999999).toFixed(2))}
              </div>
            </div>
            <div className="pricingGrid border-top border-bottom pricingGrid2">
              <div className="genSansFont">Individual user analytics</div>
              <div>
                <div className="list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div className="midPricing">
                <div className="donateHome3 text-white list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div>
                <div className="list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
            </div>
            <div className="pricingGrid border-top border-bottom pricingGrid2">
              <p className="m-0 fw-bold genSansFont">Reporting</p>
              <div></div>
              <div className="midPricing"></div>
              <div></div>
            </div>
            <div className="pricingGrid border-top border-bottom pricingGrid2">
              <div className="genSansFont">Daily reports</div>
              <div>
                <div className="d-none list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div className="midPricing">
                <div className="donateHome3 text-white list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div>
                <div className="list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
            </div>
            <div className="pricingGrid border-top border-bottom pricingGrid2">
              <div className="genSansFont">Weekly reports</div>
              <div>
                <div className="d-none list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div className="midPricing">
                <div className="donateHome3 text-white list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div>
                <div className="list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
            </div>
            <div className="pricingGrid border-top border-bottom pricingGrid2">
              <div className="genSansFont">Monthly reports</div>
              <div>
                <div className="d-none list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div className="midPricing">
                <div className="d-none donateHome3 text-white list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div>
                <div className="list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
            </div>
            <div className="pricingGrid border-top border-bottom pricingGrid2">
              <div className="genSansFont">Custom reports</div>
              <div>
                <div className="d-none list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div className="midPricing">
                <div className="d-none donateHome3 text-white list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
              <div>
                <div className="list-group-item-primary rounded-circle p-1">
                  <BiCheck size={30} />
                </div>
              </div>
            </div>
          </section>
        </section>
        <ModalInviteUser
          isOpen={isOpen}
          back={toggle}
          handleSubmit={handleSubmit}
        />
        <ModalPasswordRest
          isOpen={isOpen2}
          back={toggle2}
          handleSubmit={handleSubmitPass}
        />
      </div>
    </main>
  );
};

export default Settings;
