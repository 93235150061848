import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import { FaDownload } from "react-icons/fa";
import { DefaultHeader } from "../../Component";
import { GlobalState } from "../../Data/Context";
import { useParams } from "react-router-dom";

const Reciepts = () => {
	const { nairaSign, numberWithCommas, auth } = useContext(GlobalState);

	let [orgWallet, setOrgWallet] = useState(null),
		param = useParams();

	useEffect(() => {
		if (auth?.user?.organisation_wallet_history)
			auth?.user?.organisation_wallet_history.map(
				item => item.id === Number(param.id) && setOrgWallet(item)
			);
	}, [auth?.user?.organisation_wallet_history, param.id]);

	if (!orgWallet) return <></>;

	return (
		<main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader notD />
				<div className="d-lg-flex justify-content-between align-items-center mb-lg-4">
					<div>
						<h1 className="interFont">
							{nairaSign}{" "}
							{numberWithCommas(Number(orgWallet?.amount).toFixed(2))}
						</h1>
						<small className="text-muted text-uppercase barFont">
							amount paid
						</small>
					</div>
					<div>
						<button className="btn text-uppercase mx-3 fontReduce px-md-4 m-0 btn-deep-red py-2 interFont">
							send
						</button>
						<button className="btn text-capitalize mx-3 fontReduce px-md-4 m-0 border py-2 barFont text-muted">
							<FaDownload className="dashboardP" /> export
						</button>
					</div>
				</div>
				<div className="row g-4">
					<div className="col-lg-6 px-lg-5">
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">Expected amount</small>
							<small className="fw-bold barFont">
								{nairaSign}{" "}
								{numberWithCommas(Number(orgWallet?.amount).toFixed(2))}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">PAYMENT METHOD</small>
							<small className="fw-bold barFont">{orgWallet?.type}</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">Transaction ID</small>
							<small className="fw-bold barFont">{orgWallet?.id}</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">{`Date & time`}</small>
							<small className="fw-bold barFont">
								{moment(orgWallet?.created_at).fromNow()}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">fee</small>
							<small className="fw-bold barFont">
								{nairaSign}{" "}
								{numberWithCommas(Number(orgWallet?.amount).toFixed(2))}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">transaction fee</small>
							<small className="fw-bold barFont">
								{nairaSign}{" "}
								{numberWithCommas(Number(orgWallet?.amount).toFixed(2))}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">total fee</small>
							<small className="fw-bold barFont">
								{nairaSign}{" "}
								{numberWithCommas(Number(orgWallet?.amount).toFixed(2))}
							</small>
						</div>
					</div>
					<div className="col-lg-6 px-lg-5">
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">DESCRIPTION</small>
							<small className="fw-bold barFont">
								{orgWallet?.description}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">Payment date</small>
							<small className="fw-bold barFont">
								{moment(orgWallet?.created_at).format("DD MMM, YYYY")}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">PAYMENT REF</small>
							<small className="fw-bold barFont">
								{orgWallet?.transaction_reference}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">
								SOURCE ACCOUNT INFO
							</small>
							<small className="fw-bold barFont">
								*********9866 ({orgWallet?.source})
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">
								SOURCE ACCOUNT name
							</small>
							<small className="fw-bold barFont">Josiah Abiodun</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">
								Destination account info
							</small>
							<small className="fw-bold barFont">
								{auth?.user?.organisation_details?.organisation_account} (Cebiz
								Bank)
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center mb-lg-3 mb-2">
							<small className="text-uppercase barFont">CUSTOMER NAME</small>
							<small className="fw-bold barFont">
								{auth?.user?.organisation_details?.organisation_name}
							</small>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Reciepts;
