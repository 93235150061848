import CreateIcon from "@mui/icons-material/Create";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { GrView } from "react-icons/gr";
import { AiFillEdit } from "react-icons/ai";
import { RiProfileFill } from "react-icons/ri";
import bgVector from "../../assets/bv-vector.png";
import { IconContext } from "react-icons";
import Confirm from "../../assets/confirm.svg";

const WorkerClass = ({
  addWorker,
  viewClass,
  paymentClassName,
  color1,
  color2,
  deleteModal,
}) => {
  return (
    <div
      className={``}
      // style={{ background: `linear-gradient(to right, ${color1}, ${color2}` }}
    >
      <div
        className={`tw-flex tw-items-center tw-bg-white tw-shadow-xl tw-justify-center tw-flex-col tw-h-52 tw-px-3`}
        style={{
          // boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.07)",
          borderRadius: "20px",
        }}
      >
        <img src={Confirm} alt="" className="tw-h-10" />
        {/* <IconContext.Provider value={{ color: "#f87474" }}>
          <RiProfileFill size={40} />
        </IconContext.Provider> */}
        <p className="text-center tw-text-[#000] tw-pt-3 tw-text-lg tw-font-medium tw-capitalize">
          {paymentClassName}
        </p>
        <div className="tw-flex tw-justify-center tw-items-center tw-gap-8 tw-mt-6">
          {addWorker && (
            <div
              className="tw-cursor-pointer tw-flex tw-items-center"
              onClick={addWorker}
            >
              <span className="text-white me-2 tw-rounded-tl-md tw-rounded-br-md">
                <IconContext.Provider value={{ color: "#031a6e" }}>
                  <BsFillPersonPlusFill size={10} />
                </IconContext.Provider>
              </span>
              <span className="tw-font-medium tw-text-sm tw-text-[#000]">
                Add
              </span>
            </div>
          )}
          {viewClass && (
            <div
              className="tw-cursor-pointer tw-flex tw-items-center"
              onClick={viewClass}
            >
              <span className="me-2">
                <IconContext.Provider value={{ color: "#031a6e" }}>
                  <GrView size={10} />
                </IconContext.Provider>
              </span>
              <span className="tw-font-medium tw-text-sm tw-text-[#000]">
                View
              </span>
            </div>
          )}
          <div
            className="tw-cursor-pointer tw-flex tw-items-center"
            onClick={deleteModal}
          >
            <span className="text-white me-2">
              <IconContext.Provider value={{ color: "#031a6e" }}>
                <AiFillEdit size={10} />
              </IconContext.Provider>
            </span>
            <span className="tw-font-medium tw-text-sm tw-text-[#000]">
              Edit
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerClass;
