import { Link } from "react-router-dom";
import whiteLogo from "../../assets/brand-white.png";
import darkLogo from "../../assets/brand-dark.png";
import "./brand.style.css";

const Brand = ({ type, height }) => {
  return (
    <Link to="/" className="brand-containerbpx-8 mdbpx-0">
      {type === "dark" ? (
        <img src={darkLogo} alt="" className={`${height}`} />
      ) : (
        <img src={whiteLogo} alt="" className={`${height}`} />
      )}
    </Link>
  );
};

export default Brand;
