import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer className="container-fluid foot">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="foot">
            <Link to="/">
              {" "}
              <img
                src={require("../assets/logo03.png")}
                alt=""
                className="footerImage"
              />{" "}
            </Link>
            <p className="fotext1">
              Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam
              mauris.
            </p>
            <div>
              <img
                src={require("../assets/apple-store.png")}
                alt="apple-store"
                className="store"
              />
            </div>
            <div className="">
              <img
                src={require("../assets/google-store.png")}
                alt="google-store"
                className="store"
              />
            </div>
          </div>
          <div className="foot">
            <ul>
              <h2 className="foh2">Company</h2>
              <li className="ulLi">
                <Link
                  to={"/about"}
                  className="text-decoration-none text-white ulLi"
                >
                  About us
                </Link>
              </li>
              <li className="ulLi">Career</li>
              <li className="ulLi">
                <Link
                  to={"/orgsavings"}
                  className="text-decoration-none text-white ulLi"
                >
                  Organizational savings
                </Link>
              </li>
            </ul>
          </div>
          <div className="foot">
            <ul>
              <h2 className="foh2">FAQs</h2>
              <li className="ulLi">Talk to us</li>
            </ul>
          </div>
          <div className="foot">
            <div className="border rounded contact">
              <div className="d-flex p-2">
                <img
                  src={require("../assets/Vector (7).png")}
                  alt="email"
                  className="mt-2 me-2 fimg2"
                />
                <div>
                  <p className="side">Email</p>
                  <p className="sides">hellocebiz@gmail.com</p>
                </div>
              </div>
            </div>
            <div className="border rounded mt-3 contact">
              <div className="d-flex p-2">
                <img
                  src={require("../assets/Frame 42574.png")}
                  alt="phone"
                  className="mt-2 me-2 fimg2"
                />
                <div>
                  <p className="side">Phone</p>
                  <p className="sides">+234 9843 78430</p>
                </div>
              </div>
            </div>
            <div className="d-flex mt-4">
              <img
                src={require("../assets/Frame 42576.png")}
                alt="phone"
                className="fimg2"
              />
              <img
                src={require("../assets/Frame 42575.png")}
                alt="phone"
                className="ms-2 fimg2"
              />
              <img
                src={require("../assets/Frame 42577.png")}
                alt="phone"
                className="ms-2 fimg2"
              />
              <img
                src={require("../assets/Frame 42578.png")}
                alt="phone"
                className="ms-2 fimg2"
              />
              <img
                src={require("../assets/Frame 42579.png")}
                alt="phone"
                className="ms-2 fimg2"
              />
            </div>
          </div>
        </div>
        <div
          className="mt-4"
          style={{ borderBottom: "0.7px solid white" }}
        ></div>
        <p className="text-center mt-4 last">
          Copyright © Cebiz Bank | All Rights Reserved{" "}
        </p>
      </footer>
    </>
  );
}

export default Footer;
