import React, { useEffect, useState, useContext } from "react";
import { GlobalState } from "../../Data/Context";
import { toast } from "react-toastify";
import { useParams, Link, useNavigate } from "react-router-dom";
import { DefaultHeader } from "../../Component";

// export let payrollType = [
//   { name: "Payment voucher", url: "/create-level" },
//   { name: "worker", url: "/worker" },
// ];

const SinglePayroll = () => {
  let param = useParams(),
    [name, setName] = useState("");
  const navigate = useNavigate();
  console.log(param);
  const { getPaymentClassWorkers, payroll } = useContext(GlobalState);
  const [percent, setPercent] = useState(0)
  const toggle = (id) => (e) => {
    console.log("toggled");
  };

  const payWorker = () => {
    if (percent <= 0) {
      toast.error("Choose percentage to pay workers");
      return
    } else if (percent > 100) {
      toast.error("Percentage value must be a maximum 0f 100");
      return
    }
    navigate("/payroll/payment-class-workers/make-payment", {
      state: { id: param.id, percent },
    });
  };

  useEffect(() => {
    const paymentClass = async () => {
      await getPaymentClassWorkers(param.id);
    };
    paymentClass();
    console.log(payroll);
  }, []);

  // useEffect(() => {
  //   payrollType.map(
  //     (item) => item.url.substring(1) === param.id && setName(item.name)
  //   );
  // }, [param.id]);

  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="container">
        <DefaultHeader text={"Pay Workers"}>
          <button
            className="btn btn-primary1 text-capitalize ms-auto px-5 py-2"
            onClick={payWorker}
          >
            Continue
          </button>
        </DefaultHeader>

        <div>
          <p>All class workers</p>

          <div className="form-floating tw-w-1/5 md:tw-ml-auto mb-4">
            <input
              className="form-control tw-w-1/5"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              value={`${percent}`}
              max={100}
              onChange={(e) => setPercent(e.target.value)}
            ></input>
            <label for="floatingTextarea">Percentage</label>
          </div>
          <div className="tw-overflow-x-auto tw-scrollbar-hide">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Active</th>
              </tr>
            </thead>
            <tbody>
              {payroll?.paymentClassWorkers.map((worker, idx) => (
                <tr>
                  <th scope="row">{idx + 1}</th>
                  <td>{worker.user.full_name}</td>
                  <td>{worker.user.last_name}</td>
                  <td>{worker.user.phone_number}</td>
                  <td>{worker.user.email}</td>
                  <td>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        defaultChecked={worker.enabled}
                        onClick={() => toggle(worker.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SinglePayroll;
