import React from "react";
import { DefaultHeader } from "../../../Component";
import moment from "moment";

const Payment = () => {
	return (
		<main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader text={"Add Payment"} />
				<div className="container">
					<form className="px-lg-5">
						<div className="mb-4">
							<p className="barFont text-muted">Staff to pay</p>
							<div className="row g-4">
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Full time workers"
										id="Full time workers"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Full time workers">
										Full time workers
									</label>
								</div>
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Contract workers"
										id="Contract workers"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Contract workers">
										Contract workers
									</label>
								</div>
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Retired Workers"
										id="Retired Workers"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Retired Workers">
										Retired Workers
									</label>
								</div>
							</div>
						</div>
						<div className="mb-4">
							<p className="barFont text-muted">Staff level to pay</p>
							<div className="row g-4">
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Inter Designer"
										id="Inter Designer"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Inter Designer">
										Inter Designer
									</label>
								</div>
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Junior level designer"
										id="Junior level designer"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Junior level designer">
										Junior level designer
									</label>
								</div>
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Retired Workers"
										id="Retired Workers"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Retired Workers">
										Retired Workers
									</label>
								</div>
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Inter Ui/Ux Designer"
										id="Inter Ui/Ux Designer"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Inter Ui/Ux Designer">
										Inter Ui/Ux Designer
									</label>
								</div>
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Ui/Ux Mid level designer"
										id="Ui/Ux Mid level designer"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Ui/Ux Mid level designer">
										Ui/Ux Mid level designer
									</label>
								</div>
								<div className="col-lg-3 d-flex align-items-center mb-3">
									<input
										type="checkbox"
										name="Retired Workers"
										id="Retired Workers"
										className="form-check form-check-input me-3 borderColor"
									/>
									<label className="barFont" htmlFor="Retired Workers">
										Retired Workers
									</label>
								</div>
							</div>
						</div>
						<div className="mb-4 row g-4">
							<div className="col-lg-6">
								<label
									htmlFor="Payment Date"
									className="text-uppercase barFont text-muted mb-2">
									Payment Date
								</label>
								<input
									type="date"
									className="form-control bg-transparent py-3 barFont"
									placeholder="Date"
									min={moment().format("YYYY-MM-DD")}
								/>
							</div>
							<div className="col-lg-6">
								<label
									htmlFor="Payment Percentage"
									className="text-uppercase barFont text-muted mb-2">
									Payment Percentage
								</label>
								<input
									type="text"
									className="form-control bg-transparent py-3 barFont"
									placeholder="Payment Percentage"
								/>
							</div>
						</div>
						<button className="w-50 d-block mx-auto text-capitalize btn btn-primary1 my-3">
							make payment
						</button>
					</form>
				</div>
			</div>
		</main>
	);
};

export default Payment;
