import { useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import Brand from "../brand/brand.component";
import { ArrowUpwardRounded } from "@mui/icons-material";

import routes from "../../routes";
import AppButton from "../button/button.component";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Slide } from "react-reveal";

const fixedRoute = ["/", "/contact", "/create-account", "/login", "/about"];

const AppNav = () => {
  const [nav, setNav] = useState(false);
  const [toTop, setToTop] = useState(false);

  window.onscroll = () => {
    window.pageYOffset > 200
      ? // console.log(pageYOffset)
        setNav(true)
      : setNav(false);

    window.pageYOffset > 4000
      ? // console.log(pageYOffset)
        setToTop(true)
      : setToTop(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  let [showNav, setShowNav] = useState(false);

  const handleNav = () => {
    setShowNav(!showNav);
  };

  const closeNav = () => {
    setShowNav(false);
  };

  const goToCReateAccount = () => {
    navigate("/create-account");
    setShowNav((showNav = !showNav));
  };
  return (
    <div>
      <div
        className={`${
          fixedRoute.includes(location.pathname) &&
          "tw-fixed tw-inset-x-0 tw-top-0 tw-z-50"
        }  ${
          location.pathname !== "/about" && location.pathname !== "/"
            ? nav && "tw-bg-white tw-shadow-xl"
            : nav && "tw-bg-mainShade"
        }`}
      >
        {/* Desktop */}
        <nav
          className={`tw-flex tw-justify-between tw-items-center tw-py-4 tw-container tw-px-8 md:tw-px-0 tw-mx-auto `}
        >
          <Brand
            height="tw-h-16"
            type={`${
              location.pathname !== "/about" && location.pathname !== "/"
                ? "dark"
                : "white"
            }`}
          />

          <div className="tw-hidden lg:tw-block">
            {routes.map((route, id) => (
              <Link
                to={route.link}
                className={`tw-mr-8 ${
                  location.pathname === "/about" || location.pathname === "/"
                    ? "tw-text-white"
                    : "tw-text-main"
                }`}
                key={id}
              >
                {route.name}
              </Link>
            ))}
          </div>
          <div className="tw-flex tw-mr-4 tw-items-center tw-gap-x-8">
            <div className="tw-hidden md:tw-inline-block">
              <Link
                to="/login"
                className={`${
                  location.pathname !== "/about" && location.pathname !== "/"
                    ? "tw-text-main"
                    : "tw-text-white"
                } tw-font-medium tw-mr-8`}
              >
                Sign in
              </Link>
              <AppButton
                children="create account"
                buttonType={`${
                  location.pathname !== "/about" && location.pathname !== "/"
                    ? "primary"
                    : "secondary"
                }`}
                onClick={goToCReateAccount}
              />
            </div>
            <div
              className="lg:tw-hidden absolute tw-right-8 tw-z-50"
              onClick={handleNav}
            >
              <button
                className={`${
                  location.pathname !== "/about" && location.pathname !== "/"
                    ? "tw-text-dark"
                    : "tw-text-white"
                } tw-p-4`}
              >
                <MenuRoundedIcon />
              </button>
            </div>
          </div>
        </nav>

        {/* mobile */}
        <Slide left when={showNav}>
          <div
            className={`tw-fixed tw-inset-0 tw-z-50 lg:tw-hidden tw-flex ${
              !showNav && "tw-hidden"
            }`}
          >
            <div className="tw-w-full tw-px-12 tw-py-10 tw-bg-mainShade tw-h-screen">
              <div className="tw-flex tw-justify-between tw-mb-8">
                <Brand height="tw-h-16" />
                <button className="tw-text-white tw-p-4" onClick={handleNav}>
                  <CloseRoundedIcon fontSize="large" />
                </button>
              </div>
              <ul className="tw-mt-12">
                {routes.map((route) => (
                  <li
                    className={`tw-mb-10 ${!showNav && "tw-hidden"}`}
                    key={route.name}
                  >
                    <Link
                      to={route.link}
                      className="tw-text-white tw-text-2xl tw-font-medium tw-text-decoration-none"
                      onClick={closeNav}
                    >
                      {route.name}
                    </Link>
                  </li>
                ))}
              </ul>
              {showNav && (
                <div className="md:tw-hidden">
                  <Link
                    to="/login"
                    className={`tw-text-white tw-font-medium tw-text-2xl tw-mb-8`}
                    onClick={() => setShowNav((showNav = !showNav))}
                  >
                    Sign in
                  </Link>
                  <div className="mt-4">
                    <AppButton
                      children="create account"
                      buttonType="secondary"
                      onClick={goToCReateAccount}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <div
              className={`bg-black w-2/5 h-screen opacity-25`}
              onClick={handleNav}
            ></div> */}
          </div>
        </Slide>
      </div>
      <div>
        <Outlet />
        {toTop && (
          <div
            className="tw-font-bold tw-text-2xl text-white tw-bg-main tw-h-16 tw-w-16 tw-rounded-full tw-flex tw-font-bold tw-items-center tw-justify-center tw-fixed tw-bottom-20 tw-right-10 tw-pointer-cursor tw-z-50"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <ArrowUpwardRounded fontSize="xl" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppNav;
