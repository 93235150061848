import React, { useEffect, useContext, useState } from "react";
import {
  DefaultHeader,
  Empty,
  ModalComponents,
  ModalCreateSavings,
} from "../../Component";
import { GlobalState } from "../../Data/Context";
import moment from "moment";
import "../../Styles/OrgAuth.css";
import { Buttons, Loader } from "../../Utils";
// import { toast } from "react-toastify";

export const getUniqueColor = () => {
  var p = "1234567890abcdef";
  return [...Array(6)].reduce((a) => a + p[~~(Math.random() * p.length)], "");
};

let colorArray = [
  "#3364CD",
  "#2EB66E",
  "rgba(250, 174, 59, 0.8)",
  "#F87474",
  "#33C4CD",
  "#7AB62E",
  "rgba(250, 59, 231, 0.8)",
  "#CD337D",
  "#115E35",
  "#010821",
  "#00160A",
];

const Savings = () => {
  const {
    nairaSign,
    numberWithCommas,
    savings,
    createSavings,
    updateSavings,
    deleteSavings,
    reloadSavings,
    // organisation,
    // getOrganizationInfo,
  } = useContext(GlobalState);
  let [stateSavings, setStateSavings] = useState(null),
    initalState = {
      savings_name: "",
      savings_description: "",
      amount: 1000,
      savings_method: "",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: "",
      duration: "",
    },
    [stateData, setStateData] = useState(initalState),
    [stateEdit, setStateEdit] = useState({ value: false, data: null }),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false);

  useEffect(() => {
    setStateSavings(savings.savings);
  }, [savings.savings]);

  useEffect(() => {
    if (stateEdit.value) {
      setStateData({
        ...stateEdit.data,
        duration: moment(stateEdit.data.end_date).diff(
          moment(stateEdit.data.start_date),
          "month"
        ),
        savings_method: stateEdit.data.savings_method.toLowerCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEdit.value]);

  let [isOpen, setIsOpen] = useState(false),
    [isOpen2, setIsOpen2] = useState(false);

  let toggle = () => {
    if (isOpen) {
      setStateData(initalState);
      setStateEdit({ value: false, data: null });
    }
    setIsOpen(!isOpen);
  };

  let toggle2 = () => {
    setIsOpen2(!isOpen2);
  };

  let handleSubmit = async (e) => {
    if (e) e.preventDefault();
    // let errArr = [];

    // if (!stateData.savings_name) errArr.push("Savings name required");
    // if (!stateData.amount) errArr.push("Savings amount required");
    // if (!stateData.savings_method) errArr.push("Savings method required");
    // if (!stateData.duration) errArr.push("Savings duration required");
    // if (!stateData.savings_description)
    // 	errArr.push("Savings description required");

    // if (errArr.length > 0) return errArr.forEach(item => toast.info(item));
    let data = {
      end_date: moment(stateData.start_date)
        .add(stateData.duration, "month")
        .format('YYYY-MM-DD'),
      savings_method: stateData.savings_method.toUpperCase(),
      savings_name: stateData.savings_name,
      savings_description: stateData.savings_description,
      amount: stateData.amount,
      start_date: stateData.start_date,
    //   id: stateData.id,
    };
    console.log(data);
    setLoading(true);
    if (stateEdit.value) {
      await updateSavings(data);
    } else {
      await createSavings(data);
    }
    setLoading(false);
    setSubmit(true);
  };

  let handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    await deleteSavings(stateEdit.data);
    setLoading(false);
    setSubmit(true);
  };

  let textChange = (name) => (e) => {
    setStateData({
      ...stateData,
      [name]: e.floatValue ? e.floatValue : e.target.value,
    });
  };

  useEffect(() => {
    if (submit && savings.isAdded) {
      toggle();
      setSubmit(false);
      reloadSavings();
      setStateData(initalState);
    }
    if (submit && savings.isUpdated) {
      toggle();
      reloadSavings();
      setSubmit(false);
      setStateData(initalState);
    }
    if (submit && savings.isDeleted) {
      toggle2();
      reloadSavings();
      setSubmit(false);
      setStateData(initalState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submit,
    savings.isAdded,
    savings.isUpdated,
    stateEdit.value,
    savings.isDeleted,
  ]);
//   useEffect(() => {
//     const getOrganisation = async () => {
//       await getOrganizationInfo();
//     };
//     getOrganisation();
//     console.log(organisation);
//   }, []);

  if (savings.loading) return <Loader />;
  if (!stateSavings) return <></>;

  let createButton = () => (
    <button
      onClick={toggle}
      className="btn donateHome3 text-capitalize btn-primary px-5"
    >
      create savings
    </button>
  );

  return (
    <main className="bg-light pb-4 main-height">
      <div className="container">
        <DefaultHeader text={"Save more today"} search>
          {createButton()}
        </DefaultHeader>

        {stateSavings.length === 0 ? (
          <Empty subtitle={`No savings type available`} />
        ) : (
          <section className="container savingsGrid my-3">
            {stateSavings.map((saving, index) => (
              <div
                key={index}
                className="d-flex flex-column rounded p-2 text-white myCursor"
                onClick={() => {
                  setStateEdit({ value: true, data: saving });
                  toggle2();
                }}
                style={{
                  background: `${colorArray[index % colorArray.length]}`,
                }}
              >
                <h5 className="text-capitalize genSansFont">
                  {saving?.savings_name}
                </h5>
                <div className="mt-auto">
                  <h6 className="montFont fontReduce">
                    {nairaSign}{" "}
                    {numberWithCommas(Number(saving?.amount).toFixed(2))}
                  </h6>
                  <small className="montFont fontReduce">
                    {moment(saving?.start_date).format("Do MM YYYY")} -{" "}
                    {moment(saving?.end_date).format("Do MM YYYY")}
                  </small>
                </div>
              </div>
            ))}
          </section>
        )}
        <div className="d-flex justify-content-center pt-4 d-lg-none">
          {createButton()}
        </div>
      </div>
      <ModalCreateSavings
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        back={toggle}
        stateData={stateData}
        textChange={textChange}
        stateEdit={stateEdit}
        loading={loading}
      />
      <ModalComponents isOpen={isOpen2} back={toggle2} title="Action">
        <div className="d-flex flex-column text-center">
          <p className="text-dark">
            Do you want to edit {stateEdit.value && stateEdit.data.savings_name}
            ?
          </p>
          <div className="btn-group mx-auto">
            <button
              onClick={() => {
                toggle2();
                toggle();
              }}
              className="btn btn-success text-uppercase"
            >
              yes
            </button>
            <button
              onClick={toggle2}
              className="btn btn-success btn-success2 text-uppercase"
            >
              no
            </button>
          </div>
          <hr />
          <p className="text-dark text-uppercase">OR</p>
          <hr />
          <p className="text-dark">
            Do you want to delete{" "}
            {stateEdit.value && stateEdit.data.savings_name}?
          </p>
          <div className="btn-group mx-auto">
            <Buttons
              title={"yes"}
              onClick={handleDelete}
              loading={loading}
              css="btn-danger text-uppercase"
              width={"mx-auto"}
            />
            <button
              onClick={toggle2}
              className="btn btn-primary text-uppercase"
            >
              no
            </button>
          </div>
        </div>
      </ModalComponents>
    </main>
  );
};

export default Savings;
