import React from "react";
import { DefaultHeader } from "../../../Component";

const SingleVoucher = () => {
	return (
		<main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader text={"Edit Payment Voucher"} />
				<div
					className="container d-flex justify-content-center"
					style={{ maxWidth: "600px" }}>
					<form className="border p-3 rounded borderColor borderRadius w-100">
						<section className="px-3 py-4">
							<div className="mb-3">
								<label
									htmlFor="Job title"
									className="text-uppercase barFont text-muted mb-2">
									Job title
								</label>
								<select
									name=""
									id=""
									type="text"
									className="form-control bg-transparent py-3 barFont form-select"
									placeholder="Job title">
									<option value="">Job title</option>
								</select>
							</div>
							<div className="mb-3">
								<label
									htmlFor="Level"
									className="text-uppercase barFont text-muted mb-2">
									Level
								</label>
								<select
									name=""
									id=""
									type="text"
									className="form-control bg-transparent py-3 barFont form-select"
									placeholder="Level">
									<option value="">Level</option>
								</select>
							</div>
							<div className="mb-3">
								<label
									htmlFor="Amount"
									className="text-uppercase barFont text-muted mb-2">
									Amount
								</label>
								<input
									type="number"
									className="form-control bg-transparent py-3 barFont"
									placeholder="20,000"
									min={0}
								/>
							</div>
							<div className="mb-3">
								<label
									htmlFor="benefit"
									className="text-uppercase barFont text-muted mb-2">
									benefit {"&"} extra
								</label>
								<input
									type="number"
									className="form-control bg-transparent py-3 barFont"
									placeholder="20,000"
									min={0}
								/>
							</div>
							<div className="mb-3">
								<label
									htmlFor="Interval"
									className="text-uppercase barFont text-muted mb-2">
									Interval
								</label>
								<select
									name=""
									id=""
									type="text"
									className="form-control bg-transparent py-3 barFont form-select"
									placeholder="Interval">
									<option value="">Interval</option>
								</select>
							</div>
						</section>
						<button className="px-5 d-block mx-auto text-capitalize btn btn-primary1 my-3">
							update
						</button>
					</form>
				</div>
			</div>{" "}
		</main>
	);
};

export default SingleVoucher;
