import React from "react";
import { DefaultHeader, ModalNotification } from "../../Component";
import { BiChat, BiBell, BiEnvelope } from "react-icons/bi";
import { FaFilter } from "react-icons/fa";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const Notification = () => {
	let notificationType = [
		{
			color: "#EEFFDD",
			name: "create SMS",
			icon: <BiChat size={26} />,
			primaryColor: "#2EB66E",
		},
		{
			color: "#DADAFF",
			name: "create email",
			icon: <BiEnvelope size={26} />,
			primaryColor: "#031A6E",
		},
		{
			color: "#FFE7E7",
			name: "create push",
			icon: <BiBell size={26} />,
			primaryColor: "#F87474",
		},
	];

	let notificationDetail = [
		{
			date: Date.now(),
			type: "push",
			title: "Bank Transfer Downtime",
		},
		{
			date: Date.now(),
			type: "email",
			title: "Bank Transfer Downtime",
		},
		{
			date: Date.now(),
			type: "sms",
			title: "Bank Transfer Downtime",
		},
		{
			date: Date.now(),
			type: "push",
			title: "Bank Transfer Downtime",
		},
		{
			date: Date.now(),
			type: "email",
			title: "Bank Transfer Downtime",
		},
		{
			date: Date.now(),
			type: "sms",
			title: "Bank Transfer Downtime",
		},
		{
			date: Date.now(),
			type: "push",
			title: "Bank Transfer Downtime",
		},
		{
			date: Date.now(),
			type: "email",
			title: "Bank Transfer Downtime",
		},
		{
			date: Date.now(),
			type: "sms",
			title: "Bank Transfer Downtime",
		},
	];

	let [isOpen, setIsOpen] = useState(false),
		[type, setType] = useState(""),
		[loading, setLoading] = useState(""),
		initialState = { message: "", title: "", notification_type: "", type },
		[stateData, setStateData] = useState(initialState);

	let toggle = () => {
		setIsOpen(!isOpen);
	};

	let handleSubmit = async e => {
		e.preventDefault();
		if (
			!stateData.notification_type ||
			!stateData.title ||
			!stateData.notification_type
		)
			return toast.info("Please input all fields");
		setLoading(true);
		try {
			let res = await axios.post(`/api/organisations/userNotification`, {
				title: stateData.title,
				message: stateData.message,
				// user_id: stateUsers?.id,
				notification_type: stateData.notification_type,
			});
			toast.success(res.data.message);
			setStateData(initialState);
			toggle();
		} catch (err) {
			console.log({ err });
			if (err) console.log(err.response?.data);
			let error = err.response?.data;
			if (error)
				toast.error(
					error.error && typeof error.error === "string"
						? error.error
						: error.message,
					{ autoClose: false }
				);
		}
		setLoading(false);
	};

	let textChange =
		name =>
		({ target: { value } }) => {
			setStateData({ ...stateData, [name]: value });
		};

	return (
		<main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader text={"Here is your Notification"} />
				<section className="container savingsGrid my-3 notifications">
					{notificationType.map((type, index) => (
						<div
							key={index}
							onClick={() => {
								setType(type.name);
								toggle();
							}}
							className="d-flex justify-content-center align-items-center text-center myCursor"
							style={{
								background: `${type.color}`,
							}}>
							<div className=" d-flex flex-column justify-content-center align-items-center">
								<div
									className="rounded-circle text-center d-flex justify-content-center align-items-center text-white fontReduce notifyIcon"
									style={{
										background: `${type.primaryColor}`,
									}}>
									{type.icon}
								</div>
								<h5 className="text-capitalize mt-3 barFont fontReduce">
									{type.name}
								</h5>
							</div>
						</div>
					))}
				</section>
				<div className="bg-white p-3">
					<button className="btn barFont text-capitalize px-md-4 m-0 border py-2">
						<FaFilter className="dashboardP fontReduce" /> filter
					</button>
				</div>
				<section className="container">
					<table className="">
						<thead className="text-uppercase text-muted fw-100">
							<tr>
								<th className="barFont fontReduce">date</th>
								<th className="barFont fontReduce">type</th>
								<th className="barFont fontReduce">title</th>
							</tr>
						</thead>
						<tbody>
							{notificationDetail.map((item, index) => (
								<tr key={index}>
									<td className="barFont fontReduce">
										{moment(item.date).format("MMM DD, YYYY")}
									</td>

									<td className="text-capitalize barFont fontReduce">
										{item.type}
									</td>
									<td className="barFont fontReduce">{item.title}</td>
								</tr>
							))}
						</tbody>
					</table>
				</section>
			</div>
			<ModalNotification
				back={toggle}
				type={type}
				isOpen={isOpen}
				handleSubmit={handleSubmit}
				textChange={textChange}
				loading={loading}
				stateData={stateData}
			/>
		</main>
	);
};

export default Notification;
