import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_ORGANISATION,
  ADD_ORGANISATION_FAIL,
  DELETE_ORGANISATION,
  DELETE_ORGANISATION_FAIL,
  GET_ORGANISATION,
  GET_ORGANISATION_FAIL,
  GET_ORGANISATION_LOADING,
  GET_USER_ORG,
  GET_USER_ORG_FAIL,
  GET_USER_ORG_LOADING,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_FAIL,
  GET_ORGANISATION_INFO,
  GET_ORGANISATION_INFO_LOADING,
  GET_ORGANISATION_INFO_FAIL,
} from "./ActionTypes";

export const getOrganisationUser = () => async (dispatch) => {
  dispatch({ type: GET_ORGANISATION_LOADING });
  try {
    let res = await axios.get(`/api/organisations/allUsers`);
    console.log(res);
    dispatch({
      type: GET_ORGANISATION,
      payload: res.data.data,
    });
  } catch (err) {
    if (err) console.log({ err });
    dispatch({ type: GET_ORGANISATION_FAIL });
  }
};

export const getSingleOrganisationUser = (thisData) => async (dispatch) => {
  dispatch({ type: GET_USER_ORG_LOADING });
  try {
    let res = await axios.get(`/api/organisations/userbyid/${thisData.id}`);

    dispatch({
      type: GET_USER_ORG,
      payload: res.data.data,
    });
  } catch (err) {
    if (err) console.log({ err });
    dispatch({ type: GET_USER_ORG_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const deleteOrganisationUser = (thisData) => async (dispatch) => {
  try {
    let res = await axios.post(
      `/api/organisations/deletesavings/${thisData.id}`
    );

    dispatch({
      type: DELETE_ORGANISATION,
      payload: thisData,
    });
    toast.success(res.data.message, {
      autoClose: 5000,
    });
    dispatch(getOrganisationUser());
  } catch (err) {
    console.log({ err });
    dispatch({ type: DELETE_ORGANISATION_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const updateOrganisationUser = (thisData) => async (dispatch) => {
  try {
    let res = await axios.post(`/api/organisations/freezeAccount`, {
      ...thisData,
    });

    dispatch({
      type: UPDATE_ORGANISATION,
    });

    toast.success(res.data.message, {
      autoClose: 5000,
    });
    dispatch(getOrganisationUser());
  } catch (err) {
    console.log({ err });
    dispatch({ type: UPDATE_ORGANISATION_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const createOrganisationUser = (thisData) => async (dispatch) => {
  try {
    let body = thisData;
    let res = await axios.post(`/api/organisations/createsavings`, body);

    dispatch({
      type: ADD_ORGANISATION,
      payload: res.data.data,
    });

    toast.success(res.data.message, {
      autoClose: 5000,
    });
  } catch (err) {
    console.log({ err });
    dispatch({ type: ADD_ORGANISATION_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};

export const getOrganizationInfo = () => async (dispatch) => {
  dispatch({ type: GET_ORGANISATION_INFO_LOADING });
  try {
    let res = await axios.get(`/api/organisations/organisation_info`);
	console.log(res.data.data);

    dispatch({
      type: GET_ORGANISATION_INFO,
      payload: res.data.data,
    });
  } catch (err) {
    console.log({ err });
    dispatch({ type: GET_ORGANISATION_INFO_FAIL });
    let error = err?.response?.data;
    if (error) {
      console.log({ error });
      toast.error(
        error.error && typeof error.error === "string"
          ? error.error
          : error.message
      );
    }
  }
};
