import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultHeader, ModalAddWorker, ModalSuccess } from "../../Component";
import WorkerClass from "../../Component/Payroll/WorkerClass";
import { GlobalState } from "../../Data/Context";
import colorArray from "../../colorArray";
import { ConfirmDeleteModal } from "../../Component/ModalComponents";

const Payroll = () => {
  const { getPaymentClass, getAllWorkers, payroll, updateWorker } =
    useContext(GlobalState);
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  const [paymentClassId, setpaymentClassId] = useState(null);
  const [dropDownOPen, setDropDownOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const options = [];
  payroll?.allWorkers.forEach((element) => {
    options.push({
      label: element.user.full_name + " " + element.user.last_name,
      value: element.id,
    });
  });
  const toggleOpen = () => {
    setDropDownOpen(!dropDownOPen);
  };

  const onSelectChange = (value) => {
    toggleOpen();
    setValue(value);
  };

  let toggle = () => {
    setIsOpen(!isOpen);
  };

  const setWorker = (classId) => {
    toggle();
    setpaymentClassId(classId);
  };

  const toggleDelete = () => {
    setDeleteModal(!deleteModal);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const details = {
      worker_id: value.value,
      payment_class_id: String(paymentClassId),
    };
    if (!details.worker_id || !details.payment_class_id) return;
    const res = await updateWorker(details);
    setLoading(false);
    if (res) {
      toggle();
      setSuccessModal(!successModal);
    }

    console.log(details);
    // toggle();
  };

  const createClass = () => {
    navigate("/payroll/create-payment-class");
  };

  const handleClick = (route) => {
    navigate(`/payroll/${route}`);
  };

  useEffect(() => {
    const paymentClass = async () => {
      await getPaymentClass();
      await getAllWorkers();
    };
    paymentClass();
    console.log(payroll);
  }, []);

  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="container">
        <DefaultHeader text={"Pay Workers"}>
          <button
            className="btn btn-primary1 text-capitalize ms-auto px-5 py-2"
            onClick={createClass}
          >
            add new
          </button>
        </DefaultHeader>
        <section className="container tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-8 my-3 notification">
          {payroll?.allPaymentClass.map((paymentClass, idx) => (
            <WorkerClass
              key={idx}
              addWorker={() => setWorker(paymentClass.id)}
              viewClass={() => handleClick(paymentClass.id)}
              paymentClassName={paymentClass.class_flow}
              color1={colorArray[idx].two}
              color2={colorArray[idx].one}
              deleteModal={toggleDelete}
            />
          ))}
        </section>
        <div className="tw-flex tw-mt-12 md:tw-hidden">
          <button
            className="btn btn-primary1 text-capitalize mx-auto px-5 py-2"
            onClick={createClass}
          >
            add new
          </button>
        </div>
      </div>
      <ModalAddWorker
        isOpen={isOpen}
        back={toggle}
        handleSubmit={handleSubmit}
        users={options}
        onSelectChange={onSelectChange}
        dropDownOpen={dropDownOPen}
        toggleOpen={toggleOpen}
        value={value}
        loading={loading}
      />
      <ModalSuccess
        isOpen={successModal}
        toggle={() => setSuccessModal(!successModal)}
        msg="Continue"
      />
      <ConfirmDeleteModal
        isOpen={deleteModal}
        toggle={toggleDelete}
        loading={loading}
      />
    </main>
  );
};

export default Payroll;
