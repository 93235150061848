import React, { useContext, useState, useEffect } from "react";
import { BiGroup, BiUserPlus } from "react-icons/bi";
import { FaPiggyBank, FaShare } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { DefaultHeader, Empty, NewChartFormat } from "../../Component";
import { GlobalState } from "../../Data/Context";
import { getUniqueColor } from "../savings";
import { CapitalizeFirst } from "../../Component/Sidebar";
import { Loader } from "../../Utils";

export let stateProducts = [
  { title: "Jan", Month: 10, color: `#${getUniqueColor()}` },
  { title: "Feb", Month: 20, color: `#${getUniqueColor()}` },
  { title: "Mar", Month: 30, color: `#${getUniqueColor()}` },
  { title: "Apr", Month: 20, color: `#${getUniqueColor()}` },
  { title: "May", Month: 10, color: `#${getUniqueColor()}` },
];

export const initialState = {
  chart: {
    id: "basic-line",
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: "smooth",
    width: 1.5,
    colors: "#031a6e",
  },
  xaxis: {
    categories: [],
    title: {
      text: "Month",
    },
  },
  yaxis: {
    title: {
      text: "Number of Users",
    },
  },
};

const colors = [
  {
    bg: "#DDF2D3",
    text: "#68BD41",
  },
  {
    bg: "#DFDEED",
    text: "#3D3895",
  },
  {
    bg: "#FDE6D0",
    text: "#E79F56",
  },
  {
    bg: "#F9EDFA",
    text: "#E756BE",
  },
];

export const month = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

const Dashboard = () => {
  const { numberWithCommas, auth } = useContext(GlobalState);
  const navigate = useNavigate();

  let [dashboardData, setDashboardData] = useState(null),
    [noOfUser, setNoOfUsers] = useState(null),
    [series, setSeries] = useState([
      {
        name: "Number of Users",
        data: [],
      },
    ]),
    [options, setOptions] = useState(initialState),
    [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    if (auth?.dashboard) {
      setDashboardData(auth.dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.dashboard]);

  useEffect(() => {
    if (dashboardData) {
      let data = [],
        data2 = [],
        data3 = [];
      for (let i = 0; i < month.length; i++) {
        data.push({
          title: CapitalizeFirst(month[i]),
          // eslint-disable-next-line no-eval
          Month: eval(`dashboardData?.no_of_users.${month[i]}`),
        });
        data2.push(CapitalizeFirst(month[i]));
        // eslint-disable-next-line no-eval
        data3.push(eval(`dashboardData?.no_of_users.${month[i]}`));
      }
      setNoOfUsers(data);
      setOptions({ ...options, xaxis: { categories: data2 } });
      setSeries([{ name: "Number of Users", data: data3 }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  useEffect(() => {
    if (noOfUser) {
      let total = noOfUser.reduce((acc, next) => (acc += next.Month), 0);
      setTotalUsers(total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfUser]);

  if (auth.dashboardLoading) return <Loader />;

  let dashTopic = [
    {
      icon: <BiUserPlus size={30} />,
      users: dashboardData?.total_user_balance
        ? dashboardData?.total_user_balance
        : 0,
      topic: "user wallet Value",
      link: "/wallet",
    },
    {
      icon: <BiGroup size={30} />,
      users: dashboardData?.activeuser ? dashboardData?.activeuser : 0,
      topic: "Pending user",
      link: "/customers",
    },
    {
      icon: <FaShare size={30} />,
      users: dashboardData?.pendinguser ? dashboardData?.pendinguser : 0,
      topic: "Active user",
      link: "/customers",
    },
    {
      icon: <FaPiggyBank size={30} />,
      users: dashboardData?.organisationSavings
        ? dashboardData?.organisationSavings
        : 0,
      topic: "SavingsType",
      link: "/savings",
    },
  ];
  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="tw-container mx-auto tw-px-4 md:tw-px-0">
        <DefaultHeader />
        {!dashboardData ? (
          <Empty subtitle={"No dashboard data yet"} />
        ) : (
          <>
            <section className="tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-gap-10 tw-px-6">
              {dashTopic.map((item, index) => (
                <div className="">
                  <div
                    // to={item.link}
                    onClick={() => navigate(`${item.link}`)}
                    className={`text-decoration-none tw-flex tw-gap-3 tw-cursor-pointer hover:tw-shadow-sm tw-p-4 tw-shadow-xl tw-items-center tw-rounded tw-bg-white tw-w-full`}
                    style={
                      {
                        // background: colors[index].bg,
                        // color: `${colors[index].text}`,
                      }
                    }
                    key={index}
                  >
                    <div
                      className="tw-h-12 tw-w-12 tw-flex align-items-center justify-content-center tw-rounded-full text-white"
                      style={{
                        background: "#031a6e",
                      }}
                    >
                      {item.icon}
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-1">
                      <h5 className="tw-text-xl tw-font-bold tw-capitalize">
                        {numberWithCommas(item.users)}
                      </h5>
                      <p
                        className="tw-italic tw-capitalize tw-text-sm tw-font-medium tw-whitespace-nowrap"
                        style={{
                          color: "black !important",
                        }}
                      >
                        {item.topic}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </section>
            <h4 className="px-lg-3 my-5 fw-bold">Overview</h4>
            <div className="container">
              <div className="row g-4 border">
                <NewChartFormat
                  options={options}
                  series={series}
                  type="line"
                  info={{
                    name: "Number Of User",
                    amount: totalUsers,
                    money: false,
                    user: totalUsers > 1 ? "users" : "user",
                  }}
                />
                <NewChartFormat
                  options={options}
                  series={[
                    { name: "Total Transactions", data: [10, 20, 30, 20, 10] },
                  ]}
                  type="line"
                  info={{
                    name: "Total Transaction",
                    amount: 76543234567,
                    money: true,
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default Dashboard;
