import React, { useContext, useState, useEffect } from "react";
// import { Wrapper } from "../../Component";
import {
  SecretPinAuthenticate,
  SetSecretPinAuthenticate,
  VerificationCode,
} from "../create-account/index";
import { GlobalState } from "../../Data/Context";
import { Buttons } from "../../Utils";
import AppInput from "../../components/input/input.component";
// import AppButton from "../../components/button/button.component";
import Footer from "../../components/footer/footer.component";

const Login = () => {
  const { loginUser, auth } = useContext(GlobalState);
  let [stateData, setStateData] = useState({
    email: "",
    password: "",
    user: "organisation",
  });
  let [loading, setLoading] = useState(false),
    [typePass, setTypePass] = useState(false),
    [isOpen, setIsOpen] = useState(false),
    toggle = () => {
      setIsOpen(!isOpen);
    },
    [isOpen2, setIsOpen2] = useState(false),
    toggle2 = () => {
      setIsOpen2(!isOpen2);
    },
    [isOpen3, setIsOpen3] = useState(false),
    toggle3 = () => {
      setIsOpen3(!isOpen3);
    };

  let textChange =
    (name) =>
    ({ target: { value } }) => {
      setStateData({ ...stateData, [name]: value });
    };

  let handleSubmit = async (e) => {
    console.log(stateData);
    e.preventDefault();
    setLoading(true);
    await loginUser(stateData);
    setLoading(false);
  };

  useEffect(() => {
    if (auth.firstStep) {
      toggle();
    } else if (auth.twoStep) {
      toggle2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.twoStep, auth.firstStep]);
  return (
    <div>
      <section
        style={{ background: "#FCFEFF" }}
        className="container tw-px-8 tw-mx-auto md:tw-px-0 tw-pt-32 tw-pb-20"
      >
        <p className="tw-font-extrabold tw-text-[#000] tw-capitalize tw-text-3xl">welcome back</p>
        <p className="tw-text-sm tw-text-[#000] tw-font-semibold tw-mt-4">
          Welcome! Please enter your details.
        </p>

        <div className="tw-grid tw-gap-x-24 md:tw-grid-cols-2">
          <div className="tw-mt-8 tw-order-2 md:tw-order-1">
            <form className="tw-space-y-6">
              <AppInput
                label="Email"
                placeholder="Enter your email"
                type="email"
                onChange={textChange("email")}
                name={stateData.email}
              />
              <AppInput
                label="Password"
                placeholder="******"
                type="text"
                onChange={textChange("password")}
                name={stateData.password}
                password
              />
              <div className="tw-flex tw-justify-between tw-mt-8 tw-max-w-md">
                <span>
                  <input type="checkbox" /> Remember for 30 days
                </span>
                <p>Forgot password</p>
              </div>
              <div className="tw-mt-8">
                {/* <AppButton buttonType="secondary" children="login" /> */}
                <Buttons
                  title={"sign in"}
                  css={"text-white mt-2"}
                  style={{
                    backgroundColor: "#031A6E",
                    padding: "10px 18px",
                  }}
                  loading={loading}
                  width
                  type="submit"
                  onClick={handleSubmit}
                />
              </div>
            </form>
          </div>
          <div className="tw-order-1 md:tw-order-2">
            <img src={require("../../assets/login.png")} alt="" />
          </div>
        </div>
      </section>
      <VerificationCode isOpen={isOpen} toggle={toggle} />
      <SecretPinAuthenticate
        isOpen={isOpen2}
        toggle={toggle2}
        toggle3={toggle3}
      />
      <SetSecretPinAuthenticate
        isOpen={isOpen3}
        toggle={toggle3}
        toggle2={toggle2}
      />
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Login;
