import React, { useContext, useState, useEffect } from "react";
import { BiArrowBack, BiSearch } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalState } from "../Data/Context";

const DefaultHeader = ({ text, search = false, notD = false, children }) => {
  const { auth } = useContext(GlobalState);
  let navigate = useNavigate(),
    param = useParams();
  let [searchText, setSearchText] = useState(""),
    [textInput, setTextInput] = useState("");

  useEffect(() => {
    if (auth?.isAuth) {
      setTextInput(
        `Hello ${auth?.user?.organisation_details?.organisation_name}👋🏽${
          text ? `, ${text}` : ""
        }`
      );
    } else {
      setTextInput("Hello Ifeoluwa 👋🏽,");
    }
  }, [auth?.isAuth, text, auth?.user?.organisation_details?.organisation_name]);

  return (
    <section className="d-lg-block d-none">
      <div className="d-flex" style={{ minHeight: "7rem" }}>
        <div className="d-flex align-items-center mb-3">
          {param.page === "dashboard" ? (
            <></>
          ) : (
            <BiArrowBack
              className="me-3 myCursor"
              onClick={() => navigate(-1)}
            />
          )}
          {!notD && (
            <p className="m-0 tw-pl-8 tw-font-bold tw-text-dark tw-text-3xl tw-whitespace-nowrap">
              {textInput}{" "}
            </p>
          )}
        </div>
        <header className="d-flex align-items-center my-auto justify-content-between w-100 container pb-4">
          {!notD && search && (
            <div className="input-group w-50 tw-ml-auto tw-mr-8">
              <div className="input-group-prepend">
                <div className="input-group-text bg-white py-3">
                  <BiSearch className="text-muted" />
                </div>
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className="form-control"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          )}{" "}
          {children}
        </header>
      </div>
    </section>
  );
};

export default DefaultHeader;
