import "./button.style.css";

const BUTTON_TYPE = {
  primary: "tw-bg-main tw-text-white",
  secondary: "tw-bg-secondary tw-text-main",
  light: "tw-bg-[#3A70FF] tw-text-white",
  white: "tw-bg-white tw-text-main"
};

const AppButton = ({ buttonType, children, ...otherProps }) => {
  return (
    <button
      className={`button tw-font-medium tw-capitalize tw-text-sm ${BUTTON_TYPE[buttonType]}`}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default AppButton;
