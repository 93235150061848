import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DefaultHeader } from "../../../../Component";
import { GlobalState } from "../../../../Data/Context";
import { toast } from "react-toastify";
import { ModalSuccess } from "../../../../Component";
import { Buttons } from "../../../../Utils";

const MakePayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { payWorkers, validateWorkers, getPaymentClassWorkers, payroll } =
    useContext(GlobalState);
  console.log(location);
  const [paymentForm, setPaymentForm] = useState({
    payment_name: "",
    payment_description: "",
    percentage: location.state.percent,
    payment_class_id: location.state.id,
  });
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workerType, setWorkerType] = useState("all");
  const handleChange = (e) => {
    const { name, value } = e.target;

    setPaymentForm({ ...paymentForm, [name]: value });
  };
  const toggle = () => {};
  const payClassWorkers = async (e) => {
    const successError =
      "Your Wallet Balance is currently insufficient to complete this transaction";
    e.preventDefault();
    setLoading(true);
    if (
      !paymentForm.payment_name ||
      !paymentForm.payment_description ||
      !paymentForm.percentage
    ) {
      setLoading(false);
      toast.error("Field cannot be empty");
      return;
    }
    const res = await payWorkers(paymentForm);
    if (res.data.message === successError) {
      setLoading(false);
      toast.error(successError);
      return;
    } else if (res) {
      setLoading(false);
      toggle();
      setSuccessModal(!successModal);
      return;
    }
  };

  useEffect(() => {
    const paymentGroup = async () => {
      await validateWorkers({
        percentage: location.state.percent,
        payment_class_id: location.state.id,
      });
      await getPaymentClassWorkers(location.state.id);
    };
    paymentGroup();
    console.log(payroll);
  }, []);

  if (!location.state.id) {
    return navigate("/payroll");
  }
  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="container">
        <form onSubmit={payClassWorkers}>
          <DefaultHeader text={"Pay Workers"}>
            <Buttons
              title="Make Payment"
              css={"btn btn-primary1 d-block ms-auto mt-4 px-5 text-capitalize"}
              width
              loading={loading}
              type="submit"
            />
          </DefaultHeader>

          <div>
            <div className="tw-grid tw-grid-cols-5 tw-gap-8 tw-mb-8">
              <div className="tw-col-span-2">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setWorkerType(e.target.value)}
                >
                  <option value="all">All Workers</option>
                  <option value="eligible_workers">Eligible Workers</option>
                  <option value="not_eligible_workers">
                    Non Eligible Workers
                  </option>
                </select>
              </div>
              <div className="tw-col-span-4">
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                    onChange={handleChange}
                    name="payment_name"
                    value={paymentForm.payment_name}
                  ></textarea>
                  <label for="floatingTextarea">Payment Name</label>
                </div>
              </div>
              <div className="tw-">
                <div className="form-floating">
                  <input
                    type="number"
                    max={100}
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                    onChange={handleChange}
                    name="percentage"
                    value={paymentForm.percentage}
                  ></input>
                  <label for="floatingTextarea">Percentage</label>
                </div>
              </div>
            </div>

            <div class="form-floating">
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                id="floatingTextarea2"
                style={{ height: "100px" }}
                onChange={handleChange}
                name="payment_description"
                value={paymentForm.payment_description}
              ></textarea>
              <label for="floatingTextarea2">Payment Description</label>
            </div>
          </div>
        </form>
        <div className="tw-overflow-x-auto tw-scrollbar-hide mt-5">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Wallet Id</th>
                <th scope="col">Email</th>
                <th scope="col">Active</th>
              </tr>
            </thead>
            <tbody>
              {workerType === "eligible_workers" &&
                payroll?.valdatedWorkers?.eligible_workers.map(
                  (worker, idx) => (
                    <tr key={idx}>
                      <th scope="row">{worker.user.id}</th>
                      <td>
                        {worker.user.full_name + " " + worker.user.last_name}
                      </td>
                      <td>{worker.user.user_wallet_id}</td>
                      <td>{worker.user.email}</td>
                      <td>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            defaultChecked={worker.enabled}
                            onClick={() => toggle(worker.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                )}
              {workerType === "not_eligible_workers" &&
                payroll?.valdatedWorkers?.not_eligible_workers.map(
                  (worker, idx) => (
                    <tr key={idx}>
                      <th scope="row">{worker.user.id}</th>
                      <td>
                        {worker.user.full_name + " " + worker.user.last_name}
                      </td>
                      <td>{worker.user.user_wallet_id}</td>
                      <td>{worker.user.email}</td>
                      <td>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            defaultChecked={worker.enabled}
                            onClick={() => toggle(worker.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                )}
              {workerType === "all" &&
                payroll?.paymentClassWorkers.map((worker, idx) => (
                  <tr key={idx}>
                    <th scope="row">{worker.user.id}</th>
                    <td>
                      {worker.user.full_name + " " + worker.user.last_name}
                    </td>
                    <td>{worker.user.user_wallet_id}</td>
                    <td>{worker.user.email}</td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          defaultChecked={worker.enabled}
                          onClick={() => toggle(worker.id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="tw-flex tw-gap-10 tw-justify-center">
          <p className="text-black fw-bold">
            Total Eligible Workers:{" "}
            {payroll?.valdatedWorkers?.total_eligible_worker}
          </p>
          <p className="text-black fw-bold">
            Total Amount: {payroll?.valdatedWorkers?.total_amount}
          </p>
        </div>
      </div>

      <ModalSuccess
        isOpen={successModal}
        toggle={() => setSuccessModal(!successModal)}
        msg="Continue"
      />
    </main>
  );
};

export default MakePayment;
