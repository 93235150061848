import React from "react";
import { DefaultHeader, Pagination, Transactions } from "../../Component";

const History = () => {
	return (
		<section className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader text={"Here is your Transaction History"} />
				<Transactions />
				<Pagination />
			</div>
		</section>
	);
};

export default History;
