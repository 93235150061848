import React from "react";
import empty from "../assets/empty.png";

const Empty = ({ subtitle }) => {
	return (
		<section className="d-flex justify-content-center align-items-center h-50">
			<div className="m-auto text-center py-4">
				<h4 className="barFont textColor fw-bold">Nothing to see here yet! </h4>
				<small className="barFont d-block textColor mb-3">
					{subtitle ? subtitle : `You haven't performed any transaction yet`}
				</small>
				<img src={empty} alt="empty" className="emptyData" />
			</div>
		</section>
	);
};

export default Empty;
