import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  DefaultHeader,
  ModalNewPaygroup,
  ModalSuccess,
} from "../../../Component";
import WorkerClass from "../../../Component/Payroll/WorkerClass";
import { GlobalState } from "../../../Data/Context";
import colorArray from "../../../colorArray";
import { ConfirmDeleteModal } from "../../../Component/ModalComponents";

const CreatePaymentClass = () => {
  const [inputValue, setInputValue] = useState({
    name: "",
  });
  const {
    createPaymentGroup,
    getPaymentGroup,
    deleteGroup,
    updateGroup,
    payroll,
  } = useContext(GlobalState);

  const [mode, setMode] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateEdit, setStateEdit] = useState(null);

  const toggleDelete = () => {
    setDeleteModal(!deleteModal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ [name]: value });
  };

  const createGroup = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!inputValue.name) {
      setLoading(false);
      toast.error("Field cannot be empty");
      return;
    }

    let res;

    if (mode === "Add New") {
      res = await createPaymentGroup(inputValue);
    } else {
      res = await updateGroup(stateEdit.id, inputValue);
    }
    setLoading(false);
    if (res) {
      toggle();
      setSuccessModal(!successModal);
      return;
    }
  };

  const toggle = (modeType) => {
    setIsOpen(!isOpen);
    setMode(modeType);

    // always clear when in add new mode
    if (mode === "Add New") {
      setInputValue({ name: "" });
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await deleteGroup(stateEdit.id);
    setLoading(false);
    toggleDelete();
    setSuccessModal(!successModal);
    // setSubmit(true);
  };

  useEffect(() => {
    const paymentGroup = async () => {
      await getPaymentGroup();
    };
    paymentGroup();
    console.log(payroll);
  }, [successModal]);

  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="container">
        <div className="tw-sticky tw-inset-y-0 tw-z-10">
          <DefaultHeader text={"Manage Payment Group"}>
            <button
              className="btn btn-primary1 text-capitalize ms-auto px-5 py-2"
              onClick={() => toggle("Add New")}
            >
              Add new
            </button>
          </DefaultHeader>
        </div>
        <section className="container tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-8 my-3 notification">
          {payroll?.paymentGroup?.map((group, idx) => (
            <WorkerClass
              paymentClassName={group.name}
              color1={colorArray[idx].two}
              color2={colorArray[idx].one}
              deleteModal={() => {
                toggleDelete();
                setStateEdit(group);
              }}
            />
          ))}
        </section>
        <div className="tw-flex tw-mt-12 md:tw-hidden">
          <button
            className="btn btn-primary1 text-capitalize mx-auto px-5 py-2"
            onClick={() => toggle("Add New")}
          >
            add new
          </button>
        </div>
      </div>
      <ModalNewPaygroup
        isOpen={isOpen}
        handleSubmit={createGroup}
        handleInput={handleChange}
        back={toggle}
        inputName="name"
        title="group"
        modeType={mode}
        value={inputValue.name}
        loading={loading}
      />
      <ModalSuccess
        isOpen={successModal}
        toggle={() => setSuccessModal(!successModal)}
        msg="Continue"
      />
      <ConfirmDeleteModal
        isOpen={deleteModal}
        toggle={toggleDelete}
        loading={loading}
        deleteItem={handleDelete}
        editItem={() => {
          setInputValue({ name: stateEdit.name });
          toggleDelete();
          toggle("Edit");
        }}
      />
    </main>
  );
};

export default CreatePaymentClass;
