import { useState } from "react";
import background from "../../assets/contact-bg.png";
import formBg from "../../assets/contact-form-bg.png";
import AppButton from "../../components/button/button.component";
import Footer from "../../components/footer/footer.component";
import AppInput from "../../components/input/input.component";
import { toast } from "react-toastify";

const defaultDetails = {
  fullName: "",
  email: "",
  phone: "",
  message: "",
};

const Contact = () => {
  const [formData, setFormData] = useState(defaultDetails);

  const { fullName, email, phone, message } = formData;

  const handleChange = (e) => {
    e.preventDefault();
    const [name, value] = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!fullName || !email || !phone || !message) {
      toast.error("All fields are required");
      return
    }
    console.log(formData);
  };
  return (
    <div>
      <section
        className="tw-pt-32 tw-pb-20 tw-px-8"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="tw-text-center">
          <p className="tw-text-4xl tw-font-extrabold tw-text-main">
            We've been waiting for you
          </p>
          <p className="tw-pt-10 tw-text-sm tw-text-gray">
            Let's hear from you!
          </p>
        </div>

        <div
          className="md:tw-px-12 tw-py-20"
          style={{
            background: `url(${formBg}) no-repeat center`,
            backgroundSize: "contain",
          }}
        >
          <form
            className="tw-max-w-4xl tw-mx-auto tw-grid tw-gap-8"
            onSubmit={submitForm}
          >
            <AppInput
              placeholder="Fullname"
              label="Full Name"
              onChange={handleChange}
              name={formData.fullName}
              // value={formData.fullName}
            />
            <AppInput
              placeholder="Email"
              label="Email"
              type="email"
              onChange={handleChange}
              name={formData.email}
              // value={formData.email}
            />
            <AppInput
              placeholder="Phone"
              label="Phone"
              onChange={handleChange}
              name={formData.phone}
              // value={formData.phone}
            />
            <AppInput
              placeholder="Message"
              label="Your Message"
              inputType="textArea"
              onChange={handleChange}
              name={formData.message}
              // value={formData.message}
            />
            <div className="tw-flex  tw-mt-8">
              <AppButton children="send message" buttonType="light" />
            </div>
          </form>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Contact;
