import {
	ADD_SAVING,
	ADD_SAVING_FAIL,
	DELETE_SAVING,
	DELETE_SAVING_FAIL,
	GET_SAVING,
	GET_SAVING_FAIL,
	GET_SAVING_LOADING,
	LOGOUT,
	RELOAD_SAVINGS,
	UPDATE_SAVING,
	UPDATE_SAVING_FAIL,
} from "../Actions/ActionTypes";

const initialState = {
	savings: [],
	isAdded: false,
	isDeleted: false,
	isUpdated: false,
	loading: false,
};
const SavingsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_SAVING:
			return {
				...state,
				savings: payload,
				loading: false,
			};
		case GET_SAVING_LOADING:
			return {
				...state,
				loading: true,
			};
		case GET_SAVING_FAIL:
			return {
				...state,
				loading: false,
			};
		case ADD_SAVING:
			return {
				...state,
				isAdded: true,
				savings: [{ ...payload }, ...state.savings],
			};
		case ADD_SAVING_FAIL:
			return {
				...state,
				isAdded: false,
			};
		case UPDATE_SAVING:
			return {
				...state,
				isUpdated: true,
			};
		case UPDATE_SAVING_FAIL:
			return {
				...state,
				isUpdated: false,
			};
		case DELETE_SAVING:
			return {
				...state,
				isDeleted: true,
				savings: DeleteData(state.savings, payload),
			};
		case DELETE_SAVING_FAIL:
			return {
				...state,
				isDeleted: false,
			};
		case LOGOUT:
			return {
				...state,
				savings: [],
				isAdded: false,
				isDeleted: false,
				isUpdated: false,
				loading: false,
			};
		case RELOAD_SAVINGS:
			return { ...state, isAdded: false, isUpdated: false, isDeleted: false };
		default:
			return state;
	}
};

export default SavingsReducer;

export const EditData = (data, payload) => {
	let updatateData = data.map(item =>
		item.id !== payload.id ? item : payload
	);
	return updatateData;
};

export const DeleteData = (data, payload) => {
	let filterItem = [...data.filter(item => item.id !== payload.id)];
	return filterItem;
};
