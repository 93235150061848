import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalState } from "../Data/Context";
import { BiLogIn } from "react-icons/bi";
import logo from "../assets/logo3.png";
import "../Styles/Sidebar.css";
import "../Styles/OrgAuth.css";
import { ModalComponents } from ".";
import { FaTimes } from "react-icons/fa";
import { Buttons } from "../Utils";
import { MdPayments } from "react-icons/md";
import { IconContext } from "react-icons";
import { AiOutlineMenu } from "react-icons/ai";

export let CapitalizeFirst = (text) => {
  return text.replace(/\b\w/g, (m) => {
    return m.toUpperCase();
  });
};

const Sidebar = () => {
  const { headerFiles, logout } = useContext(GlobalState);
  let location = useLocation(),
    navigate = useNavigate(),
    [isOpen, setIsOpen] = useState(false),
    [loading, setLoading] = useState(false),
    // [shouldOpen, setShouldOpen] = useState(false),
    toggle = () => {
      setIsOpen(!isOpen);
    };
  const [isOpen2, setIsOpen2] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  let toggle2 = () => {
    setSubMenu(false);
    setIsOpen2(!isOpen2);
  };
  console.log(headerFiles);

  // useEffect(() => {
  //   setShouldOpen(
  //     document.body.clientWidth < 992 &&
  //       document?.getElementById("sidebarSmall")?.classList?.contains("visible")
  //   );
  // }, [isOpen2]);

  // useEffect(() => {
  //   if (shouldOpen)
  //     if (isOpen2) {
  //       document.body.style.overflow = "hidden";
  //     }
  //   return () => {
  //     document.body.style.overflow = "unset";
  //   };
  // }, [isOpen2, shouldOpen]);

  let menuList = (item, index) => (
    <li
      className={`${
        location.pathname.includes(item.url) ? "headerActive" : ""
      }`}
      key={index}
    >
      {item.type === "button" ? (
        <div className="tw-relative py-2" onClick={() => setSubMenu(!subMenu)}>
          <div className="text-capitalize genSansFont myCursor d-block h-100 d-flex">
            <div className="my-auto tw-flex tw-items-center">
              <span className="mx-3">{item.icon}</span>
              <span>{item.name}</span>
              <span className="mx-3">{item.icon2}</span>
            </div>
          </div>
          {subMenu && (
            <div className="tw-p-2 tw-w-full tw-bg-transparemt tw-space-y-2">
              {item.subUrl.map((url) => (
                <div
                  data-aos="fade-down"
                  data-aos-duration="500"
                  className="tw-pl-10 "
                >
                  <Link
                    to={url.url}
                    className="tw-text-white tw-whitespace-nowrap tw-text-sm"
                    onClick={toggle2}
                  >
                    {url.name}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Link
          className="text-capitalize d-flex genSansFont py-2"
          onClick={toggle2}
          to={item.url}
        >
          <span className="mx-3">{item.icon}</span>
          {item.name}
        </Link>
      )}
    </li>
  );

  let handleLogOut = async (e) => {
    e.preventDefault();
    setLoading(true);
    await logout();
    setLoading(false);
    navigate("/");
  };

  useEffect(() => {
    document.title = CapitalizeFirst(
      `Cebiz Bank Organization ${location.pathname
        .split("/")
        .join(" ")
        .substring(1)}`
    );
  }, [location.pathname]);

  return (
    <aside id="header" className="mainHeader sticky-top">
      <nav className="sidebar-menu bg-dark1">
        <div className="d-flex justify-content-lg-center justify-content-between mx-3 mx-lg-0 align-items-center">
          <Link to={"/"}>
            <img src={logo} alt="Cebiz" className="lg:tw-h-24 tw-h-16" />
          </Link>
          {isOpen2 ? (
            <FaTimes
              color="black"
              onClick={toggle2}
              className="navbar-close rounded tw-border-none d-lg-none"
            />
          ) : (
            <div
              className=" tw-border-none ms-auto d-lg-none tw-h-10"
              // type="button"
              onClick={toggle2}
              data-bs-toggle="collapse"
              data-bs-target="#header-nav"
            >
              <IconContext.Provider value={{ color: "black" }}>
                <AiOutlineMenu size={30} />
              </IconContext.Provider>
            </div>
          )}
        </div>
        <ul
          id="sidebarSmall"
          className={`navbar-nav sidebarSmall ${isOpen2 ? "visible" : ""}`}
        >
          {headerFiles.map((item, index) => menuList(item, index))}
          <li>
            <Buttons
              loading={loading}
              loadCss="textColor"
              title={"logout"}
              onClick={handleLogOut}
              width="mx-auto"
              css="btn-white text-capitalize genSansFont"
            >
              <BiLogIn size={24} className="mx-3" />
            </Buttons>
          </li>
        </ul>
      </nav>
      <ModalComponents isOpen={isOpen} title="payroll">
        <PayrollModal toggle={toggle} />
      </ModalComponents>
    </aside>
  );
};

export default Sidebar;

let PayrollModal = ({ toggle, toggle2 }) => {
  let navigate = useNavigate();
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="my-5">
        <p>Did you want to create a payroll System</p>
      </div>
      <div className="mb-5">
        <button
          onClick={() => {
            toggle();
            if (toggle2) toggle2();
            navigate("/payroll");
          }}
          className="btn btn-primary1 text-capitalize mx-2 px-4"
        >
          Yes
        </button>
        <button
          onClick={() => {
            toggle();
            if (toggle2) toggle2();
          }}
          className="btn btn-primary1 text-capitalize mx-2 px-4"
        >
          no
        </button>
      </div>
    </div>
  );
};
