const SquareIcon = ({ icon, link }) => {
  return (
    <a href={link}>
      <span className="tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-[#F2F1FF] tw-text-main">
        {icon}
      </span>
    </a>
  );
};

export default SquareIcon;
