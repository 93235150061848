import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { GlobalState } from "../Data/Context";
import { CustomSearchBar } from "./Transactions";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import Empty from "./Empty";

let UserCustomers = () => {
  const { organisation } = useContext(GlobalState);

  let [stateUsers, setStateUsers] = useState(null);

  useEffect(() => {
    setStateUsers(organisation.allUsers);
  }, [organisation.allUsers]);

  if (!stateUsers) return <></>;

  return (
    <>
      {stateUsers.length > 0 ? (
        <>
          <section className="">
            <CustomSearchBar />
          </section>
          <section className="container bg-light">
            <table className="">
              <thead className="text-uppercase">
                <tr className="transactionGrid">
                  <th className="tw-text-sm tw-font-bold tw-text-[#1b1b1b]">
                    name
                  </th>
                  <th className="tw-text-sm tw-font-bold tw-text-[#1b1b1b] d-none d-lg-block">
                    phone
                  </th>
                  <th className="tw-text-sm tw-font-bold tw-text-[#1b1b1b]">
                    email
                  </th>
                  <th className="tw-text-sm tw-font-bold tw-text-[#1b1b1b] d-none d-lg-block">
                    sign-up date
                  </th>
                  <th className="tw-text-sm tw-font-bold tw-text-[#1b1b1b]">
                    account status
                  </th>
                </tr>
              </thead>
              <tbody>
                {stateUsers.slice(0, 10).map((item, index) => (
                  <tr key={index} className="transactionGrid">
                    <td className=" fontReduce">
                      <Link
                        className="text-capitalize text-decoration-none tw-text-sm text-dark"
                        to={`/customers/${item.id}`}
                      >
                        {item.full_name}
                      </Link>
                    </td>
                    <td className="tw-text-sm d-none d-lg-block">
                      {item.phone_number}
                    </td>
                    <td className=" fontReduce">{item.email}</td>
                    <td className=" fontReduce d-none d-lg-block">
                      {moment(item.created_at).format("L - hh:mma")}
                    </td>
                    <td className=" fontReduce">
                      <button
                        type="button"
                        className={`btn text-capitalize fontReduce`}
                      >
                        {item.account_status}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          <Pagination />
        </>
      ) : (
        <Empty subtitle={`No user available`} />
      )}
    </>
  );
};

export default UserCustomers;
