import React, { useContext, useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  DefaultHeader,
  ModalConfirm,
  ModalOtpPin,
  ModalSendMoney,
  Transactions,
} from "../../Component";
import { GlobalState } from "../../Data/Context";
import "../../Styles/OrgAuth.css";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";
import vector from "../../assets/Vector01.png";

const Wallet = () => {
  const { nairaSign, numberWithCommas, beneficiary, auth } =
    useContext(GlobalState);
  console.log(auth);

  let [visible, setVisible] = useState(false),
    [visible2, setVisible2] = useState(false),
    [isOpen, setIsOpen] = useState(false),
    [isOpen2, setIsOpen2] = useState(false),
    [isOpen3, setIsOpen3] = useState(false),
    [orgDetails, setOrgDetails] = useState(null),
    [orgWallet, setOrgWallet] = useState(null),
    [orgSavings, setOrgSavings] = useState(null);

  useEffect(() => {
    if (auth?.user?.organisation_details)
      setOrgDetails(auth?.user?.organisation_details);

    if (auth?.user?.total_savings) setOrgSavings(auth?.user?.total_savings);
    if (auth?.user?.organisation_wallet_history)
      setOrgWallet(auth?.user?.organisation_wallet_history);
  }, [auth?.user]);

  let toggle = () => {
    setIsOpen(!isOpen);
  };
  let toggle2 = () => {
    setIsOpen2(!isOpen2);
  };
  let toggle3 = () => {
    setIsOpen3(!isOpen3);
  };

  let handleSubmitSend = (e) => {
    e.preventDefault();
    toggle();
    toggle2();
  };
  let handleSubmitConfirm = (e) => {
    e.preventDefault();
    toggle2();
    toggle3();
  };
  let handleSubmitOtp = (e) => {
    e.preventDefault();
    toggle3();
  };
  let handleBackConfirm = () => {
    toggle2();
    toggle();
  };
  let handleBackPin = () => {
    toggle3();
    toggle2();
  };

  if (!orgDetails || !orgWallet) return <></>;

  return (
    <main className="pb-3 pt-3 pt-lg-0 main-height">
      <div className="container">
        <DefaultHeader text={"Welcome back to your Wallet"} />
        <section className="tw-grid md:tw-grid-cols-2 tw-gap-8">
          <div className="donateHome44 rounded">
            <div
              className="donateHome4 tw- tw-inset-0 tw- p-4 rounded d-flex justify-content-center text-white "
              style={{ backgroundImage: `url(${vector})` }}
            >
              {" "}
              <div className="text-white genSansFont tw-space-y-3 lg:tw-space-y-0">
                <p className="text-white genSansFont tw-capitalize tw-text-3xl tw-font-bold">
                  organisation's wallet
                </p>
                <p className="text-white fw-500 d-flex align-items-center tw-gap-4">
                  <span>Bank: Cebiz Bank </span>
                  <BiCopy
                    className="myCursor text-white"
                    size={20}
                    onClick={() => {
                      navigator.clipboard.writeText("Cebiz Bank").then(
                        () => {
                          toast.info("Copied");
                        },
                        (err) => {
                          toast.warn(`Could not copy:4 ${err}`);
                        }
                      );
                    }}
                  />{" "}
                </p>
                <p className="text-white genSansFont fw-500">
                  Account Name: {orgDetails?.organisation_name}
                </p>
                <p className="text-white genSansFont fw-500">
                  Account number: {orgDetails?.organisation_account}
                </p>
                <p className="text-white genSansFont fw-500 d-flex align-items-center tw-gap-4 tw-text-2xl">
                  <span>
                    {nairaSign}
                    {visible
                      ? numberWithCommas(
                          Number(
                            orgDetails?.organisation_wallet_balance
                          ).toFixed(2)
                        )
                      : "**************"}
                  </span>
                  <span onClick={() => setVisible(!visible)}>
                    {!visible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </p>
                <div className="btn-group">
                  <button
                    onClick={toggle}
                    className="btn btn-white text-capitalize"
                  >
                    send money
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="donateHome4 rounded">
            <div
              className="p-4 rounded d-flex justify-content-center text-center text-white "
              style={{ backgroundImage: `url(${vector})` }}
            >
              <div className="text-white genSansFont">
                <p className="text-white genSansFont tw-capitalize tw-text-3xl tw-font-bold">
                  savings wallet
                </p>
                <h1 className="text-white genSansFont fw-500 d-flex align-items-center tw-gap-4 tw-text-2xl">
                  <span>
                    {nairaSign}
                    {visible2
                      ? numberWithCommas(Number(orgSavings).toFixed(2))
                      : "**************"}
                  </span>
                  <span onClick={() => setVisible2(!visible2)}>
                    {!visible2 ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </h1>
                <div>
                  <img
                    src={require("../../assets/savings-img.png")}
                    alt=""
                    className="mx-auto mt-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Transactions data={orgWallet} />
        {orgWallet && orgWallet.length > 0 && (
          <div className="d-flex my-3">
            <button className="btn donateHome3 text-uppercase btn-primary px-5 mx-auto">
              load more
            </button>
          </div>
        )}
      </div>
      <ModalSendMoney
        isOpen={isOpen}
        toggle={toggle}
        beneficiary={beneficiary}
        handleSubmit={handleSubmitSend}
      />
      <ModalConfirm
        handleSubmit={handleSubmitConfirm}
        isOpen={isOpen2}
        toggle={toggle2}
        back={handleBackConfirm}
      />
      <ModalOtpPin
        handleSubmit={handleSubmitOtp}
        isOpen={isOpen3}
        toggle={toggle3}
        back={handleBackPin}
      />
    </main>
  );
};

export default Wallet;
