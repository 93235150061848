import React from "react";
import moment from "moment";

const UserProfile = () => {
	return (
		<div className="px-lg-5">
			<form className="border p-3 rounded borderColor borderRadius">
				<section className="row g-5 px-3 py-4">
					<div className="col-lg-6">
						<label
							htmlFor="Name"
							className="text-uppercase barFont text-muted mb-2">
							Fullname
						</label>
						<input
							type="text"
							className="form-control bg-transparent py-3 barFont"
							placeholder="Fullname"
						/>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Bank Name"
							className="text-uppercase barFont text-muted mb-2">
							Bank Name
						</label>
						<select
							name=""
							id=""
							type="text"
							className="form-control bg-transparent py-3 barFont form-select"
							placeholder="Bank Name">
							<option value="">Bank Name</option>
						</select>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Job title"
							className="text-uppercase barFont text-muted mb-2">
							Job title
						</label>
						<select
							name=""
							id=""
							type="text"
							className="form-control bg-transparent py-3 barFont form-select"
							placeholder="Job title">
							<option value="">Job title</option>
						</select>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Payment Date"
							className="text-uppercase barFont text-muted mb-2">
							Payment Date
						</label>
						<input
							type="date"
							className="form-control bg-transparent py-3 barFont"
							placeholder="Date"
							min={moment().format("YYYY-MM-DD")}
						/>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Level"
							className="text-uppercase barFont text-muted mb-2">
							Level
						</label>
						<select
							name=""
							id=""
							type="text"
							className="form-control bg-transparent py-3 barFont form-select"
							placeholder="Level">
							<option value="">Level</option>
						</select>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Amount"
							className="text-uppercase barFont text-muted mb-2">
							Amount
						</label>
						<input
							type="number"
							className="form-control bg-transparent py-3 barFont"
							placeholder="20,000"
							min={0}
						/>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Start Date"
							className="text-uppercase barFont text-muted mb-2">
							Start Date
						</label>
						<input
							type="date"
							className="form-control bg-transparent py-3 barFont"
							placeholder="Start Date"
							min={moment().format("YYYY-MM-DD")}
						/>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Interval"
							className="text-uppercase barFont text-muted mb-2">
							Interval
						</label>
						<select
							name=""
							id=""
							type="text"
							className="form-control bg-transparent py-3 barFont form-select"
							placeholder="Interval">
							<option value="">Interval</option>
						</select>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Account name"
							className="text-uppercase barFont text-muted mb-2">
							Account name
						</label>
						<input
							type="text"
							className="form-control bg-transparent py-3 barFont"
							placeholder="Account name"
						/>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Month"
							className="text-uppercase barFont text-muted mb-2">
							Month
						</label>
						<input
							type="month"
							className="form-control bg-transparent py-3 barFont"
							placeholder="Month"
						/>
					</div>
					<div className="col-lg-6">
						<label
							htmlFor="Account number"
							className="text-uppercase barFont text-muted mb-2">
							Account number
						</label>
						<input
							type="number"
							className="form-control bg-transparent py-3 barFont"
							placeholder="Account number"
							min={0}
						/>
					</div>
				</section>
				<button className="w-50 d-block mx-auto text-capitalize btn btn-primary1 my-3">
					update
				</button>
			</form>
		</div>
	);
};

export default UserProfile;
