// Root reducer to combine all reducers in the app

import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import SavingsReducer from "./SavingReducer";
import OrganisationReducer from "./OrganisationReducer";
import { payrollReducer } from "./PayrollReducer";

export default combineReducers({
	auth: AuthReducer,
	savings: SavingsReducer,
	organisation: OrganisationReducer,
	payroll: payrollReducer
});
