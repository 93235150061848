import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../Data/Context";
import { BiLogIn } from "react-icons/bi";

const Home = () => {
	const { logout } = useContext(GlobalState),
		navigate = useNavigate();

	let handleLogOut = async () => {
		await logout();
		navigate("/");
	};
	return (
		<div>
			<h1 className="text-center">Home</h1>
			<button
				className="btn btn-white text-capitalize genSansFont"
				onClick={handleLogOut}>
				<BiLogIn size={24} className="mx-3" />
				logout
			</button>
		</div>
	);
};

export default Home;
