import React from "react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";

const Pagination = () => {
  return (
    <div className="d-md-flex justify-content-between align-items-center px-3 fontReduce">
      <div className="tw-flex tw-items-center tw-gap-4 mb-4 mb-md-0">
        ROWS PER PAGE : 10 <FaAngleDown />
      </div>
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-8">
        <span className="tw-flex tw-items-center tw-gap-4">
		<FaAngleDoubleLeft />PAGE 01/20 <FaAngleDoubleRight />
        </span>
        <span className="tw-flex tw-items-center tw-gap-4">
          <FaAngleLeft /> 01 / 20 <FaAngleRight /> 
        </span>
      </div>
    </div>
  );
};

export default Pagination;
