import React from "react";
import { useContext } from "react";
import { GlobalState } from "../Data/Context";
import "../Styles/Error.css";
import Navbar from "./Navbar";
function Error() {
	const { auth } = useContext(GlobalState);
	return (
		<>
			{!auth.isAuth && <Navbar />}
			<div className="container">
				<div className="row co">
					<div className="col-12 col-md-6">
						<p>404 Error</p>
						<h2>Under maintenance</h2>
						<p>
							Sorry, the page you are looking for doesn't exist or <br />{" "}
							has been moved. Try searching our site:
						</p>
					</div>
					<div className="col-12 col-md-6">
						<img src={require("../assets/404.png")} alt="" />
					</div>
				</div>
			</div>
		</>
	);
}

export default Error;
