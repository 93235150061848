const AddItem = ({
  handleSubmit,
  handleInput,
  inputName,
  inputValue,
  title,
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center tw-h-screen">
      <div className="tw-max-w-lg tw-border tw-p-8 tw-rounded-md">
        <form>
          <div class="form-floating mb-3">
            <input
              type="email"
              class="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              onChange={handleInput}
              name={inputName}
              value={inputValue}
            />
            <label for="floatingInput">{title} title</label>
          </div>
        </form>
        <button
          onClick={handleSubmit}
          className="w-75 d-block mx-auto text-capitalize btn btn-primary1 mt-4 py-2"
        >
          next
        </button>
      </div>
    </div>
  );
};

export default AddItem;
