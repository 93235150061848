import { DefaultHeader } from "../../../Component";

const PayWorker = () => {
    const toggle = () => {}
    return (
      <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
        <div className="container">
          <DefaultHeader text={"Create Payment Group"}>
            <button
              className="btn btn-primary1 text-capitalize ms-auto px-5 py-2"
              onClick={toggle}
            >
              add new
            </button>
          </DefaultHeader>
        </div>
        
      </main>
    );
};

export default PayWorker;
