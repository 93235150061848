import {
  GET_PAYMENT_CLASS,
  GET_PAYMENT_CLASS_FAIL,
  GET_PAYMENT_CLASS_LOADING,
  CREATE_PAYMENT_CLASS,
  CREATE_PAYMENT_CLASS_FAIL,
  CREATE_PAYMENT_CLASS_LOADING,
  CREATE_GROUP,
  CREATE_GROUP_LOADING,
  CREATE_GROUP_FAILED,
  CREATE_LEVEL,
  CREATE_LEVEL_FAILED,
  CREATE_LEVEL_LOADING,
  CREATE_ROLE,
  CREATE_ROLE_LOADING,
  CREATE_ROLE_FAILED,
  GET_GROUP,
  GET_GROUP_LOADING,
  GET_GROUP_FAILED,
  GET_LEVEL,
  GET_LEVEL_LOADING,
  GET_LEVEL_FAILED,
  GET_ROLE,
  GET_ROLE_LOADING,
  GET_ROLE_FAILED,
  GET_WORKER,
  GET_WORKER_LOADING,
  GET_WORKER_FAILED,
  UPDATE_WORKER,
  UPDATE_WORKER_LOADING,
  UPDATE_WORKER_FAILED,
  ADD_WORKER,
  ADD_WORKER_LOADING,
  ADD_WORKER_FAILED,
  GET_CLASS_WORKER,
  GET_CLASS_WORKER_FAILED,
  GET_CLASS_WORKER_LOADING,
  PAY_WORKERS,
  PAY_WORKERS_LOADING,
  PAY_WORKERS_FAILED,
  VALIDATE_WORKERS,
  VALIDATE_WORKERS_LOADING,
  VALIDATE_WORKERS_FAILED,
  DELETE_GROUP,
  DELETE_GROUP_FAIL,
  DELETE_LEVEL,
  DELETE_LEVEL_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_FAIL,
  UPDATE_LEVEL,
  UPDATE_LEVEL_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
} from "../Actions/ActionTypes";
import { DeleteData } from "./SavingReducer";

const initialState = {
  allPaymentClass: [],
  paymentClassWorkers: [],
  paymentGroup: [],
  paymentLevel: [],
  paymentRole: [],
  allWorkers: [],
  valdatedWorkers: [],
  isLoading: false,
  isDeleted: false,
  isUpdaed: false,
};

export const payrollReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PAYMENT_CLASS:
      return {
        ...state,
        allPaymentClass: payload,
        isLoading: false,
      };

    case GET_PAYMENT_CLASS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case GET_PAYMENT_CLASS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_PAYMENT_CLASS:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_PAYMENT_CLASS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_PAYMENT_CLASS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_GROUP:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_GROUP_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_LEVEL:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_LEVEL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_LEVEL_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_ROLE:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_ROLE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_ROLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case GET_GROUP:
      return {
        ...state,
        paymentGroup: payload,
        isLoading: false,
      };
    case GET_GROUP_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_LEVEL:
      return {
        ...state,
        paymentLevel: payload,
        isLoading: false,
      };
    case GET_LEVEL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LEVEL_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_WORKER:
      return {
        ...state,
        allWorkers: payload,
        isLoading: false,
      };
    case GET_WORKER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_WORKER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_ROLE:
      return {
        ...state,
        paymentRole: payload,
        isLoading: false,
      };
    case GET_ROLE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ROLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_WORKER:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_WORKER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_WORKER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_WORKER:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_WORKER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_WORKER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case PAY_WORKERS:
      return {
        ...state,
        isLoading: false,
      };
    case PAY_WORKERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PAY_WORKERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case VALIDATE_WORKERS:
      return {
        ...state,
        isLoading: false,
        valdatedWorkers: payload,
      };
    case VALIDATE_WORKERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case VALIDATE_WORKERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_CLASS_WORKER:
      return {
        ...state,
        paymentClassWorkers: payload,
        isLoading: false,
      };
    case GET_CLASS_WORKER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLASS_WORKER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_GROUP:
      return {
        ...state,
        isDeleted: true,
        allUsers: DeleteData(state.allUsers, payload),
      };
    case DELETE_GROUP_FAIL:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_GROUP:
      return {
        ...state,
        isUpdated: true,
      };
    case UPDATE_GROUP_FAIL:
      return {
        ...state,
        isUpdated: false,
      };
    case DELETE_LEVEL:
      return {
        ...state,
        isDeleted: true,
        allUsers: DeleteData(state.allUsers, payload),
      };
    case DELETE_LEVEL_FAIL:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_LEVEL:
      return {
        ...state,
        isUpdated: true,
      };
    case UPDATE_LEVEL_FAIL:
      return {
        ...state,
        isUpdated: false,
      };
    case DELETE_ROLE:
      return {
        ...state,
        isDeleted: true,
        allUsers: DeleteData(state.allUsers, payload),
      };
    case DELETE_ROLE_FAIL:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        isUpdated: true,
      };
    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        isUpdated: false,
      };
    default:
      return state;
  }
};
