import axios from "axios";
import { toast } from "react-toastify";
import {
	ADD_SAVING,
	ADD_SAVING_FAIL,
	DELETE_SAVING,
	DELETE_SAVING_FAIL,
	GET_SAVING,
	GET_SAVING_FAIL,
	GET_SAVING_LOADING,
	RELOAD_SAVINGS,
	UPDATE_SAVING,
	UPDATE_SAVING_FAIL,
} from "./ActionTypes";

export const getSavings = () => async dispatch => {
	dispatch({ type: GET_SAVING_LOADING });
	try {
		let res = await axios.get(`/api/organisations/all_savings_type`);

		dispatch({
			type: GET_SAVING,
			payload: res.data.data,
		});
	} catch (err) {
		if (err) console.log({ err });
		dispatch({ type: GET_SAVING_FAIL });
	}
};

export const deleteSavings = thisData => async dispatch => {
	try {
		let res = await axios.post(
			`/api/organisations/deletesavings/${thisData.id}`
		);

		dispatch({
			type: DELETE_SAVING,
			payload: thisData,
		});
		toast.success(res.data.message, {
			autoClose: 5000,
		});
	} catch (err) {
		console.log({ err });
		dispatch({ type: DELETE_SAVING_FAIL });
		let error = err?.response?.data;
		if (error) {
			console.log({ error });
			toast.error(
				error.error && typeof error.error === "string"
					? error.error
					: error.message
			);
		}
	}
};

export const updateSavings = thisData => async dispatch => {
	try {
		let res = await axios.post(
			`/api/organisations/updateSavingsType/${thisData.id}`,
			{
				...thisData,
			}
		);

		dispatch({
			type: UPDATE_SAVING,
		});

		toast.success(res.data.message, {
			autoClose: 5000,
		});
		dispatch(getSavings());
	} catch (err) {
		console.log({ err });
		dispatch({ type: UPDATE_SAVING_FAIL });
		let error = err?.response?.data;
		if (error) {
			console.log({ error });
			toast.error(
				error.error && typeof error.error === "string"
					? error.error
					: error.message,
				{ autoClose: false }
			);
		}
	}
};

export const createSavings = thisData => async dispatch => {
	try {
		let res = await axios.post(`/api/organisations/createsavings`, thisData);

		dispatch({
			type: ADD_SAVING,
			payload: res.data.data,
		});

		toast.success(res.data.message, {
			autoClose: 5000,
		});
	} catch (err) {
		console.log({ err });
		dispatch({ type: ADD_SAVING_FAIL });
		let error = err?.response?.data;
		if (error) {
			console.log({ error });
			toast.error(
				error.error && typeof error.error === "string"
					? error.error
					: error.message,
				{ autoClose: false }
			);
		}
	}
};

export const reloadSavings = () => async dispatch => {
	dispatch({ type: RELOAD_SAVINGS });
};
