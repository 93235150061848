import React, { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ErrorPage } from "./Component";
import { GlobalState } from "./Data/Context";
import { Loader } from "./Utils";

const generatePage = (pageName, folder) => {
  const component = () => require(`./${folder}/${pageName}`).default;

  try {
    return createElement(component());
  } catch (error) {
    return <ErrorPage />;
  }
};

const PageRender = () => {
  const { auth } = useContext(GlobalState);
  const { page, id, step } = useParams(),
    navigate = useNavigate(),
    escape = [
      "pay-worker",
      "create-group",
      "create-level",
      "create-role",
      "create-payment-class",
      "add-worker",
      "payment-class-workers",
    ],
    escape2 = ["add", "email", "make-payment"];

  useEffect(() => {
    if (auth.isAuth) {
      if (page === "login" || page === "createaccount") {
        navigate("/");
      }
    }
  }, [page, auth.isAuth, navigate]);

  if (auth.token && auth.loading) return <Loader />;

  let pageName = "";
  if (step) {
    if (
      (page === "payroll" && escape2.includes(step)) ||
      (page === "invoicing" && escape2.includes(step))
    ) {
      pageName = `${page}/${id}/${step}`;
    } else pageName = `${page}/${id}/${"[id]"}`;
  } else if (id) {
    if (
      (page === "payroll" && escape.includes(id)) ||
      (page === "invoicing" && escape.includes(id))
    ) {
      pageName = `${page}/${id}`;
    } else pageName = `${page}/[id]`;
  } else {
    pageName = `${page}`;
  }

  return generatePage(
    pageName,
    auth?.userType === "ORGANISATION"
      ? "Pages"
      : auth?.userType === "USER"
      ? "Views"
      : "routes"
  );
};

export default PageRender;
