import { useContext, useEffect } from "react";
import { DefaultHeader } from "../../../Component";
import { GlobalState } from "../../../Data/Context";
import axios from "axios";
import { useLocation } from "react-router-dom";

const PaymentClassWorkers = () => {
  const { getPaymentClassWorkers, payroll } = useContext(GlobalState);
  const location = useLocation()
  console.log(location);
  const toggle = (id) => (e) => {
    console.log("toggled");
  };

  const payWorker = () => {};

  useEffect(() => {
    const paymentClass = async () => {
      await getPaymentClassWorkers("1");
    };
    paymentClass();
    console.log(payroll);
  }, []);
  return (
    <main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
      <div className="container">
        <DefaultHeader text={"Pay Workers"}>
          <button
            className="btn btn-primary1 text-capitalize ms-auto px-5 py-2"
            onClick={payWorker}
          >
            Continue
          </button>
        </DefaultHeader>

        <div>
          <p>All class workers</p>

          <div className="form-floating tw-w-1/5 md:tw-ml-auto mb-4">
            <input
              className="form-control tw-w-1/5"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              value={`${100}%`}
            ></input>
            <label for="floatingTextarea">Percentage</label>
          </div>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Active</th>
              </tr>
            </thead>
            <tbody>
              {payroll?.paymentClassWorkers.map((worker, idx) => (
                <tr key={idx}>
                  <th scope="row">{idx + 1}</th>
                  <td>{worker.user.full_name}</td>
                  <td>{worker.user.last_name}</td>
                  <td>{worker.user.phone_number}</td>
                  <td>{worker.user.email}</td>
                  <td>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        // checked={worker.enabled}
                        onChange={() => toggle(worker.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};

export default PaymentClassWorkers;
