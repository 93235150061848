import React, { createContext, useState } from "react";
import { connect, useSelector } from "react-redux";
import { FaHandHoldingUsd, FaPiggyBank, FaWallet } from "react-icons/fa";
import { BiHome, BiGroup, BiBell, BiCog } from "react-icons/bi";
import picture from "../assets/user.png";
import { AiOutlineArrowDown } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";

import {
  registerUser,
  logout,
  loginUser,
  updateUser,
  loadUser,
} from "./Actions/AuthAction";
import { v4 } from "uuid";
import {
  deleteSavings,
  updateSavings,
  createSavings,
  reloadSavings,
} from "./Actions/SavingsAction";
import {
  getSingleOrganisationUser,
  deleteOrganisationUser,
  updateOrganisationUser,
  createOrganisationUser,
  getOrganisationUser,
  getOrganizationInfo,
} from "./Actions/OrganisationAction";

import {
  getPaymentClass,
  createPaymentGroup,
  createPaymentLevel,
  createPaymentRole,
  getPaymentGroup,
  getPaymentLevel,
  getPaymentRole,
  createPaymentClass,
  updateWorker,
  getAllWorkers,
  getPaymentClassWorkers,
  addWorker,
  payWorkers,
  validateWorkers,
  deleteGroup,
  updateGroup,
  updateLevel,
  deleteLevel,
  updateRole,
  deleteRole,
} from "./Actions/PayrollAction";

export const GlobalState = createContext();

const DataProvider = ({
  children,
  registerUser,
  loginUser,
  updateUser,
  logout,
  loadUser,
  deleteSavings,
  updateSavings,
  createSavings,
  reloadSavings,
  getOrganisationUser,
  getSingleOrganisationUser,
  getOrganizationInfo,
  deleteOrganisationUser,
  updateOrganisationUser,
  createOrganisationUser,
  getPaymentClass,
  createPaymentGroup,
  createPaymentLevel,
  createPaymentRole,
  getPaymentGroup,
  getPaymentLevel,
  getPaymentRole,
  createPaymentClass,
  updateWorker,
  getAllWorkers,
  getPaymentClassWorkers,
  addWorker,
  payWorkers,
  validateWorkers,
  deleteGroup,
  updateGroup,
  updateLevel,
  deleteLevel,
  updateRole,
  deleteRole,
}) => {
  const { auth, savings, organisation, payroll } = useSelector(
      (state) => state
    ),
    [contextUser, setCountextUser] = useState("");

  let numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const headerFiles = [
    {
      name: "dashboard",
      url: "/dashboard",
      icon: <BiHome size={24} />,
      icon2: "",
    },
    {
      name: "wallet",
      url: "/wallet",
      icon: <FaWallet size={24} />,
      icon2: "",
    },
    {
      name: "savings",
      url: "/savings",
      icon: <FaPiggyBank size={24} />,
      icon2: "",
    },
    {
      name: "customers",
      url: "/customers",
      icon: <BiGroup size={24} />,
      icon2: "",
    },
    {
      name: "payroll",
      url: "/payroll",
      icon: <FaHandHoldingUsd size={24} />,
      icon2: <IoIosArrowDown size={20} />,
      type: "button",
      subUrl: [
        { name: "Payment", url: "/payroll" },
        { name: "Manage Group", url: "/payroll/create-group" },
        { name: "Manage Level", url: "/payroll/create-level" },
        { name: "Manage Role", url: "/payroll/create-role" },
        { name: "Create Payment Class", url: "/payroll/create-payment-class" },
        { name: "Manage Worker", url: "/payroll/add-worker" },
      ],
    },
    {
      name: "notification",
      url: "/notification",
      icon: <BiBell size={24} />,
    },
    {
      name: "settings",
      url: "/settings",
      icon: <BiCog size={24} />,
    },
  ];

  let transactions = [
    {
      amount: 2000,
      id: v4(),
      status: "failed",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "failed",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "send",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "failed",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "failed",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "send",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "recieved",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "failed",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "failed",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
    {
      amount: 2000,
      id: v4(),
      status: "recieved",
      date: Date.now(),
      method: "Account Transfer",
      description: "Account Transfer",
    },
  ];

  let beneficiary = [
    {
      picture,
      name: "Adaralegbe Ifeoluwa",
      telephone: "08135373695",
      email: "ifeoluwabk4ever@gmail.com",
      createdAt: Date.now(),
      status: "active",
      id: v4(),
    },
    {
      picture,
      name: "Kalu Peter",
      telephone: "08135373695",
      email: "kalupeter@gmail.com",
      createdAt: Date.now(),
      status: "suspended",
      id: v4(),
    },
    {
      picture,
      name: "Chris Finebone",
      telephone: "08135373695",
      email: "chrisfinebone@gmail.com",
      createdAt: Date.now(),
      status: "active",
      id: v4(),
    },
    {
      picture,
      name: "Madu Blessing",
      telephone: "08135373695",
      email: "madublessing@gmail.com",
      createdAt: Date.now(),
      status: "suspended",
      id: v4(),
    },
    {
      picture,
      name: "Adaralegbe Ifeoluwa",
      telephone: "08135373695",
      email: "ifeoluwabk4ever@gmail.com",
      createdAt: Date.now(),
      status: "active",
      id: v4(),
    },
    {
      picture,
      name: "Kalu Peter",
      telephone: "08135373695",
      email: "kalupeter@gmail.com",
      createdAt: Date.now(),
      status: "suspended",
      id: v4(),
    },
    {
      picture,
      name: "Chris Finebone",
      telephone: "08135373695",
      email: "chrisfinebone@gmail.com",
      createdAt: Date.now(),
      status: "active",
      id: v4(),
    },
    {
      picture,
      name: "Madu Blessing",
      telephone: "08135373695",
      email: "madublessing@gmail.com",
      createdAt: Date.now(),
      status: "suspended",
      id: v4(),
    },
    {
      picture,
      name: "Adaralegbe Ifeoluwa",
      telephone: "08135373695",
      email: "ifeoluwabk4ever@gmail.com",
      createdAt: Date.now(),
      status: "active",
      id: v4(),
    },
    {
      picture,
      name: "Kalu Peter",
      telephone: "08135373695",
      email: "kalupeter@gmail.com",
      createdAt: Date.now(),
      status: "suspended",
      id: v4(),
    },
    {
      picture,
      name: "Chris Finebone",
      telephone: "08135373695",
      email: "chrisfinebone@gmail.com",
      createdAt: Date.now(),
      status: "active",
      id: v4(),
    },
    {
      picture,
      name: "Madu Blessing",
      telephone: "08135373695",
      email: "madublessing@gmail.com",
      createdAt: Date.now(),
      status: "suspended",
      id: v4(),
    },
  ];

  let nairaSign = <span>&#8358;</span>;

  const state = {
    numberWithCommas,
    nairaSign,
    headerFiles,
    transactions,
    beneficiary,
    contextUser,
    setCountextUser,

    auth,
    registerUser,
    loginUser,
    updateUser,
    logout,
    loadUser,

    savings,
    deleteSavings,
    updateSavings,
    createSavings,
    reloadSavings,

    organisation,
    getOrganisationUser,
    getSingleOrganisationUser,
    deleteOrganisationUser,
    updateOrganisationUser,
    createOrganisationUser,
    getOrganizationInfo,

    payroll,
    getPaymentClass,
    createPaymentGroup,
    createPaymentLevel,
    createPaymentRole,
    getPaymentGroup,
    getPaymentLevel,
    getPaymentRole,
    createPaymentClass,
    updateWorker,
    getAllWorkers,
    getPaymentClassWorkers,
    addWorker,
    payWorkers,
    validateWorkers,
    deleteGroup,
    updateGroup,
    updateLevel,
    deleteLevel,
    updateRole,
    deleteRole,
  };

  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default connect(null, {
  registerUser,
  loginUser,
  updateUser,
  loadUser,
  logout,
  deleteSavings,
  updateSavings,
  createSavings,
  reloadSavings,
  getOrganisationUser,
  getSingleOrganisationUser,
  deleteOrganisationUser,
  updateOrganisationUser,
  createOrganisationUser,
  getOrganizationInfo,
  getPaymentClass,
  createPaymentGroup,
  createPaymentLevel,
  createPaymentRole,
  getPaymentGroup,
  getPaymentLevel,
  getPaymentRole,
  createPaymentClass,
  updateWorker,
  getAllWorkers,
  getPaymentClassWorkers,
  addWorker,
  payWorkers,
  validateWorkers,
  deleteGroup,
  updateGroup,
  updateLevel,
  deleteLevel,
  updateRole,
  deleteRole,
})(DataProvider);
