import React, { useState } from "react";
import { DefaultHeader, ModalSuccess } from "../../../Component";
import { BsCheckCircle, BsChevronRight } from "react-icons/bs";
import { AddNew, AddNew2, AddNew3 } from "../../../Component/Payroll";
import { useNavigate, useParams } from "react-router-dom";

const AddNewWorker = () => {
	let [stateActive, setStateActive] = useState(0),
		buttonName = ["general info", "payment detail", "benefits & extra"],
		[isOpen, setIsOpen] = useState(false),
		navigate = useNavigate(),
		param = useParams();

	let handleSubmit = async e => {
		e.preventDefault();
		if (stateActive === buttonName.length - 1) {
			setIsOpen(true);
		} else setStateActive(stateActive + 1);
	};
	let fullToggle = () => {
		setIsOpen(false);
		navigate(`/${param.page}/worker`);
	};
	return (
		<main className="pb-3 pt-3 pt-lg-0 bg-light main-height">
			<div className="container">
				<DefaultHeader text={"Add New Worker"} />
				<div className="d-flex justify-content-center mb-3">
					<div className="btn-group">
						{buttonName.map((item, index) => (
							<div
								key={index}
								// onClick={() => setStateActive(index)}
								className={`text-capitalize px-2 fw-bold barFont d-flex align-items-center ${
									stateActive >= index ? "textColor" : "text-muted"
								}`}>
								<BsCheckCircle size={20} className="mx-2" />
								{item}
								{index !== buttonName.length - 1 && (
									<div className="d-flex align-items-center">
										<div
											style={{ height: "1px", width: "3rem" }}
											className={`ms-1 ${
												stateActive >= index ? "bgColor" : "bgGrey"
											}`}></div>
										<BsChevronRight className="mx-0 px-0" />
									</div>
								)}
							</div>
						))}
					</div>
				</div>
				<div className="container">
					{stateActive === 2 ? (
						<AddNew3 handleSubmit={handleSubmit} />
					) : stateActive === 1 ? (
						<AddNew2 handleSubmit={handleSubmit} />
					) : (
						<AddNew handleSubmit={handleSubmit} />
					)}
				</div>
			</div>
			<ModalSuccess isOpen={isOpen}>
				<div className="d-flex justify-content-center">
					<div className="text-center">
						<p className="text-dark genSansFont">
							You have successfully completed your action
						</p>
						<button
							onClick={fullToggle}
							className="btn btn-primary1 px-5 py-3 text-capitalize">
							okay
						</button>
					</div>
				</div>
			</ModalSuccess>
		</main>
	);
};

export default AddNewWorker;
